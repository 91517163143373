import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import { 
  IAddNewEmployeeRequest, 
  IAddNewEmployeeResponse, 
  IDeleteEmployeeRequest, 
  IEditEmployeeRequest, 
  IEditEmployeeResponse, 
  IVerifyRequest,
  IVerifyResponse,
  IGetEmployeeRequest,
  IGetEmployeeResponse,
  IResetForgotPasswordRequest,
  IResetForgotPasswordResponse,
  ISendEmailForgotPasswordRequest,
  IGetEmployeesNamesResponse,
  IGetAgencyAccountsRequest,
  IAddNewAgencyAccountRequest} from "@source/api/models/employeeModels";
import { AxiosResponse } from "axios";

export default class EmploeeService {
  static async add(employee: IAddNewEmployeeRequest) {
    try {
      return (await api.post<IAddNewEmployeeResponse>('auth/employee/register', employee)).data
    } catch (e: any) {
      throw ReqError.addNewEmployeeError(e.response)
    }
  }

  static async getNames(nursingHomeId: string) {
    try {
      return (await api.get<IGetEmployeesNamesResponse[]>(`employee/${nursingHomeId}/names`)).data
    } catch (e: any) {
      throw ReqError.getNamesError(e.response)
    }
  }

  static async edit(employee: IEditEmployeeRequest, employeeId: string) {
    try {
      return (await api.put<IEditEmployeeResponse>(`employee/${employeeId}`, employee)).data
    } catch (e: any) {
      throw ReqError.editEmployeeError(e.response)
    }
  }

  static async delete({employeeId, nursingHomeId}: IDeleteEmployeeRequest) {
    try {
      return (await api.delete(`employee?employeeId=${employeeId}&nursingHomeId=${nursingHomeId}`)).data
    } catch (e: any) {
      throw ReqError.editEmployeeError(e.response)
    }
  }

  static async get({searchString = "", organizationId, isDisabled = "false", isConfirmed = "", limit = 100, page = 0, nursingHomeIds}: IGetEmployeeRequest) {
    try {
      return (
        await api.
        get<IGetEmployeeResponse>
        (`employee?searchString=${searchString}&organizationId=${organizationId}&isDisabled=${isDisabled}${isConfirmed.trim() && `&isConfirmed=${isConfirmed}`}&limit=${limit}&page=${page}`, {params: {
          nursingHomeIds
        }})
      ).data
    } catch (e: any) {
      throw ReqError.editEmployeeError(e.response)
    }
  }

  static async verify(verifyInfo: IVerifyRequest) {
    try {
      return (await api.post<IVerifyResponse>('auth/employee/confirm', verifyInfo)).data
    } catch (e: any) {
      throw ReqError.verifyEmployeeError(e.response)
    }
  }

  static async disableEmployee(employeeId: string) {
    try {
      return (await api.patch(`users/employees/${employeeId}/disable`, {})).data
    } catch (e: any) {
      throw ReqError.disableEmployeeError(e.response)
    }
  }

  static async enableEmployee(employeeId: string) {
    try {
      return (await api.patch(`users/employees/${employeeId}/enable`, {})).data
    } catch (e: any) {
      throw ReqError.enableEmployeeError(e.response)
    }
  }

  static async resetPassword(body: IResetForgotPasswordRequest) {
    try {
      return (await api.post<IResetForgotPasswordResponse>('auth/employee/forgot-password/reset', body)).data
    } catch (e: any) {
      throw ReqError.resetPasswordEmployeeError(e.response)
    }
  }

  static async sendForgotPasswordCodeToEmail(body: ISendEmailForgotPasswordRequest) {
    try {
      return (await api.post<IResetForgotPasswordResponse>('auth/employee/forgot-password', body)).data
    } catch (e: any) {
      throw ReqError.forgotPasswordEmployeeError(e.response)
    }
  }

  static async createEmployeePhoto(req: FormData, employeeId: string): Promise<AxiosResponse<any>> {
    try {
      return(await api.post(`employee/${employeeId}/photo`, req)).data;
    } catch (e: any) {
      throw ReqError.createEmployeePhotoError(e.response)
    }
  }

  static async deleteEmployeePhoto(employeeId: string): Promise<AxiosResponse<any>> {
    try {
      return(await api.delete(`employee/${employeeId}/photo`)).data;
    } catch (e: any) {
      throw ReqError.deleteEmployeePhotoError(e.response)
    }
  }

  static async getAgencyAccounts({searchString = "", organizationId, isDisabled = "false", limit = 100, page = 0, nursingHomeIds}: IGetAgencyAccountsRequest) {
    try {
      return (
        await api.
        get<IGetEmployeeResponse>
        (`agency?searchString=${searchString}&organizationId=${organizationId}&isDisabled=${isDisabled}&limit=${limit}&page=${page}`, {params: {
          nursingHomeIds
        }})
      ).data
    } catch (e: any) {
      throw ReqError.getAgencyAccountsError(e.response)
    }
  }

  static async addNewAgencyAccount(agencyAccount: IAddNewAgencyAccountRequest) {
    try {
      return (await api.post<IAddNewEmployeeResponse>('auth/agency/register/root', agencyAccount)).data
    } catch (e: any) {
      throw ReqError.addNewAgencyAccountError(e.response)
    }
  }

  static async disableAgencyAccount(agencyId: string) {
    try {
      return (await api.patch(`agency/${agencyId}/disable`, {})).data
    } catch (e: any) {
      throw ReqError.disableAgencyAccountError(e.response)
    }
  }

  static async enableAgencyAccount(agencyId: string) {
    try {
      return (await api.patch(`agency/${agencyId}/enable`, {})).data
    } catch (e: any) {
      throw ReqError.enableAgencyAccountError(e.response)
    }
  }
}