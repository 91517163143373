import { OnboardingEmployeeStatus } from "@source/api/models/employeeModels";
import { HomeStatus, INursingHome } from "@source/api/models/nursingHomeModels";

export enum Role {
    SUPER_ADMIN = 'SuperAdmin',
    ORGANIZATION_ADMIN = 'OrganizationAdmin',
    HOME_ADMIN = 'HomeAdmin',
    STAFF_ADMIN = 'StaffAdmin',
    FAMILY_ADMIN = 'FamilyAdmin',
    NURSE = 'Nurse',
    AGENCY = 'agency',
    ROOT_AGENCY = 'root_agency',
    AGENCY_NURSE = 'agency_nurse',
  }

export const RoleName =  {
  [Role.SUPER_ADMIN]: 'Super manager',
  [Role.ORGANIZATION_ADMIN]: 'Organisation manager',
  [Role.HOME_ADMIN]: 'Home manager',
  [Role.STAFF_ADMIN]: 'Staff manager',
  [Role.FAMILY_ADMIN]: 'Family manager',
  [Role.NURSE]: 'Nurse',
  [Role.AGENCY]: 'Agency nurse',
  [Role.ROOT_AGENCY]: 'Agency Nurse',
  [Role.AGENCY_NURSE]: 'Agency Nurse',
}
  
export enum Position {
    DEFAULT = 'default',
    FACILITY_MANAGER = 'FacilityManager',
    REGISTERED_NURSE = 'RegisteredNurse',
    CLINICAL_LEADER = 'ClinicalLeader',
    CLINICAL_CARE_COORDINATOR = 'ClinicalCareCoordinator',
    CLINICAL_COMPLIANCE_MANAGER = 'ClinicalComplianceManager',
    CEO = 'CEO',
    TEAM_LEADER = 'TeamLeader',
    RECEPTIONIST = 'Receptionist',
    OPERATION_MANAGER = 'OperationManager',
    ADMISSIONS_MANAGER = 'AdmissionsManager',
    PROJECT_MANAGER = 'ProjectManager',
}

export interface TableData {
    stuffId: string,
    email: string,
    phoneNumber: string,
    name: string,
    firstName: string,
    lastName: string,
    photo: string,
    accountType: string,
    position: string,
    role: string,
    _id: string,
    homes: string[],
    homesNames: string[],
    homePermissionIds: {
      [key: string]: string[]
    } | {},
    orgPermissionIds: string[],
    isDisabled: boolean,
    isConfirmed: boolean,
    actions: null,
    toggle: null,
    loginDate: string,
    onboardingStatus: OnboardingEmployeeStatus,
}
  
export interface HeadCell {
    disablePadding: boolean;
    id: keyof TableData;
    label: string;
}

export interface HousesTableData {
  name: string,
  address: string,
  _id: string,
  phone: string,
  email: string,
  photo: string,
  accessKeys: string[],
  actions: null,
  isEmployeeUploaded: boolean,
  isFamiliesUploaded: boolean,
  status: HomeStatus,
  lastEmployeeInvitation: string,
  lastFamiliesInvitation: string,
  endOnboardingDate: string,
}

export interface HeadCellHouses {
  disablePadding: boolean;
  id: keyof HousesTableData;
  label: string;
}

export type Order = 'asc' | 'desc';