import NursingHomeService from "@source/api/services/nursingHomeServices";
import { ICreateNursingHomeRequest, IEditNursingHomePhotoRequest, IEditNursingHomeRequest, IGetAvailableNursingHomesRequest, IGetNursingHomesRequest, IGetOrganizationsReq } from "@source/api/models/nursingHomeModels";

export default class NursingHomeController {
  static async getNursingHome(nursingHomeId: string) {
    return await NursingHomeService.getNursingHome(nursingHomeId);
  }
  static async createNursingHome(req: ICreateNursingHomeRequest) {
    return await NursingHomeService.createNursingHome(req);
  }
  
  static async editNursingHome(req: IEditNursingHomeRequest, nursingHomeId: string) {
    return await NursingHomeService.editNursingHome(req, nursingHomeId);
  }

  static async editNursingHomePhoto(req: FormData, nursingHomeId: string) {
    return await NursingHomeService.editNursingHomePhoto(req, nursingHomeId);
  }
  
  static async getNursingHomes(req: IGetNursingHomesRequest) {
    return await NursingHomeService.getNursingHomes(req);
  }

  static async getAvailableNursingHomes(req: IGetAvailableNursingHomesRequest) {
    return await NursingHomeService.getAvailableNursingHomes(req);
  }

  static async getOrganizations(req: IGetOrganizationsReq) {
    return await NursingHomeService.getOrganizations(req);
  }
}