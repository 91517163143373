import { IInitialSchema, Infection_Covid_First_Update_To_Family_Inputs, Infection_Covid_Ongoing_Update_Inputs, IUpdateStep, Resident_Of_The_Day_Inputs, Resident_Of_The_Day_SubSections, SubSections } from './updateFormModels';
import { deepClone, formatDate } from "@source/utils/utils";
import { 
  Fall_SubSections, 
  IUpdateSchema, 
  Sections, 
  Infection_SubSections, 
  GP_Review_Medication_Change_SubSections, 
  UpdateInputsTypes,
  Fall_Fall_Inputs,
  Infection_Urinary_Inputs,
  Infection_Respiratory_Inputs,
  Infection_Wound_Inputs,
  GPReview_GeneralReview_Inputs,
  GPReview_Deterioration_Inputs,
  GPReview_MedicationReview_Inputs,
  GPReview_ReviewPost_Inputs,
  GPReview_Other_Inputs,
  RadioBoolean,
  Infection_Skin_Inputs,
  Infection_Cellulitis_Inputs,
  Infection_EarNoseThroatENT_Inputs,
  Infection_Eye_Inputs,
  Infection_Other_Inputs,
  Incident_SubSections,
  Incident_Bruise_Inputs,
  Incident_SkinTear_Inputs,
  Incident_PressureInjury_Inputs,
  Incident_Ulcer_Inputs,
  Incident_Absconding_Inputs,
  Incident_Behavior_Inputs,
  Incident_Choking_Inputs,
  Incident_Other_Inputs,
  Change_To_Condition_SubSections,
  ChangeToCondition_ChangeToCognition_Inputs,
  ChangeToCondition_ChangeToMobility_Inputs,
  ChangeToCondition_ChangeToAppetite_Inputs,
  ChangeToCondition_ChangeToBehaviourMood_Inputs,
  ChangeToCondition_WeightChangeUpdate_Inputs,
  ChangeToCondition_OtherGeneralUpdate_Inputs,
  Medication_Incidents_SubSections,
  Pain_SubSections,
  MedicationIncidents_MedicationIncidents_Inputs,
  Pain_Pain_Inputs,
  UpdateMessageSymbols,
  InputsName,
  Physiotherapy_Review_SubSections,
  Physiotherapy_Review_General_Update_From_Physio_Inputs,
  Physiotherapy_Review_New_Aid_Walker_Inputs,
  Physiotherapy_Review_New_Exercises_Inputs,
  Physiotherapy_Review_Mobility_ReAssessed_Inputs,
  Request_For_Items_SubSections,
  Request_For_Items_Clothing_Footwear_Inputs,
  Request_For_Items_Food_Items_Inputs,
  Request_For_Items_Personal_Care_Items_Inputs,
  Request_For_Items_Other_Inputs,
  Other_Other_Inputs,
  Other_SubSections,
  Referral_To_Allied_Health_Professiona_Inputs, 
  Referral_To_Specialist_Inputs,  
  Specialist_Appointment_SubSections, 
  Nutritional_SubSections, 
  Nutritional_Nutritional_Inputs, 
  Return_From_Hospital_Inputs, 
  Return_From_Hospital_SubSections
} from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormModels";
import * as Yup from "yup";
import { IMainUpdateForm } from "@source/pages/HomePage/homeRouter/updateTab/updateTab";
import styles from "./updateForm.module.scss";
import { formatSummaryMessage } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormUtils";
import moment from 'moment';
import nursingStore from "@source/store/nursingHomeStore";
import { version } from 'react';
import residentsStore from '@store/residentsStore';
import { PhoneCall, PhoneCallNames } from '../steps/mainStep.utils';

const UpdateSchema:IUpdateSchema = {
  [Sections.Fall]: {
    [Fall_SubSections.Fall]: {
      message: (mainValues: IMainUpdateForm, inputsValues:any) => formatSummaryMessage(
        `Outline of what happened 
          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Fall_Fall_Inputs.Describe_How_The_Resident_Fell]}. 
          ${UpdateMessageSymbols.Paragraph_Break}

          The fall was ${inputsValues[Fall_Fall_Inputs.Did_Anyone_See_The_Fall] === RadioBoolean.Yes ? 'witnessed' : 'unwitnessed'}.
          ${mainValues.firstName} 
            ${inputsValues[Fall_Fall_Inputs.Did_Resident_Hit_Their_Head] === Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_Not_Remember 
            ? 'is unable to recall if they hit their head.' : ''} 

            ${inputsValues[Fall_Fall_Inputs.Did_Resident_Hit_Their_Head] === Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_Yes 
            ? 'hit his head when he fell.' : ''}

            ${inputsValues[Fall_Fall_Inputs.Did_Resident_Hit_Their_Head] === Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_No 
              ? 'didn’t hit their head.' : ''}
            
          ${inputsValues[Fall_Fall_Inputs.Did_Resident_Hit_Their_Head] === Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_Yes && 
            inputsValues[Fall_Fall_Inputs.Neuro_Observations_Have_Been_Completed] === Fall_Fall_Inputs.Neuro_Observations_Have_Been_Completed_Yes
            ? `Neuro observations have been completed. `
            : ''}

          ${inputsValues[Fall_Fall_Inputs.Resident_Takes_Blood_Thinners] === RadioBoolean.Yes 
            ? `${mainValues.firstName} takes blood thinning medication. ` 
            : ''} 

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Fall_Fall_Inputs.Was_The_Resident_Injured] === RadioBoolean.Yes
            ? `There is an apparent injury: 
              ${inputsValues[Fall_Fall_Inputs.Injured_Type]?.includes(Fall_Fall_Inputs.Bruise)
                ? 
                  `${mainValues.firstName} has Bruising.

                    ${inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise]?.front?.length || inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise]?.back?.length || inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise]?.additional
                      ? `${UpdateMessageSymbols.Line_Break}Location of the bruising:${` ${[...inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise].front, ...inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise].back].join(', ')}`}${inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise].additional ? `${ inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise]?.front?.length || inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise]?.back?.length ? ', ' : ''} ${inputsValues[Fall_Fall_Inputs.Bruise_Place_Bruise].additional}` : ''}.`
                      : ''}

                    ${UpdateMessageSymbols.Paragraph_Break}
      
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise] && inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.length
                      ? 'Our team has taken the following actions: '
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.RN_Assessment_Of_Pain_Bruise)
                      ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.GP_Informed_Bruise)
                      ? 'The GP has been informed. '
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.GP_Prescribed_Pain_Medication_Bruise)
                      ? 'The GP has prescribed pain medication. '
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.Clinical_Nurse_Specialist_Review_Of_Bruising_Bruise)
                      ? 'A clinical nurse specialist has reviewed the wound. '
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.Cream_Applied_Bruise)
                      ? 'Cream has been applied. '
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.Staff_Monitoring_Their_Condition_Bruise)
                      ? 'Our team is monitoring their condition. '
                      : ''}
            
                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.includes(Fall_Fall_Inputs.Nutritional_Supplements_Have_Been_Commenced_Bruise)
                      ? 'Nutritional supplements have been commenced. '
                      : ''}

                    ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise] && inputsValues[Fall_Fall_Inputs.Actions_Taken_Bruise]?.length
                      ? UpdateMessageSymbols.Paragraph_Break
                      : ''}
                  `
                : ''}
              ${inputsValues[Fall_Fall_Inputs.Injured_Type]?.includes(Fall_Fall_Inputs.Skin_Tear)
                ? 
                  `${mainValues.firstName} has a skin tear.

                    ${inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear]?.front?.length || inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear]?.back?.length || inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear]?.additional
                      ? `${UpdateMessageSymbols.Line_Break}Location of the skin tear:${` ${[...inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear].front, ...inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear].back].join(', ')}`}${inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear].additional ? `${ inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear]?.front?.length || inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear]?.back?.length ? ', ' : ''} ${inputsValues[Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear].additional}` : ''}.`
                      : ''}
                    ${UpdateMessageSymbols.Paragraph_Break}
                
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear] && inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.length
                    ? 'Our team has taken the following actions: '
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.RN_Assessment_Of_Pain_Skin_Tear)
                    ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
                    : ''}

                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.Pain_Relief_Analygesic_Was_Given_Skin_Tear)
                    ? `Pain relief analgesic was given. ${inputsValues[Fall_Fall_Inputs.Pain_Relief_Was_Effective_Skin_Tear] === Fall_Fall_Inputs.Pain_Relief_Was_Effective_Yes_Skin_Tear
                          ? 'Pain relief was effective. '
                          : inputsValues[Fall_Fall_Inputs.Pain_Relief_Was_Effective_Skin_Tear] === Fall_Fall_Inputs.Pain_Relief_Was_Effective_No_Skin_Tear
                              ? `Pain relief wasn’t effective. `
                              : `${mainValues.firstName} is waiting for effect of pain relief. `}`
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.GP_Informed_Skin_Tear)
                    ? 'The GP has been informed. '
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.GP_Prescribed_Pain_Medication_Skin_Tear)
                    ? 'The GP has prescribed pain medication. '
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.Clinical_Nurse_Specialist_Review_Of_Skin_Tear_Skin_Tear)
                    ? 'A clinical nurse specialist has reviewed the skin tear.'
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.Dressing_Applied_Skin_Tear)
                    ? 'A dressing has been applied. '
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.Cream_Applied_Skin_Tear)
                    ? 'Cream has been applied. '
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.Staff_Monitoring_Their_Condition_Skin_Tear)
                    ? 'Our team is monitoring their condition. '
                    : ''}
          
                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.includes(Fall_Fall_Inputs.Nutritional_Supplements_Have_Been_Commenced_Skin_Tear)
                    ? 'Nutritional supplements have been commenced. '
                    : ''}

                  ${inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear] && inputsValues[Fall_Fall_Inputs.Actions_Taken_Skin_Tear]?.length
                    ? UpdateMessageSymbols.Paragraph_Break
                    : ''}
                    `
                : ''}

              ${inputsValues[Fall_Fall_Inputs.Injured_Type]?.includes(Fall_Fall_Inputs.Other)
                ? `${mainValues.firstName} has an injury: ${inputsValues[Fall_Fall_Inputs.Other_Injury_Other]}.${UpdateMessageSymbols.Paragraph_Break}`
                : ''}
                `
          : `${mainValues.firstName} was not injured. `}

          ${UpdateMessageSymbols.Paragraph_Break}

          
          ${inputsValues[Fall_Fall_Inputs.Further_Investigation] && inputsValues[Fall_Fall_Inputs.Further_Investigation]?.length
            ? 'Our team has taken the following actions: '
            : ''}
  
          ${inputsValues[Fall_Fall_Inputs.Further_Investigation]?.includes(Fall_Fall_Inputs.Transfer_To_Hospital)
            ? `${mainValues.firstName} has been transferred to hospital for further investigation. `
            : `${mainValues.firstName} hasn't been transferred to hospital for further investigation. `}

          ${inputsValues[Fall_Fall_Inputs.Further_Investigation]?.includes(Fall_Fall_Inputs.GP_Notified)
            ? 'The GP has been informed. '
            : ''}

          ${inputsValues[Fall_Fall_Inputs.Further_Investigation]?.includes(Fall_Fall_Inputs.Physio_Notified)
            ? 'The Physio has been informed. '
            : ''}

          ${/* inputsValues[Fall_Fall_Inputs.Further_Investigation_Comments]
            ? `Further investigation comments: ${inputsValues[Fall_Fall_Inputs.Further_Investigation_Comments]}. `
            : '' */''}
          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Fall_Fall_Inputs.How_Is_The_Resident_Feeling]
            ? `How is ${mainValues.firstName}? ${inputsValues[Fall_Fall_Inputs.How_Is_The_Resident_Feeling]}. `
            : ''}
          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Fall_Fall_Inputs.Other_Information]
            ? `Other Information: ${inputsValues[Fall_Fall_Inputs.Other_Information]}. `
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Fall_Fall_Inputs.Other_Information]
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Fall_Fall_Inputs.Describe_How_The_Resident_Fell,
              label: 'Describe how the resident fell',
              placeholder: 'Write how the resident fell',
              required: true,
              validationSchema: Yup.string().required('Describe how the resident fell')
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Fall_Fall_Inputs.Was_The_Resident_Injured,
              value: undefined,
              label: 'Was the resident injured?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.CheckboxInput,
                    value: undefined,
                    name: Fall_Fall_Inputs.Injured_Type,
                    label: 'Injury Type:',
                    className: styles.padding_1,
                    checkboxes: [
                      {
                        value: Fall_Fall_Inputs.Bruise, 
                        label: 'Bruise', 
                      },
                      {
                        value: Fall_Fall_Inputs.Skin_Tear, 
                        label: 'Skin tear', 
                      },
                      {
                        value: Fall_Fall_Inputs.Other, 
                        label: 'Other', 
                      },
                    ],
                    dependentInputs: {
                      [Fall_Fall_Inputs.Bruise]: [
                        {
                          type: UpdateInputsTypes.HumanInput,
                          name: Fall_Fall_Inputs.Bruise_Place_Bruise,
                          headerType: 'bruise',
                          className: `${styles.margin_1} ${styles.humanInput}`,
                        },
                        {
                          type: UpdateInputsTypes.CheckboxInput,
                          name: Fall_Fall_Inputs.Actions_Taken_Bruise,
                          label: 'Actions taken (select all that apply):',
                          className: styles.padding_1,
                          checkboxes: [
                            {
                              value: Fall_Fall_Inputs.RN_Assessment_Of_Pain_Bruise, 
                              label: 'RN Assessment of Pain', 
                            },
                            {
                              value: Fall_Fall_Inputs.GP_Informed_Bruise, 
                              label: 'GP Informed', 
                            },
                            {
                              value: Fall_Fall_Inputs.GP_Prescribed_Pain_Medication_Bruise, 
                              label: 'GP Prescribed pain medication ', 
                            },
                            {
                              value: Fall_Fall_Inputs.Clinical_Nurse_Specialist_Review_Of_Bruising_Bruise, 
                              label: 'Clinical nurse specialist review of bruising', 
                            },
                            {
                              value: Fall_Fall_Inputs.Cream_Applied_Bruise, 
                              label: 'Cream applied ', 
                            },
                            {
                              value: Fall_Fall_Inputs.Staff_Monitoring_Their_Condition_Bruise, 
                              label: 'Our team is monitoring their condition ', 
                            },
                            {
                              value: Fall_Fall_Inputs.Nutritional_Supplements_Have_Been_Commenced_Bruise, 
                              label: 'Nutritional supplements have been commenced', 
                            },
                          ]
                        },
                      ],
                      [Fall_Fall_Inputs.Skin_Tear]: [
                        {
                          type: UpdateInputsTypes.HumanInput,
                          name: Fall_Fall_Inputs.Skin_Tear_Place_Skin_Tear,
                          headerType: 'skin tear',
                          className: `${styles.margin_1} ${styles.humanInput}`,
                        },
                        {
                          type: UpdateInputsTypes.CheckboxInput,
                          name: Fall_Fall_Inputs.Actions_Taken_Skin_Tear,
                          label: 'Actions taken (select all that apply):',
                          className: styles.padding_1,
                          checkboxes: [
                            {
                              value: Fall_Fall_Inputs.RN_Assessment_Of_Pain_Skin_Tear, 
                              label: 'RN Assessment of Pain', 
                            },
                            {
                              value: Fall_Fall_Inputs.Pain_Relief_Analygesic_Was_Given_Skin_Tear, 
                              label: 'Pain relief analgesic was given', 
                            },
                            {
                              value: Fall_Fall_Inputs.GP_Informed_Skin_Tear, 
                              label: 'GP Informed', 
                            },
                            {
                              value: Fall_Fall_Inputs.GP_Prescribed_Pain_Medication_Skin_Tear, 
                              label: 'GP Prescribed pain medication ', 
                            },
                            {
                              value: Fall_Fall_Inputs.Clinical_Nurse_Specialist_Review_Of_Skin_Tear_Skin_Tear, 
                              label: 'Clinical nurse specialist review of bruising', 
                            },
                            {
                              value: Fall_Fall_Inputs.Cream_Applied_Skin_Tear, 
                              label: 'Cream applied ', 
                            },
                            {
                              value: Fall_Fall_Inputs.Dressing_Applied_Skin_Tear, 
                              label: 'Dressing applied', 
                            },
                            {
                              value: Fall_Fall_Inputs.Staff_Monitoring_Their_Condition_Skin_Tear, 
                              label: 'Our team is monitoring their condition ', 
                            },
                            {
                              value: Fall_Fall_Inputs.Nutritional_Supplements_Have_Been_Commenced_Skin_Tear, 
                              label: 'Nutritional supplements have been commenced', 
                            },
                          ],
                          dependentInputs: {
                            [Fall_Fall_Inputs.Pain_Relief_Analygesic_Was_Given_Skin_Tear]: [
                              {
                                type: UpdateInputsTypes.RadioInput,
                                radios: [
                                  {value: Fall_Fall_Inputs.Pain_Relief_Was_Effective_Yes_Skin_Tear, label: 'Yes'},
                                  {value: Fall_Fall_Inputs.Pain_Relief_Was_Effective_No_Skin_Tear, label: 'No'},
                                  {value: Fall_Fall_Inputs.Pain_Relief_Was_Effective_Waiting_Skin_Tear, label: 'Waiting for effect '},
                                ],
                                name: Fall_Fall_Inputs.Pain_Relief_Was_Effective_Skin_Tear,
                                value: undefined,
                                label: 'Pain relief was effective',
                                className: styles.padding_1,
                                required: true,
                              },
                            ]
                          }
                        },
                      ],
                      [Fall_Fall_Inputs.Other]: [
                        {
                          type: UpdateInputsTypes.TextInput,
                          multiline: true,
                          value: undefined,
                          className: `${styles.padding_1} ${styles.fullWidth}`,
                          name: Fall_Fall_Inputs.Other_Injury_Other,
                          label: 'Other injury',
                          placeholder: 'Write Other injury',
                        }
                      ]
                    }
                  },
                ]
              }
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Fall_Fall_Inputs.Further_Investigation,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Fall_Fall_Inputs.Transfer_To_Hospital, 
                  label: 'Transfer to hospital', 
                },
                {
                  value: Fall_Fall_Inputs.Resident_Family_Says_No_To_Transfer_To_Hospital, 
                  label: 'Resident / family says no to transfer to hospital', 
                },
                {
                  value: Fall_Fall_Inputs.GP_Notified, 
                  label: 'GP Notified', 
                },
                {
                  value: Fall_Fall_Inputs.Physio_Notified, 
                  label: 'Physio notified', 
                },
              ],
/*               dependentInputs: {
                [Fall_Fall_Inputs.Further_Investigation_Required]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    value: undefined,
                    className: styles.padding_1,
                    name: Fall_Fall_Inputs.Further_Investigation_Comments,
                    label: 'Comments',
                    placeholder: 'Write comments',
                  },
                ],
              } */
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_Yes, label: 'Yes'},
                {value: Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_No, label: 'No'},
                {value: Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_Not_Remember, label: 'Resident unable to recall'},
              ],
              name: Fall_Fall_Inputs.Did_Resident_Hit_Their_Head,
              value: undefined,
              label: 'Did resident hit their head?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [Fall_Fall_Inputs.Did_Resident_Hit_Their_Head_Yes]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    className: styles.padding_1,
                    required: true,
                    validationSchema: Yup.string().required('Select one'),
                    radios: [
                      {value: Fall_Fall_Inputs.Neuro_Observations_Have_Been_Completed_Yes, label: 'Yes'},
                      {value: Fall_Fall_Inputs.Neuro_Observations_Have_Been_Completed_No, label: 'No'},
                    ],
                    name: Fall_Fall_Inputs.Neuro_Observations_Have_Been_Completed,
                    value: undefined,
                    label: 'Neuro observations have been completed',
                  },
                ]
              }
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Witnessed'},
                {value: RadioBoolean.No, label: 'Unwitnessed'},
              ],
              name: Fall_Fall_Inputs.Did_Anyone_See_The_Fall,
              value: undefined,
              label: 'Was fall Witnessed or Unwitnessed?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Fall_Fall_Inputs.Resident_Takes_Blood_Thinners,
              value: undefined,
              label: 'Do they take blood thinning medication?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Fall_Fall_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              required: true,
              validationSchema: Yup.string().required('Write how is the resident feeling'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Fall_Fall_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
  },
  [Sections.Infection]: {
    [Infection_SubSections.Urinary_Infection]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has a urinary tract infection.
          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Infection_Urinary_Inputs.Actions_Taken] && inputsValues[Infection_Urinary_Inputs.Actions_Taken]?.length
            ? 'Our team has taken the following actions: '
            : ''}

          ${inputsValues[Infection_Urinary_Inputs.Actions_Taken]?.includes(Infection_Urinary_Inputs.GP_Prescribed_Antibiotics)
            ? 'The GP has ordered to commence antibiotics. '
            : ''}

          ${inputsValues[Infection_Urinary_Inputs.Actions_Taken]?.includes(Infection_Urinary_Inputs.Urine_Test_Taken_And_Sent_To_Pathology)
            ? 'Urine Test has been taken and sent to pathology. We are waiting for the results. '
            : ''}

          ${inputsValues[Infection_Urinary_Inputs.Actions_Taken]?.includes(Infection_Urinary_Inputs.GP_Informed)
            ? 'The GP has been informed. '
            : ''}

          ${inputsValues[Infection_Urinary_Inputs.Actions_Taken]?.includes(Infection_Urinary_Inputs.Fluids_Increased)
            ? 'The level of fluids is increased. '
            : ''}

          ${inputsValues[Infection_Urinary_Inputs.Actions_Taken]?.includes(Infection_Urinary_Inputs.Managing_Symptoms_With_Common_UTI_Treatment_Approaches)
            ? 'Symptoms are being managed with common urinary infection treatment approaches. '
            : ''}

          Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Infection_Urinary_Inputs.How_Is_The_Resident_Feeling]
            ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Urinary_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
            : ''}
          
          ${inputsValues[Infection_Urinary_Inputs.Other_Information]
            ? `Other Information: ${inputsValues[Infection_Urinary_Inputs.Other_Information]}.`
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Urinary_Inputs.Other_Information]
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Urinary_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Urinary_Inputs.Urine_Test_Taken_And_Sent_To_Pathology, 
                  label: 'Urine Test taken and sent to pathology', 
                },
                {
                  value: Infection_Urinary_Inputs.GP_Prescribed_Antibiotics, 
                  label: 'GP Prescribed Antibiotics', 
                },
                {
                  value: Infection_Urinary_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_Urinary_Inputs.Fluids_Increased, 
                  label: 'Fluids increased', 
                },
                {
                  value: Infection_Urinary_Inputs.Managing_Symptoms_With_Common_UTI_Treatment_Approaches, 
                  label: 'Managing symptoms with common urinary infection treatment approaches', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Urinary_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              required: true,
              validationSchema: Yup.string().required('Write how is the resident feeling'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Urinary_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Respiratory]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has a respiratory infection.
          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Infection_Respiratory_Inputs.Actions_Taken] && inputsValues[Infection_Respiratory_Inputs.Actions_Taken]?.length
            ? 'Our team has taken the following actions: '
            : ''}
            
          ${inputsValues[Infection_Respiratory_Inputs.Actions_Taken]?.includes(Infection_Respiratory_Inputs.GP_Informed)
            ? 'The GP has been informed. '
            : ''}

          ${inputsValues[Infection_Respiratory_Inputs.Actions_Taken]?.includes(Infection_Respiratory_Inputs.GP_Prescribed_Antibiotics)
            ? 'The GP has ordered to commence antibiotics. '
            : ''}

          ${inputsValues[Infection_Respiratory_Inputs.Actions_Taken]?.includes(Infection_Respiratory_Inputs.Observations_Monitored)
            ? 'The observations are monitored. '
            : ''}

          ${inputsValues[Infection_Respiratory_Inputs.Actions_Taken]?.includes(Infection_Respiratory_Inputs.Fluids_Increased)
            ? 'The level of fluids is increased. '
            : ''}

          ${inputsValues[Infection_Respiratory_Inputs.Actions_Taken]?.includes(Infection_Respiratory_Inputs.Managing_Symptoms_With_Common_UTI_Treatment_Approaches)
            ? 'Symptoms are being managed with common respiratory infection treatment approaches. '
            : ''}

          Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Infection_Respiratory_Inputs.How_Is_The_Resident_Feeling]
            ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Respiratory_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
            : ''}
          
          ${inputsValues[Infection_Respiratory_Inputs.Other_Information]
            ? `Other Information: ${inputsValues[Infection_Respiratory_Inputs.Other_Information]}.`
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Respiratory_Inputs.Other_Information]
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Respiratory_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Respiratory_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_Respiratory_Inputs.GP_Prescribed_Antibiotics, 
                  label: 'GP Prescribed Antibiotics', 
                },
                {
                  value: Infection_Respiratory_Inputs.Fluids_Increased, 
                  label: 'Fluids increased', 
                },
                {
                  value: Infection_Respiratory_Inputs.Observations_Monitored, 
                  label: 'Observations Monitored', 
                },
                {
                  value: Infection_Respiratory_Inputs.Managing_Symptoms_With_Common_UTI_Treatment_Approaches, 
                  label: 'Managing symptoms with common respiratory infection treatment approaches ', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Respiratory_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Respiratory_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Infection_SubSections.Wound]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has a Wound.

        ${inputsValues[Infection_Wound_Inputs.Wound_Place]?.front?.length || inputsValues[Infection_Wound_Inputs.Wound_Place]?.back?.length || inputsValues[Infection_Wound_Inputs.Wound_Place]?.additional
        ? `${UpdateMessageSymbols.Line_Break}Location of the wound:${` ${[...inputsValues[Infection_Wound_Inputs.Wound_Place].front, ...inputsValues[Infection_Wound_Inputs.Wound_Place].back].join(', ')}`}${inputsValues[Infection_Wound_Inputs.Wound_Place].additional ? `${ inputsValues[Infection_Wound_Inputs.Wound_Place]?.front?.length || inputsValues[Infection_Wound_Inputs.Wound_Place]?.back?.length ? ', ' : ''} ${inputsValues[Infection_Wound_Inputs.Wound_Place].additional}` : ''}.`
        : ''}
        
        ${inputsValues[Infection_Wound_Inputs.The_Cause_Of_The_Wound_Is]
          ? 
        `${UpdateMessageSymbols.Line_Break}How it happened: ${inputsValues[Infection_Wound_Inputs.The_Cause_Of_The_Wound_Is]}. `
        : ''}

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken] && inputsValues[Infection_Wound_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.Swab_Of_Wound_Taken_And_Sent_To_Pathology)
          ? 'A swab of the wound has been taken and sent to pathology. We are waiting for the results. '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.RN_Assessment_Of_Pain)
          ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.GP_Prescribed_Pain_Medication)
          ? 'The GP has prescribed pain medication. '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.GP_Prescribed_Antibiotics)
          ? 'The GP has ordered to commence antibiotics. '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.Clinical_Nurse_Specialist_Review_Of_Wound)
          ? 'A clinical nurse specialist has reviewed the wound. '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.Dressing_Applied)
          ? 'A dressing has been applied. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.Nutritional_Supplements_Are_Being_Taken_To_Manage_Condition)
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken]?.includes(Infection_Wound_Inputs.Nutritional_Supplements_Are_Being_Taken_To_Manage_Condition)
          ? 'Nutritional supplements are being taken to manage condition. '
          : ''}

        ${inputsValues[Infection_Wound_Inputs.Actions_Taken] && inputsValues[Infection_Wound_Inputs.Actions_Taken]?.length
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}

        ${inputsValues[Infection_Wound_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Wound_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_Wound_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_Wound_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Wound_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Wound_Inputs.The_Cause_Of_The_Wound_Is,
              label: 'The cause of the wound is',
              placeholder: 'Write the cause of the wound',
              validationSchema: Yup.string().required('Write the cause of the wound'),
              required: true,
            },
            {
              type: UpdateInputsTypes.HumanInput,
              name: Infection_Wound_Inputs.Wound_Place,
              headerType: 'wound'
            },
          ]
        },
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Wound_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Wound_Inputs.Swab_Of_Wound_Taken_And_Sent_To_Pathology, 
                  label: 'Swab of wound taken and sent to pathology', 
                },
                {
                  value: Infection_Wound_Inputs.RN_Assessment_Of_Pain, 
                  label: 'RN Assessment of Pain', 
                },
                {
                  value: Infection_Wound_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_Wound_Inputs.GP_Prescribed_Pain_Medication, 
                  label: 'GP Prescribed pain medication', 
                },
                {
                  value: Infection_Wound_Inputs.GP_Prescribed_Antibiotics, 
                  label: 'GP Prescribed Antibiotics', 
                },
                {
                  value: Infection_Wound_Inputs.Dressing_Applied, 
                  label: 'Dressing applied', 
                },
                {
                  value: Infection_Wound_Inputs.Clinical_Nurse_Specialist_Review_Of_Wound, 
                  label: 'Clinical nurse specialist review of wound', 
                },
                {
                  value: Infection_Wound_Inputs.Nutritional_Supplements_Are_Being_Taken_To_Manage_Condition, 
                  label: 'Nutritional supplements are being taken to manage condition', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Wound_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Wound_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Skin_Infection]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has a skin infection.
          ${UpdateMessageSymbols.Paragraph_Break}
          
        ${inputsValues[Infection_Skin_Inputs.Actions_Taken] && inputsValues[Infection_Skin_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Actions_Taken]?.includes(Infection_Skin_Inputs.Swab_Of_Infection_Taken_And_Sent)
          ? 'A swab of the skin has been taken and sent to pathology, we are awaiting for the results. '
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Actions_Taken]?.includes(Infection_Skin_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Actions_Taken]?.includes(Infection_Skin_Inputs.Antibiotics_Commenced)
          ? 'Antibiotics have been commenced. '
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Actions_Taken]?.includes(Infection_Skin_Inputs.GP_Prescribed_Medication_Or_Treatment)
          ? 'The GP has prescribed the medication/treatment (TBD). '
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Actions_Taken]?.includes(Infection_Skin_Inputs.Dressing_Applied)
          ? 'A dressing has been applied. '
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Actions_Taken]?.includes(Infection_Skin_Inputs.Referral_To_Wound_Specialist)
          ? `${mainValues.firstName} has been referred to a wound specialist. `
          : ''}

        ${inputsValues[Infection_Skin_Inputs.Description_Of_The_Infection]
          ? `Description of the infection: ${inputsValues[Infection_Skin_Inputs.Description_Of_The_Infection]}.`
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Skin_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Skin_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_Skin_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_Skin_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Skin_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Skin_Inputs.Description_Of_The_Infection,
              label: 'Description of the infection',
              placeholder: 'Write description of the infection',
              validationSchema: Yup.string().required('Write description of the infection'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Skin_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Skin_Inputs.Swab_Of_Infection_Taken_And_Sent, 
                  label: 'Swab of infection taken and sent to pathology', 
                },
                {
                  value: Infection_Skin_Inputs.GP_Informed, 
                  label: 'GP informed', 
                },
                {
                  value: Infection_Skin_Inputs.GP_Prescribed_Medication_Or_Treatment, 
                  label: 'GP prescribed medication or treatment', 
                },
                {
                  value: Infection_Skin_Inputs.Dressing_Applied, 
                  label: 'Dressing applied', 
                },
                {
                  value: Infection_Skin_Inputs.Referral_To_Wound_Specialist, 
                  label: 'Referral to wound specialist', 
                },
                {
                  value: Infection_Skin_Inputs.Antibiotics_Commenced, 
                  label: 'Antibiotics commenced', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Skin_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Skin_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Cellulitis]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has cellulitis.
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Cellulitis_Inputs.Actions_Taken] && inputsValues[Infection_Cellulitis_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Infection_Cellulitis_Inputs.Actions_Taken]?.includes(Infection_Cellulitis_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Infection_Cellulitis_Inputs.Actions_Taken]?.includes(Infection_Cellulitis_Inputs.Antibiotics_Commenced)
          ? 'Antibiotics have been commenced. '
          : ''}

        ${inputsValues[Infection_Cellulitis_Inputs.Actions_Taken]?.includes(Infection_Cellulitis_Inputs.GP_Prescribed_Medication_Or_Treatment)
          ? 'The GP has prescribed the medication/treatment (TBD). '
          : ''}

        ${inputsValues[Infection_Cellulitis_Inputs.Actions_Taken]?.includes(Infection_Cellulitis_Inputs.Monitoring_For_Any_Spread)
          ? 'Our team is monitoring the infection for any spread. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Cellulitis_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Cellulitis_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_Cellulitis_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_Cellulitis_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Cellulitis_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Cellulitis_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Cellulitis_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_Cellulitis_Inputs.GP_Prescribed_Medication_Or_Treatment, 
                  label: 'GP prescribed medication or treatment', 
                },
                {
                  value: Infection_Cellulitis_Inputs.Antibiotics_Commenced, 
                  label: 'Antibiotics commenced', 
                },
                {
                  value: Infection_Cellulitis_Inputs.Monitoring_For_Any_Spread, 
                  label: 'Monitoring for any spread', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Cellulitis_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Cellulitis_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Ear_Nose_Throat_ENT]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has an Ear, Nose, Throat ENT infection.
          ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken] && inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken]?.includes(Infection_EarNoseThroatENT_Inputs.RN_Assessment_Conducted)
          ? 'An RN has conducted an Assessment. '
          : ''}

        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken]?.includes(Infection_EarNoseThroatENT_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken]?.includes(Infection_EarNoseThroatENT_Inputs.Monitoring_Fluids_And_Food_Intake)
          ? 'Our team is monitoring fluids and food intake. '
          : ''}

        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken]?.includes(Infection_EarNoseThroatENT_Inputs.GP_Prescribed_Medication_Or_Treatment)
          ? 'The GP has prescribed the medication/treatment (TBD). '
          : ''}

        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Actions_Taken]?.includes(Infection_EarNoseThroatENT_Inputs.Antibiotics_Commenced)
          ? 'Antibiotics have been commenced. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Infection_EarNoseThroatENT_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_EarNoseThroatENT_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_EarNoseThroatENT_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_EarNoseThroatENT_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_EarNoseThroatENT_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_EarNoseThroatENT_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_EarNoseThroatENT_Inputs.RN_Assessment_Conducted, 
                  label: 'RN assessment conducted', 
                },
                {
                  value: Infection_EarNoseThroatENT_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_EarNoseThroatENT_Inputs.GP_Prescribed_Medication_Or_Treatment, 
                  label: 'GP prescribed medication or treatment', 
                },
                {
                  value: Infection_EarNoseThroatENT_Inputs.Antibiotics_Commenced, 
                  label: 'Antibiotics commenced', 
                },
                {
                  value: Infection_EarNoseThroatENT_Inputs.Monitoring_Fluids_And_Food_Intake, 
                  label: 'Monitoring fluids and food intake', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_EarNoseThroatENT_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_EarNoseThroatENT_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Eye]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has an eye infection.
          ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Eye_Inputs.Actions_Taken] && inputsValues[Infection_Eye_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Infection_Eye_Inputs.Actions_Taken]?.includes(Infection_Eye_Inputs.RN_Assessment_Conducted)
          ? 'An RN has conducted an Assessment. '
          : ''}

        ${inputsValues[Infection_Eye_Inputs.Actions_Taken]?.includes(Infection_Eye_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Infection_Eye_Inputs.Actions_Taken]?.includes(Infection_Eye_Inputs.Dressing_Applied)
          ? 'A dressing has been applied. '
          : ''}

        ${inputsValues[Infection_Eye_Inputs.Actions_Taken]?.includes(Infection_Eye_Inputs.GP_Prescribed_Medication_Or_Treatment)
          ? 'The GP has prescribed the medication/treatment (TBD). '
          : ''}

        ${inputsValues[Infection_Eye_Inputs.Actions_Taken]?.includes(Infection_Eye_Inputs.Antibiotics_Commenced)
          ? 'Antibiotics have been commenced. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Infection_Eye_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Eye_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_Eye_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_Eye_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Eye_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Eye_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Eye_Inputs.RN_Assessment_Conducted, 
                  label: 'RN assessment conducted', 
                },
                {
                  value: Infection_Eye_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_Eye_Inputs.GP_Prescribed_Medication_Or_Treatment, 
                  label: 'GP prescribed medication or treatment', 
                },
                {
                  value: Infection_Eye_Inputs.Antibiotics_Commenced, 
                  label: 'Antibiotics commenced', 
                },
                {
                  value: Infection_Eye_Inputs.Dressing_Applied, 
                  label: 'Dressing applied', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: 'howIsTheResidentFeeling',
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: 'otherInformation',
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Covid_First_Update_To_Family]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `
        Covid-19 tests have been completed today.
        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Covid_Tests]?.length
          ? `Results: `
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Covid_Tests]?.includes(Infection_Covid_First_Update_To_Family_Inputs.Covid_RAT_Test)
          ? `Covid-19 Rapid Antigen Test: ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Covid_RAT_Test_Result] === RadioBoolean.Yes ? 'positive' : 'negative'}, `
          : ''
        }

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Covid_Tests]?.includes(Infection_Covid_First_Update_To_Family_Inputs.Covid_PCR_Test)
          ? `Covid-19 PCR test: ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Covid_PCR_Test_Result] === RadioBoolean.Yes ? 'positive' : 'negative'}. `
          : ''
        } 

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken] && inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.length
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken] && inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following action: '
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.includes(Infection_Covid_First_Update_To_Family_Inputs.Increased_Fluids_Encouraged)
          ? `We have encouraged an increase in fluids. `
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.includes(Infection_Covid_First_Update_To_Family_Inputs.Observations_Being_Monitored_Regularly)
          ? `Observations are being monitored at regular intervals. `
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.includes(Infection_Covid_First_Update_To_Family_Inputs.GP_Informed)
          ? `The GP has been informed. `
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.includes(Infection_Covid_First_Update_To_Family_Inputs.GP_Prescribed_Antiviral_Medication)
          ? `The GP has prescribed the Antiviral medication: 
            ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Antiviral]?.join(', ')
            } `
          : ''}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken] && inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken]?.length
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Isolation_Rules_As_Per_Isolation_Protocol_Apply] === RadioBoolean.Yes
          ? 'Isolation rules as per isolation protocol apply. '
          : ''}

        Isolation to finish on ${moment(inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Isolation_To_Finish_On]).format("DD MMM YYYY")}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Covid_Visitation_Rules_Apply] === RadioBoolean.Yes
          ? `Our current Covid visitation rules apply: ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Visitation_Rules]}. `
          : ''}

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Covid_First_Update_To_Family_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              checkboxes: [
                {value: Infection_Covid_First_Update_To_Family_Inputs.Covid_RAT_Test, label: 'Covid-19 RAT Test'},
                {value: Infection_Covid_First_Update_To_Family_Inputs.Covid_PCR_Test, label: 'Covid-19 PCR Test'},
              ],
              name: Infection_Covid_First_Update_To_Family_Inputs.Covid_Tests,
              value: undefined,
              label: 'Covid-19 tests completed',
              dependentInputs: {
                [Infection_Covid_First_Update_To_Family_Inputs.Covid_RAT_Test]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    radios: [
                      {value: RadioBoolean.Yes, label: 'positive'},
                      {value: RadioBoolean.No, label: 'negative'},
                    ],
                    name: Infection_Covid_First_Update_To_Family_Inputs.Covid_RAT_Test_Result,
                    value: undefined,
                    label: 'Result',
                    className: styles.padding_1,
                    required: true,
                    validationSchema: Yup.string().required('Please select one'),
                  }
                ],
                [Infection_Covid_First_Update_To_Family_Inputs.Covid_PCR_Test]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    radios: [
                      {value: RadioBoolean.Yes, label: 'positive'},
                      {value: RadioBoolean.No, label: 'negative'},
                    ],
                    name: Infection_Covid_First_Update_To_Family_Inputs.Covid_PCR_Test_Result,
                    value: undefined,
                    className: styles.padding_1,
                    label: 'Result',
                    required: true,
                    validationSchema: Yup.string().required('Please select one'),
                  }
                ],
              }
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Infection_Covid_First_Update_To_Family_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Infection_Covid_First_Update_To_Family_Inputs.Increased_Fluids_Encouraged, 
                  label: 'Increased fluids encouraged', 
                },
                {
                  value: Infection_Covid_First_Update_To_Family_Inputs.Observations_Being_Monitored_Regularly, 
                  label: 'Observations being monitored regularly', 
                },
                {
                  value: Infection_Covid_First_Update_To_Family_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Infection_Covid_First_Update_To_Family_Inputs.GP_Prescribed_Antiviral_Medication, 
                  label: 'GP Prescribed Antiviral medication', 
                },
              ],
              dependentInputs: {
                [Infection_Covid_First_Update_To_Family_Inputs.GP_Prescribed_Antiviral_Medication]: [
                  {
                    type: UpdateInputsTypes.CheckboxInput,
                    className: styles.padding_1,
                    name: Infection_Covid_First_Update_To_Family_Inputs.Antiviral,
                    label: 'Actions taken (select all that apply):',
                    checkboxes: [
                      {
                        value: Infection_Covid_First_Update_To_Family_Inputs.Lagevrio, 
                        label: 'Lagevrio® (molnupiravir)', 
                      },
                      {
                        value: Infection_Covid_First_Update_To_Family_Inputs.Paxlovid, 
                        label: 'Paxlovid® (nirmatrelvir + ritonavir)', 
                      },
                    ]
                  }
                ]
              }
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Infection_Covid_First_Update_To_Family_Inputs.Isolation_Rules_As_Per_Isolation_Protocol_Apply,
              value: undefined,
              label: 'Isolation rules as per isolation protocol apply',
              required: true,
              validationSchema: Yup.string().required('Please select one'),

            },
            {
              type: UpdateInputsTypes.DateInput,
              name: Infection_Covid_First_Update_To_Family_Inputs.Isolation_To_Finish_On,
              label: 'Isolation to finish on',
              placeholder: 'Select date Isolation to finish on',
              dateType: 'date',
              validationSchema: Yup.date().required('Date Isolation to finish on is required'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Infection_Covid_First_Update_To_Family_Inputs.Covid_Visitation_Rules_Apply,
              value: undefined,
              label: 'Covid visitation rules apply',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    value: undefined,
                    name: Infection_Covid_First_Update_To_Family_Inputs.Visitation_Rules,
                    label: 'Current visitation rules',
                    placeholder: 'Write Current visitation rules',
                    validationSchema: Yup.string().required('Write Current visitation rules'),
                    required: true,
                  }
                ]
              }
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Covid_First_Update_To_Family_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Covid_First_Update_To_Family_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Infection_SubSections.Covid_Ongoing_Update]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `
          Update on the ${mainValues.firstName}${'`s'} condition: ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Update_On_The_Residents_Condition]}. 

          ${UpdateMessageSymbols.Paragraph_Break}

          Covid-19 tests have been completed today.
          ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Covid_Tests_Have_Been_Completed_Today]?.length
            ? `Results: `
            : ''}

          ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Covid_Tests_Have_Been_Completed_Today]?.includes(Infection_Covid_Ongoing_Update_Inputs.Covid_RAT_Test)
            ? `Covid-19 Rapid Antigen Test: ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Covid_RAT_Test_Result] === RadioBoolean.Yes ? 'positive' : 'negative'}. `
            : ''
          }

          ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Covid_Tests_Have_Been_Completed_Today]?.includes(Infection_Covid_Ongoing_Update_Inputs.Covid_PCR_Test)
            ? `Covid-19 PCR test: ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Covid_PCR_Test_Result] === RadioBoolean.Yes ? 'positive' : 'negative'}. `
            : ''
          } 

          ${UpdateMessageSymbols.Paragraph_Break}

          Our team has taken the following action: ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Actions_Taken_By_Team]}. 

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Isolation_Rules_As_Per_Isolation_Protocol_Apply] === RadioBoolean.Yes
            ? 'Isolation rules as per isolation protocol apply. '
            : ''}
  
          Isolation to finish on ${moment(inputsValues[Infection_Covid_Ongoing_Update_Inputs.Isolation_To_Finish_On]).format("DD MMM YYYY")}. 
  
          ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Covid_Visitation_Rules_Apply] === RadioBoolean.Yes
            ? `Our current Covid visitation rules apply: ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Outline_The_Current_Visitation_Rules]}. `
            : ''}
          
          ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Other_Information]
            ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Infection_Covid_Ongoing_Update_Inputs.Other_Information]}.`
            : ''} 
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
        steps: [
          {
            inputs: [
              {
                type: UpdateInputsTypes.TextInput,
                multiline: true,
                value: undefined,
                name: Infection_Covid_Ongoing_Update_Inputs.Update_On_The_Residents_Condition,
                label: 'Update on the residents condition',
                placeholder: 'Write residents condition',
                validationSchema: Yup.string().required('Write residents condition'),
                required: true,
              },
              {
                type: UpdateInputsTypes.CheckboxInput,
                checkboxes: [
                  {value: Infection_Covid_Ongoing_Update_Inputs.Covid_RAT_Test, label: 'Covid-19 RAT Test'},
                  {value: Infection_Covid_Ongoing_Update_Inputs.Covid_PCR_Test, label: 'Covid-19 PCR Test'},
                ],
                name: Infection_Covid_Ongoing_Update_Inputs.Covid_Tests_Have_Been_Completed_Today,
                value: undefined,
                label: 'Covid-19 tests have been completed today',
                dependentInputs: {
                  [Infection_Covid_Ongoing_Update_Inputs.Covid_RAT_Test]: [
                    {
                      type: UpdateInputsTypes.RadioInput,
                      radios: [
                        {value: RadioBoolean.Yes, label: 'positive'},
                        {value: RadioBoolean.No, label: 'negative'},
                      ],
                      name: Infection_Covid_Ongoing_Update_Inputs.Covid_RAT_Test_Result,
                      value: undefined,
                      label: 'Result',
                      className: styles.padding_1,
                      required: true,
                      validationSchema: Yup.string().required('Please select one'),
                    }
                  ],
                  [Infection_Covid_Ongoing_Update_Inputs.Covid_PCR_Test]: [
                    {
                      type: UpdateInputsTypes.RadioInput,
                      radios: [
                        {value: RadioBoolean.Yes, label: 'positive'},
                        {value: RadioBoolean.No, label: 'negative'},
                      ],
                      name: Infection_Covid_Ongoing_Update_Inputs.Covid_PCR_Test_Result,
                      value: undefined,
                      className: styles.padding_1,
                      label: 'Result',
                      required: true,
                      validationSchema: Yup.string().required('Please select one'),
                    }
                  ],
                }
              },
              {
                type: UpdateInputsTypes.TextInput,
                multiline: true,
                value: undefined,
                name: Infection_Covid_Ongoing_Update_Inputs.Actions_Taken_By_Team,
                label: 'Actions taken by team',
                placeholder: 'Write Actions taken by team',
                validationSchema: Yup.string().required('Write Actions taken by team'),
                required: true,
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Infection_Covid_Ongoing_Update_Inputs.Isolation_Rules_As_Per_Isolation_Protocol_Apply,
                value: undefined,
                label: 'Isolation rules as per isolation protocol apply',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
              },
              {
                type: UpdateInputsTypes.DateInput,
                name: Infection_Covid_Ongoing_Update_Inputs.Isolation_To_Finish_On,
                label: 'Isolation to finish on',
                placeholder: 'Select date Isolation to finish on',
                dateType: 'date',
                validationSchema: Yup.date().required('Date Isolation to finish on is required'),
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Infection_Covid_Ongoing_Update_Inputs.Covid_Visitation_Rules_Apply,
                value: undefined,
                label: 'Covid visitation rules apply',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
                dependentInputs: {
                  [RadioBoolean.Yes]: [
                    {
                      type: UpdateInputsTypes.TextInput,
                      multiline: true,
                      className: styles.padding_1,
                      value: undefined,
                      name: Infection_Covid_Ongoing_Update_Inputs.Outline_The_Current_Visitation_Rules,
                      label: 'Current visitation rules',
                      placeholder: 'Write Current visitation rules',
                      validationSchema: Yup.string().required('Write Current visitation rules'),
                      required: true,
                    }
                  ]
                }
              },
              {
                type: UpdateInputsTypes.TextInput,
                multiline: true,
                name: Infection_Covid_Ongoing_Update_Inputs.Other_Information,
                label: 'Other information',
                placeholder: 'Write other information',
              },
            ]
          }
        ]
    },
    [Infection_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has infection.
          ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Other_Inputs.Description_Of_The_Infection]
          ? `Description of the infection: ${inputsValues[Infection_Other_Inputs.Description_Of_The_Infection]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}

        ${inputsValues[Infection_Other_Inputs.Description_Of_Any_Treatment]
          ? `Description of any treatment: ${inputsValues[Infection_Other_Inputs.Description_Of_Any_Treatment]}.`
          : ''}

        ${UpdateMessageSymbols.Paragraph_Break}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Infection_Other_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Infection_Other_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Infection_Other_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Infection_Other_Inputs.Other_Information]}.`
          : ''}     
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Infection_Other_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Other_Inputs.Description_Of_The_Infection,
              label: 'Description of the infection',
              placeholder: 'Write description of the infection',
              validationSchema: Yup.string().required('Write description of the infection'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Other_Inputs.Description_Of_Any_Treatment,
              label: 'Description of any treatment',
              placeholder: 'Write description of any treatment',
              validationSchema: Yup.string().required('Write description of any treatment'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Infection_Other_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Infection_Other_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },
  [Sections.GP_Review_Medication_Change]: {
    [GP_Review_Medication_Change_SubSections.General_Review]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} was reviewed by the GP, ${inputsValues[GPReview_GeneralReview_Inputs.Name_Of_GP] || 'undefined'} on ${formatDate(mainValues.date, true)}.

        ${UpdateMessageSymbols.Paragraph_Break}
        ${inputsValues[GPReview_GeneralReview_Inputs.Description_Of_The_GP_Review]
          ? `Summary of GP Review: ${inputsValues[GPReview_GeneralReview_Inputs.Description_Of_The_GP_Review]}.`
          : ''}

        ${inputsValues[GPReview_GeneralReview_Inputs.GP_Changed_Medication] === RadioBoolean.Yes
          ? inputsValues[GPReview_GeneralReview_Inputs.Describe_Change_To_Medication]
            ? `There was change to medication: ${inputsValues[GPReview_GeneralReview_Inputs.Describe_Change_To_Medication]}. `
            : 'There was change to medication.'
          : ' There was no change to medication. '}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[GPReview_GeneralReview_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[GPReview_GeneralReview_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[GPReview_GeneralReview_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              name: GPReview_GeneralReview_Inputs.Name_Of_GP,
              label: 'Name of GP',
              placeholder: 'Write GP name',
              validationSchema: Yup.string().required('Write GP name'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_GeneralReview_Inputs.Description_Of_The_GP_Review,
              label: 'Description of the GP review',
              placeholder: 'Write description of the GP review',
              validationSchema: Yup.string().required('Write description of the GP review'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: GPReview_Deterioration_Inputs.GP_Changed_Medication,
              value: undefined,
              label: 'GP changed medication',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    required: true,
                    validationSchema: Yup.string().required('Write  change to medication'),
                    className: styles.padding_1,
                    name: GPReview_GeneralReview_Inputs.Describe_Change_To_Medication,
                    label: 'Describe change to medication',
                    placeholder: 'Write describe change to medication',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_GeneralReview_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [GP_Review_Medication_Change_SubSections.Deterioration_Physical_Or_Cognitive]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} was reviewed by the GP, ${inputsValues[GPReview_Deterioration_Inputs.Name_Of_GP] || 'undefined'} on ${formatDate(mainValues.date, true)}.
          ${inputsValues[GPReview_Deterioration_Inputs.Description_Of_The_GP_Review]
            ? `Outline of the GP’s review: ${inputsValues[GPReview_Deterioration_Inputs.Description_Of_The_GP_Review]}.`
            : ''}
        ${inputsValues[GPReview_Deterioration_Inputs.GP_Changed_Medication] === RadioBoolean.Yes
          ? inputsValues[GPReview_Deterioration_Inputs.Describe_Change_To_Medication]
            ? `There was change to medication: ${inputsValues[GPReview_Deterioration_Inputs.Describe_Change_To_Medication]}. `
            : 'There was change to medication.'
          : ' There was no change to medication. '}
        ${UpdateMessageSymbols.Paragraph_Break}
      
        ${inputsValues[GPReview_Deterioration_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[GPReview_Deterioration_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[GPReview_Deterioration_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              name: GPReview_Deterioration_Inputs.Name_Of_GP,
              label: 'Name of GP',
              placeholder: 'Write GP name',
              validationSchema: Yup.string().required('Write GP name'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_Deterioration_Inputs.Description_Of_The_GP_Review,
              label: 'Description of the GP review',
              placeholder: 'Write description of the GP review',
              validationSchema: Yup.string().required('Write description of the GP review'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: GPReview_Deterioration_Inputs.GP_Changed_Medication,
              value: undefined,
              label: 'GP changed medication',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    required: true,
                    validationSchema: Yup.string().required('Write  change to medication'),
                    className: styles.padding_1,
                    name: GPReview_Deterioration_Inputs.Describe_Change_To_Medication,
                    label: 'Describe change to medication',
                    placeholder: 'Write describe change to medication',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_Deterioration_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [GP_Review_Medication_Change_SubSections.Medication_Review]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} was reviewed by the GP, ${inputsValues[GPReview_MedicationReview_Inputs.Name_Of_GP] || 'undefined'} on ${formatDate(mainValues.date, true)}.
          ${inputsValues[GPReview_MedicationReview_Inputs.Description_Of_The_GP_Review]
            ? `Outline of the GP’s review: ${inputsValues[GPReview_MedicationReview_Inputs.Description_Of_The_GP_Review]}.`
            : ''}
        ${inputsValues[GPReview_MedicationReview_Inputs.GP_Changed_Medication] === RadioBoolean.Yes
          ? inputsValues[GPReview_MedicationReview_Inputs.Describe_Change_To_Medication]
            ? `There was change to medication: ${inputsValues[GPReview_MedicationReview_Inputs.Describe_Change_To_Medication]}. `
            : 'There was change to medication.'
          : ' There was no change to medication. '}
        ${UpdateMessageSymbols.Paragraph_Break}
      
        ${inputsValues[GPReview_MedicationReview_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[GPReview_MedicationReview_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[GPReview_MedicationReview_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              name: GPReview_MedicationReview_Inputs.Name_Of_GP,
              label: 'Name of GP',
              placeholder: 'Write GP name',
              validationSchema: Yup.string().required('Write GP name'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_MedicationReview_Inputs.Description_Of_The_GP_Review,
              label: 'Description of the GP review',
              placeholder: 'Write description of the GP review',
              validationSchema: Yup.string().required('Write description of the GP review'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: GPReview_MedicationReview_Inputs.GP_Changed_Medication,
              value: undefined,
              label: 'GP changed medication',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    required: true,
                    validationSchema: Yup.string().required('Write change to medication'),
                    name: GPReview_MedicationReview_Inputs.Describe_Change_To_Medication,
                    label: 'Describe change to medication',
                    placeholder: 'Write change to medication',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_MedicationReview_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [GP_Review_Medication_Change_SubSections.Review_Post_Fall_Or_Incident]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} was reviewed by the GP, ${inputsValues[GPReview_ReviewPost_Inputs.Name_Of_GP] || 'undefined'} on ${formatDate(mainValues.date, true)}.
          ${inputsValues[GPReview_ReviewPost_Inputs.Description_Of_The_GP_Review]
            ? `Outline of the GP’s review: ${inputsValues[GPReview_ReviewPost_Inputs.Description_Of_The_GP_Review]}.`
            : ''}

        ${inputsValues[GPReview_ReviewPost_Inputs.GP_Changed_Medication] === RadioBoolean.Yes
          ? inputsValues[GPReview_ReviewPost_Inputs.Describe_Change_To_Medication]
            ? `There was change to medication: ${inputsValues[GPReview_ReviewPost_Inputs.Describe_Change_To_Medication]}. `
            : 'There was change to medication.'
          : ' There was no change to medication. '}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              name: GPReview_ReviewPost_Inputs.Name_Of_GP,
              label: 'Name of GP',
              placeholder: 'Write GP name',
              validationSchema: Yup.string().required('Write GP name'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_ReviewPost_Inputs.Description_Of_The_GP_Review,
              label: 'Description of the GP review',
              placeholder: 'Write description of the GP review',
              validationSchema: Yup.string().required('Write description of the GP review'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: GPReview_ReviewPost_Inputs.GP_Changed_Medication,
              value: undefined,
              label: 'GP changed medication',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: GPReview_ReviewPost_Inputs.Describe_Change_To_Medication,
                    required: true,
                    validationSchema: Yup.string().required('Write change to medication'),
                    label: 'Describe change to medication',
                    placeholder: 'Write describe change to medication',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_ReviewPost_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [GP_Review_Medication_Change_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} was reviewed by the GP, ${inputsValues[GPReview_Other_Inputs.Name_Of_GP] || 'undefined'} on ${formatDate(mainValues.date, true)}.
          ${inputsValues[GPReview_Other_Inputs.Description_Of_The_GP_Review]
            ? `Outline of the GP’s review: ${inputsValues[GPReview_Other_Inputs.Description_Of_The_GP_Review]}.`
            : ''}

        ${inputsValues[GPReview_Other_Inputs.GP_Changed_Medication] === RadioBoolean.Yes
          ? inputsValues[GPReview_Other_Inputs.Describe_Change_To_Medication]
            ? `There was change to medication: ${inputsValues[GPReview_Other_Inputs.Describe_Change_To_Medication]}. `
            : 'There was change to medication.'
          : ' There was no change to medication. '}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[GPReview_Other_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[GPReview_Other_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[GPReview_Other_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              name: GPReview_Other_Inputs.Name_Of_GP,
              label: 'Name of GP',
              placeholder: 'Write GP name',
              validationSchema: Yup.string().required('Write GP name'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_Other_Inputs.Description_Of_The_GP_Review,
              label: 'Description of the GP review',
              placeholder: 'Write description of the GP review',
              validationSchema: Yup.string().required('Write description of the GP review'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: GPReview_Other_Inputs.GP_Changed_Medication,
              value: undefined,
              label: 'GP changed medication',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: GPReview_Other_Inputs.Describe_Change_To_Medication,
                    required: true,
                    validationSchema: Yup.string().required('Write change to medication'),
                    label: 'Describe change to medication',
                    placeholder: 'Write describe change to medication',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: GPReview_Other_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },
  [Sections.Incident]: {
    [Incident_SubSections.Bruising]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has Bruising.

          ${inputsValues[Incident_Bruise_Inputs.Bruise_Place]?.front?.length || inputsValues[Incident_Bruise_Inputs.Bruise_Place]?.back?.length || inputsValues[Incident_Bruise_Inputs.Bruise_Place]?.additional
            ? `${UpdateMessageSymbols.Line_Break}Location of the bruising:${` ${[...inputsValues[Incident_Bruise_Inputs.Bruise_Place].front, ...inputsValues[Incident_Bruise_Inputs.Bruise_Place].back].join(', ')}`}${inputsValues[Incident_Bruise_Inputs.Bruise_Place].additional ? `${ inputsValues[Incident_Bruise_Inputs.Bruise_Place]?.front?.length || inputsValues[Incident_Bruise_Inputs.Bruise_Place]?.back?.length ? ', ' : ''} ${inputsValues[Incident_Bruise_Inputs.Bruise_Place].additional}` : ''}.`
            : ''}

          ${inputsValues[Incident_Bruise_Inputs.How_Did_The_Bruise_Happen]
          ? 
          `${UpdateMessageSymbols.Line_Break}How it happened: ${inputsValues[Incident_Bruise_Inputs.How_Did_The_Bruise_Happen]}. `
          : ''}
          ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken] && inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.includes(Incident_Bruise_Inputs.RN_Assessment_Of_Pain)
          ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
          : ''}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.includes(Incident_Bruise_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.includes(Incident_Bruise_Inputs.GP_Prescribed_Pain_Medication)
          ? 'The GP has prescribed pain medication. '
          : ''}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.includes(Incident_Bruise_Inputs.Clinical_Nurse_Specialist_Review_Of_Bruising)
          ? 'A clinical nurse specialist has reviewed the wound. '
          : ''}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.includes(Incident_Bruise_Inputs.Cream_Applied)
          ? 'Cream has been applied. '
          : ''}

        ${inputsValues[Incident_Bruise_Inputs.Actions_Taken]?.includes(Incident_Bruise_Inputs.Nutritional_Supplements_Have_Been_Commenced)
          ? 'Nutritional supplements have been commenced. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Incident_Bruise_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_Bruise_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_Bruise_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_Bruise_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_Bruise_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Bruise_Inputs.How_Did_The_Bruise_Happen,
              label: 'How did the bruise happen?',
              placeholder: 'Write the cause of the bruise',
              validationSchema: Yup.string().required('Write the cause of the bruise'),
              required: true,
            },
            {
              type: UpdateInputsTypes.HumanInput,
              name: Incident_Bruise_Inputs.Bruise_Place,
              headerType: 'bruise'
            },
          ]
        },
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_Bruise_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_Bruise_Inputs.RN_Assessment_Of_Pain, 
                  label: 'RN Assessment of Pain', 
                },
                {
                  value: Incident_Bruise_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_Bruise_Inputs.GP_Prescribed_Pain_Medication, 
                  label: 'GP Prescribed pain medication ', 
                },
                {
                  value: Incident_Bruise_Inputs.Clinical_Nurse_Specialist_Review_Of_Bruising, 
                  label: 'Clinical nurse specialist review of bruising', 
                },
                {
                  value: Incident_Bruise_Inputs.Cream_Applied, 
                  label: 'Cream applied ', 
                },
                {
                  value: Incident_Bruise_Inputs.Nutritional_Supplements_Have_Been_Commenced, 
                  label: 'Nutritional supplements have been commenced', 
                },

              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_Bruise_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Bruise_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Incident_SubSections.Skin_Tear]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has a skin tear.

         ${inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place]?.front?.length || inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place]?.back?.length || inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place]?.additional
           ? `${UpdateMessageSymbols.Line_Break}Location of the skin tear:${` ${[...inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place].front, ...inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place].back].join(', ')}`}${inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place].additional ? `${ inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place]?.front?.length || inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place]?.back?.length ? ', ' : ''} ${inputsValues[Incident_SkinTear_Inputs.Skin_Tear_Place].additional}` : ''}.`
           : ''} 

        ${inputsValues[Incident_SkinTear_Inputs.Cause_Of_The_Skin_Tear]
        ? 
        `${UpdateMessageSymbols.Line_Break}How it happened: ${inputsValues[Incident_SkinTear_Inputs.Cause_Of_The_Skin_Tear]}. `
        : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken] && inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.RN_Assessment_Of_Pain)
          ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.Pain_Relief_Analygesic_Was_Given)
          ? `Pain relief analgesic was given. ${inputsValues[Incident_SkinTear_Inputs.Pain_Relief_Was_Effective] === Incident_SkinTear_Inputs.Pain_Relief_Was_Effective_Yes
                ? 'Pain relief was effective. '
                : inputsValues[Incident_SkinTear_Inputs.Pain_Relief_Was_Effective] === Incident_SkinTear_Inputs.Pain_Relief_Was_Effective_No
                    ? `Pain relief wasn’t effective. `
                    : `${mainValues.firstName} is waiting for effect of pain relief. `}`
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.GP_Prescribed_Pain_Medication)
          ? 'The GP has prescribed pain medication. '
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.Clinical_Nurse_Specialist_Review_Of_Skin_Tear)
          ? 'A clinical nurse specialist has reviewed the skin tear.'
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.Dressing_Applied)
          ? 'A dressing has been applied. '
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.Cream_Applied)
          ? 'Cream has been applied. '
          : ''}

        ${inputsValues[Incident_SkinTear_Inputs.Actions_Taken]?.includes(Incident_SkinTear_Inputs.Nutritional_Supplements_Have_Been_Commenced)
          ? 'Nutritional supplements have been commenced. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Incident_SkinTear_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_SkinTear_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_SkinTear_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_SkinTear_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_SkinTear_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_SkinTear_Inputs.Cause_Of_The_Skin_Tear,
              label: 'Cause of the skin tear',
              placeholder: 'Write the cause of the skin tear',
              validationSchema: Yup.string().required('Write the cause of the skin tear'),
              required: true,
            },
            {
              type: UpdateInputsTypes.HumanInput,
              name: Incident_SkinTear_Inputs.Skin_Tear_Place,
              headerType: 'skin tear'
            },
          ]
        },
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_SkinTear_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_SkinTear_Inputs.RN_Assessment_Of_Pain, 
                  label: 'RN Assessment of Pain', 
                },
                {
                  value: Incident_SkinTear_Inputs.Pain_Relief_Analygesic_Was_Given, 
                  label: 'Pain relief analgesic was given', 
                },
                {
                  value: Incident_SkinTear_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_SkinTear_Inputs.GP_Prescribed_Pain_Medication, 
                  label: 'GP Prescribed pain medication', 
                },
                {
                  value: Incident_SkinTear_Inputs.Clinical_Nurse_Specialist_Review_Of_Skin_Tear, 
                  label: 'Clinical nurse specialist review of skin tear', 
                },
                {
                  value: Incident_SkinTear_Inputs.Cream_Applied, 
                  label: 'Cream applied', 
                },
                {
                  value: Incident_SkinTear_Inputs.Dressing_Applied, 
                  label: 'Dressing applied', 
                },
                {
                  value: Incident_SkinTear_Inputs.Nutritional_Supplements_Have_Been_Commenced, 
                  label: 'Nutritional supplements have been commenced', 
                },
              ],
              dependentInputs: {
                [Incident_SkinTear_Inputs.Pain_Relief_Analygesic_Was_Given]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    radios: [
                      {value: Incident_SkinTear_Inputs.Pain_Relief_Was_Effective_Yes, label: 'Yes'},
                      {value: Incident_SkinTear_Inputs.Pain_Relief_Was_Effective_No, label: 'No'},
                      {value: Incident_SkinTear_Inputs.Pain_Relief_Was_Effective_Waiting, label: 'Waiting for effect '},
                    ],
                    name: Incident_SkinTear_Inputs.Pain_Relief_Was_Effective,
                    value: undefined,
                    label: 'Pain relief was effective',
                    required: true,
                    validationSchema: Yup.string().required('Select one'),
                    className: styles.padding_1,
                  },
                ]
              }
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_SkinTear_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_SkinTear_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Incident_SubSections.Pressure_Injury]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has a pressure injury.

          ${inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place]?.front?.length || inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place]?.back?.length || inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place]?.additional
            ? `${UpdateMessageSymbols.Line_Break}Location of the pressure injury:${` ${[...inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place].front, ...inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place].back].join(', ')}`}${inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place].additional ? `${ inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place]?.front?.length || inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place]?.back?.length ? ', ' : ''} ${inputsValues[Incident_PressureInjury_Inputs.Pressure_Injury_Place].additional}` : ''}.`
            : ''}

          ${inputsValues[Incident_PressureInjury_Inputs.Cause_Of_The_Pressure_Injury]
            ? 
          `${UpdateMessageSymbols.Line_Break}How it happened: ${inputsValues[Incident_PressureInjury_Inputs.Cause_Of_The_Pressure_Injury]}. `
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Happened_Outside_Of_The_Aged_Care_Home]?.includes(Incident_PressureInjury_Inputs.Happened_Outside_Of_The_Aged_Care_Home_Value)
          && inputsValues[Incident_PressureInjury_Inputs.Where_Did_The_Injury_Happen]
            ? `It happened ${inputsValues[Incident_PressureInjury_Inputs.Where_Did_The_Injury_Happen]}. `
            : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken] && inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.RN_Assessment_Of_Pain)
          ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.GP_Prescribed_Pain_Medication)
          ? 'The GP has prescribed pain medication. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Clinical_Nurse_Specialist_Review_Of_Pressure_Sore)
          ? 'A clinical nurse specialist has reviewed the pressure sore. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Dressing_Applied)
          ? 'A dressing has been applied. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Cream_Applied)
          ? 'Cream has been applied. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Nutritional_Supplements_Have_Been_Commenced)
          ? 'Nutritional supplements have been commenced. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Our_Team_Are_Repositioning_Them_Regularly)
          ? 'Our team are repositioning them regularly as required to relieve pressure. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Air_Mattress_Used_To_Relieve_Pressure)
          ? 'An air mattress is being used to relieve pressure. '
          : ''}

        ${inputsValues[Incident_PressureInjury_Inputs.Actions_Taken]?.includes(Incident_PressureInjury_Inputs.Sheepskin_To_Relieve_Pressure)
          ? 'A sheepskin is being used to relieve pressure. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Incident_PressureInjury_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_PressureInjury_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_PressureInjury_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_PressureInjury_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_PressureInjury_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_PressureInjury_Inputs.Cause_Of_The_Pressure_Injury,
              label: 'Cause of the pressure injury',
              placeholder: 'Write the cause of the pressure injury',
              validationSchema: Yup.string().required('Write the cause of the pressure injury'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_PressureInjury_Inputs.Happened_Outside_Of_The_Aged_Care_Home,
              label: '',
              checkboxes: [
                {
                  value: Incident_PressureInjury_Inputs.Happened_Outside_Of_The_Aged_Care_Home_Value, 
                  label: 'Happened outside of the aged care home', 
                },
              ],
              dependentInputs: {
                [Incident_PressureInjury_Inputs.Happened_Outside_Of_The_Aged_Care_Home_Value]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    value: undefined,
                    name: Incident_PressureInjury_Inputs.Where_Did_The_Injury_Happen,
                    label: 'Where did the injury happen?',
                    required: true,
                    validationSchema: Yup.string().required('Write where did the injury happen'),
                    placeholder: 'Write where did the injury happen',
                  }
                ]
              }
            },
            {
              type: UpdateInputsTypes.HumanInput,
              name: Incident_PressureInjury_Inputs.Pressure_Injury_Place,
              headerType: 'pressure injury'
            },
          ]
        },
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_PressureInjury_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_PressureInjury_Inputs.RN_Assessment_Of_Pain, 
                  label: 'RN Assessment of Pain', 
                },
                {
                  value: Incident_PressureInjury_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_PressureInjury_Inputs.GP_Prescribed_Pain_Medication, 
                  label: 'GP Prescribed pain medication', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Our_Team_Are_Repositioning_Them_Regularly, 
                  label: 'Our team are repositioning them regularly as required to relieve pressure', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Clinical_Nurse_Specialist_Review_Of_Pressure_Sore, 
                  label: 'Clinical nurse specialist review of pressure sore', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Cream_Applied, 
                  label: 'Cream applied', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Air_Mattress_Used_To_Relieve_Pressure, 
                  label: 'Air mattress used to relieve pressure', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Sheepskin_To_Relieve_Pressure, 
                  label: 'Sheepskin used to releave the pressure area', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Dressing_Applied, 
                  label: 'Dressing applied', 
                },
                {
                  value: Incident_PressureInjury_Inputs.Nutritional_Supplements_Have_Been_Commenced, 
                  label: 'Nutritional supplements have been commenced', 
                },

              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_PressureInjury_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_PressureInjury_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Incident_SubSections.Ulcer]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has an ulcer.

          ${inputsValues[Incident_Ulcer_Inputs.Ulcer_Place]?.front?.length || inputsValues[Incident_Ulcer_Inputs.Ulcer_Place]?.back?.length || inputsValues[Incident_Ulcer_Inputs.Ulcer_Place]?.additional
            ? `${UpdateMessageSymbols.Line_Break}Location of the ulcer:${` ${[...inputsValues[Incident_Ulcer_Inputs.Ulcer_Place].front, ...inputsValues[Incident_Ulcer_Inputs.Ulcer_Place].back].join(', ')}`}${inputsValues[Incident_Ulcer_Inputs.Ulcer_Place].additional ? `${ inputsValues[Incident_Ulcer_Inputs.Ulcer_Place]?.front?.length || inputsValues[Incident_Ulcer_Inputs.Ulcer_Place]?.back?.length ? ', ' : ''} ${inputsValues[Incident_Ulcer_Inputs.Ulcer_Place].additional}` : ''}.`
            : ''}

          ${inputsValues[Incident_Ulcer_Inputs.Cause_Of_The_Ulcer]
            ? 
          `${UpdateMessageSymbols.Line_Break}How it happened: ${inputsValues[Incident_Ulcer_Inputs.Cause_Of_The_Ulcer]}. `
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken] && inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.RN_Assessment_Of_Pain)
          ? `An RN has conducted an Assessment of Pain on ${mainValues.firstName}. `
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.GP_Prescribed_Pain_Medication)
          ? 'The GP has prescribed pain medication. '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.Clinical_Nurse_Review_Of_Ulcer)
          ? 'A clinical nurse specialist has reviewed the ulcer. '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.Dressing_Applied)
          ? 'A dressing has been applied. '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.Cream_Applied)
          ? 'Cream has been applied. '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.Nutritional_Supplements_Have_Been_Commenced)
          ? 'Nutritional supplements have been commenced. '
          : ''}

        ${inputsValues[Incident_Ulcer_Inputs.Actions_Taken]?.includes(Incident_Ulcer_Inputs.Refer_To_Wound_Specialist)
          ? `${mainValues.firstName} has been referred to a wound specialist. `
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Incident_Ulcer_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_Ulcer_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_Ulcer_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_Ulcer_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_Ulcer_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Ulcer_Inputs.Cause_Of_The_Ulcer,
              label: 'Cause of the ulcer',
              placeholder: 'Write the cause of the ulcer',
              validationSchema: Yup.string().required('Write the cause of the ulcer'),
              required: true,
            },
            {
              type: UpdateInputsTypes.HumanInput,
              name: Incident_Ulcer_Inputs.Ulcer_Place,
              headerType: 'ulcer'
            },
          ]
        },
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_Ulcer_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_Ulcer_Inputs.RN_Assessment_Of_Pain, 
                  label: 'RN Assessment of Pain', 
                },
                {
                  value: Incident_Ulcer_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_Ulcer_Inputs.GP_Prescribed_Pain_Medication, 
                  label: 'GP Prescribed pain medication', 
                },
                {
                  value: Incident_Ulcer_Inputs.Clinical_Nurse_Review_Of_Ulcer, 
                  label: 'Clinical nurse review of ulcer', 
                },
                {
                  value: Incident_Ulcer_Inputs.Cream_Applied, 
                  label: 'Cream applied', 
                },
                {
                  value: Incident_Ulcer_Inputs.Dressing_Applied, 
                  label: 'Dressing applied', 
                },
                {
                  value: Incident_Ulcer_Inputs.Nutritional_Supplements_Have_Been_Commenced, 
                  label: 'Nutritional supplements have been commenced', 
                },
                {
                  value: Incident_Ulcer_Inputs.Refer_To_Wound_Specialist, 
                  label: 'Refer to a wound specialist', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_Ulcer_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Ulcer_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Incident_SubSections.Absconding]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} had absconding behavior.

        ${inputsValues[Incident_Absconding_Inputs.Description_Of_What_Happened]
          ? ` ${inputsValues[Incident_Absconding_Inputs.Description_Of_What_Happened]}.`
          : ''}
         ${UpdateMessageSymbols.Paragraph_Break}
          
        ${inputsValues[Incident_Absconding_Inputs.Actions_Taken] && inputsValues[Incident_Absconding_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_Absconding_Inputs.Actions_Taken]?.includes(Incident_Absconding_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_Absconding_Inputs.Actions_Taken]?.includes(Incident_Absconding_Inputs.RN_Completed_Head_To_Toe_Assessment)
          ? 'An RN has completed head to toe assessment. '
          : ''}

        ${inputsValues[Incident_Absconding_Inputs.Actions_Taken]?.includes(Incident_Absconding_Inputs.Commence_Delirium_Screening)
          ? `Delirium screening has been commenced. `
          : ''}

        ${inputsValues[Incident_Absconding_Inputs.Actions_Taken]?.includes(Incident_Absconding_Inputs.Referral_To_Dementia_Support_Australia)
          ? `${mainValues.firstName} has been referred to dementia support Australia. `
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Incident_Absconding_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_Absconding_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_Absconding_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_Absconding_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_Absconding_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Absconding_Inputs.Description_Of_What_Happened,
              label: 'Description of what happened',
              placeholder: 'Write the description of what happened',
              validationSchema: Yup.string().required('Write the description of what happened'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_Absconding_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_Absconding_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_Absconding_Inputs.RN_Completed_Head_To_Toe_Assessment, 
                  label: 'RN completed head to toe assessment', 
                },
                {
                  value: Incident_Absconding_Inputs.Commence_Delirium_Screening, 
                  label: 'Commence delirium screening', 
                },
                {
                  value: Incident_Absconding_Inputs.Referral_To_Dementia_Support_Australia, 
                  label: 'Referral to dementia support Australia', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_Absconding_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Absconding_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Incident_SubSections.Behaviour]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} had aggressive/violent behavior.

        ${inputsValues[Incident_Behavior_Inputs.Description_Of_What_Happened]
          ? ` Here's the outline of what happened: ${inputsValues[Incident_Behavior_Inputs.Description_Of_What_Happened]}.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
          
        ${inputsValues[Incident_Behavior_Inputs.Actions_Taken] && inputsValues[Incident_Behavior_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_Behavior_Inputs.Actions_Taken]?.includes(Incident_Behavior_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_Behavior_Inputs.Actions_Taken]?.includes(Incident_Behavior_Inputs.Commence_Delirium_Screening)
          ? `Delirium screening has been commenced. `
          : ''}

        ${inputsValues[Incident_Behavior_Inputs.Actions_Taken]?.includes(Incident_Behavior_Inputs.Referral_To_Dementia_Support_Australia)
          ? `${mainValues.firstName} has been referred to dementia support Australia. `
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Incident_Behavior_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_Behavior_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_Behavior_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_Behavior_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_Behavior_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Behavior_Inputs.Description_Of_What_Happened,
              label: 'Description of what happened',
              placeholder: 'Write the description of what happened',
              validationSchema: Yup.string().required('Write the description of what happened'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_Behavior_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_Behavior_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_Behavior_Inputs.Commence_Delirium_Screening, 
                  label: 'Commence delirium screening', 
                },
                {
                  value: Incident_Behavior_Inputs.Referral_To_Dementia_Support_Australia, 
                  label: 'Referral to dementia support Australia', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_Behavior_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Behavior_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Incident_SubSections.Choking]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} was choking.

        ${inputsValues[Incident_Choking_Inputs.Description_Of_What_Happened]
          ? ` ${inputsValues[Incident_Choking_Inputs.Description_Of_What_Happened]}.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[Incident_Choking_Inputs.Actions_Taken] && inputsValues[Incident_Choking_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[Incident_Choking_Inputs.Actions_Taken]?.includes(Incident_Choking_Inputs.GP_Informed)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[Incident_Choking_Inputs.Actions_Taken]?.includes(Incident_Choking_Inputs.First_Aid_Provided_By_RN)
          ? `An RN has provided first aid. `
          : ''}

        ${inputsValues[Incident_Choking_Inputs.Actions_Taken]?.includes(Incident_Choking_Inputs.Geriatrician_Review)
          ? `A geriatric review has been done `
          : ''}

        ${inputsValues[Incident_Choking_Inputs.Actions_Taken]?.includes(Incident_Choking_Inputs.Referral_To_Speech_Pathologist)
          ? `${mainValues.firstName} has been referred to a speech pathologist. `
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}
    
        ${inputsValues[Incident_Choking_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_Choking_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_Choking_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_Choking_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_Choking_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Choking_Inputs.Description_Of_What_Happened,
              label: 'Description of what happened',
              placeholder: 'Wite the description of what happened',
              validationSchema: Yup.string().required('Write the description of what happened'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Incident_Choking_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Incident_Choking_Inputs.GP_Informed, 
                  label: 'GP Informed', 
                },
                {
                  value: Incident_Choking_Inputs.First_Aid_Provided_By_RN, 
                  label: 'First aid provided by RN', 
                },
                {
                  value: Incident_Choking_Inputs.Geriatrician_Review, 
                  label: 'Geriatrician review', 
                },
                {
                  value: Incident_Choking_Inputs.Referral_To_Speech_Pathologist, 
                  label: 'Referral to speech pathologist', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_Choking_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Choking_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Incident_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} had an incident.

        ${inputsValues[Incident_Other_Inputs.Description_Of_What_Happened]
          ? ` ${inputsValues[Incident_Other_Inputs.Description_Of_What_Happened]}. `
          : ''}

        ${UpdateMessageSymbols.Paragraph_Break}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Incident_Other_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Incident_Other_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[Incident_Other_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Incident_Other_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Incident_Other_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Other_Inputs.Description_Of_What_Happened,
              label: 'Description of what happened',
              placeholder: 'Write the description of what happened',
              validationSchema: Yup.string().required('Write the description of what happened'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Incident_Other_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Incident_Other_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
  },
  [Sections.Change_To_Condition]: {
    [Change_To_Condition_SubSections.Change_To_Cognition]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `The cognition of ${mainValues.firstName} has changed. 

        ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.Description_Of_Change]
          ? ` ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.Description_Of_Change]}. `
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.ReviewBy] === RadioBoolean.Yes
          ? ` ${mainValues.firstName} has been reviewed by GP.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[ChangeToCondition_ChangeToCognition_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[ChangeToCondition_ChangeToCognition_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToCognition_Inputs.Description_Of_Change,
              label: 'Description of the change to cognition',
              placeholder: 'Write the description of change',
              validationSchema: Yup.string().required('Write the description of change'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: ChangeToCondition_ChangeToCognition_Inputs.ReviewBy,
              value: undefined,
              label: 'Review by GP?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: ChangeToCondition_ChangeToCognition_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToCognition_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Change_To_Condition_SubSections.Change_To_Mobility]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `The mobility of ${mainValues.firstName} has changed. 

        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Description_Of_Change]
          ? ` ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Description_Of_Change]}. `
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Actions_Taken] && inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToMobility_Inputs.GP_Notified)
          ? `The GP has been informed. `
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToMobility_Inputs.Review_By_Physio)
          ? `${mainValues.firstName} has been reviewed by a physiotherapist: ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Physio_Recommendation]}. `
          : `${mainValues.firstName} hasn’t been reviewed by Physio. `}

        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToMobility_Inputs.GP_Prescribed_Treatment)
          ? `The GP has prescribed the following treatment: ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Prescribed_Treatment]}. `
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[ChangeToCondition_ChangeToMobility_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToMobility_Inputs.Description_Of_Change,
              label: 'Description of the change to mobility',
              placeholder: 'Write the description of change',
              validationSchema: Yup.string().required('Write the description of change'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: ChangeToCondition_ChangeToMobility_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: ChangeToCondition_ChangeToMobility_Inputs.GP_Notified, 
                  label: 'GP Informed', 
                },
                {
                  value: ChangeToCondition_ChangeToMobility_Inputs.Review_By_Physio, 
                  label: 'Review by physio', 
                },
                {
                  value: ChangeToCondition_ChangeToMobility_Inputs.GP_Prescribed_Treatment, 
                  label: 'GP prescribed treatment', 
                },
              ],
              dependentInputs: {
                [ChangeToCondition_ChangeToMobility_Inputs.Review_By_Physio]: [{
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    value: undefined,
                    name: ChangeToCondition_ChangeToMobility_Inputs.Physio_Recommendation,
                    label: 'Physio recommendation',
                    required: true,
                    validationSchema: Yup.string().required('Write the physio recommendation'),
                    placeholder: 'Write the physio recommendation',
                }],
                [ChangeToCondition_ChangeToMobility_Inputs.GP_Prescribed_Treatment]: [{
                  type: UpdateInputsTypes.TextInput,
                  multiline: true,
                  value: undefined,
                  name: ChangeToCondition_ChangeToMobility_Inputs.Prescribed_Treatment,
                  label: 'GP prescribed treatment',
                  placeholder: 'Write the prescribed treatment',
                }]
              }
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: ChangeToCondition_ChangeToMobility_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToMobility_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Change_To_Condition_SubSections.Change_To_Appetite]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `The appetite of ${mainValues.firstName} has changed. 

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Description_Of_Change]
          ? ` ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Description_Of_Change]}.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken] && inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToAppetite_Inputs.Commenced_Food_Chart)
          ? 'A food chart has been commenced. '
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToAppetite_Inputs.Weight_Reviewed)
          ? `The weight has been reviewed. `
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToAppetite_Inputs.Weekly_Weight_Monitoring)
          ? `Weight will be monitored weekly. `
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToAppetite_Inputs.Dietician_Referral)
          ? `${mainValues.firstName} has been referred to a dietician `
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToAppetite_Inputs.GP_Notified)
          ? `The GP has been informed. `
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken]?.includes(ChangeToCondition_ChangeToAppetite_Inputs.Recommendations_For_Supplements)
          ? `Supplements have been recommended. `
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[ChangeToCondition_ChangeToAppetite_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToAppetite_Inputs.Description_Of_Change,
              label: 'Description of the change to appetite',
              placeholder: 'Write the description of change',
              validationSchema: Yup.string().required('Write the description of change'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: ChangeToCondition_ChangeToAppetite_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: ChangeToCondition_ChangeToAppetite_Inputs.GP_Notified, 
                  label: 'GP notified', 
                },
                {
                  value: ChangeToCondition_ChangeToAppetite_Inputs.Commenced_Food_Chart, 
                  label: 'Commenced food chart', 
                },
                {
                  value: ChangeToCondition_ChangeToAppetite_Inputs.Weight_Reviewed, 
                  label: 'Weight reviewed', 
                },
                {
                  value: ChangeToCondition_ChangeToAppetite_Inputs.Weekly_Weight_Monitoring, 
                  label: 'Weight will be monitored weekly', 
                },
                {
                  value: ChangeToCondition_ChangeToAppetite_Inputs.Dietician_Referral, 
                  label: 'Dietician referral', 
                },
                {
                  value: ChangeToCondition_ChangeToAppetite_Inputs.Recommendations_For_Supplements, 
                  label: 'Recommendations for supplements', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: ChangeToCondition_ChangeToAppetite_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToAppetite_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Change_To_Condition_SubSections.Change_To_Behaviour_Mood]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `The mood and behavior of ${mainValues.firstName} has changed. 

        ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Description_Of_Change]
          ? ` Here is an outline of what happened: ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Description_Of_Change]}.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
          
        ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Action_Taken_To_Care_For_This]
          ? `Our team has taken the following actions: ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Action_Taken_To_Care_For_This]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}

        ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[ChangeToCondition_ChangeToBehaviourMood_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToBehaviourMood_Inputs.Description_Of_Change,
              label: 'Description of the change to behavior',
              placeholder: 'Write the description of change',
              validationSchema: Yup.string().required('Write the description of change'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToBehaviourMood_Inputs.Action_Taken_To_Care_For_This,
              label: 'Action taken to care for this',
              placeholder: 'Write the action taken to care for this',
              validationSchema: Yup.string().required('Write the action taken to care for this'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: ChangeToCondition_ChangeToBehaviourMood_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_ChangeToBehaviourMood_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Change_To_Condition_SubSections.Weight_Change_Update]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `The weight of ${mainValues.firstName} has changed. 

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Description_Of_Change]
          ? ` ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Description_Of_Change]}.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken] && inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.includes(ChangeToCondition_WeightChangeUpdate_Inputs.Commenced_Food_Chart)
          ? 'A food chart has been commenced. '
          : ''}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.includes(ChangeToCondition_WeightChangeUpdate_Inputs.Weight_Reviewed)
          ? `The weight has been reviewed. `
          : ''}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.includes(ChangeToCondition_WeightChangeUpdate_Inputs.Weekly_Weight_Monitoring)
          ? `Weight will be monitored weekly. `
          : ''}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.includes(ChangeToCondition_WeightChangeUpdate_Inputs.Dietician_Referral)
          ? `${mainValues.firstName} has been referred to a dietician. `
          : ''}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.includes(ChangeToCondition_WeightChangeUpdate_Inputs.GP_Notified)
          ? `The GP has been informed. `
          : ''}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken]?.includes(ChangeToCondition_WeightChangeUpdate_Inputs.Recommendations_For_Supplements)
          ? `Supplements have been recommended. `
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[ChangeToCondition_WeightChangeUpdate_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_WeightChangeUpdate_Inputs.Description_Of_Change,
              label: 'Description of the weight change',
              placeholder: 'Write the description of change',
              validationSchema: Yup.string().required('Write the description of change'),
              required: true,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: ChangeToCondition_WeightChangeUpdate_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: ChangeToCondition_WeightChangeUpdate_Inputs.GP_Notified, 
                  label: 'GP notified', 
                },
                {
                  value: ChangeToCondition_WeightChangeUpdate_Inputs.Commenced_Food_Chart, 
                  label: 'Commenced food chart', 
                },
                {
                  value: ChangeToCondition_WeightChangeUpdate_Inputs.Weight_Reviewed, 
                  label: 'Weight reviewed', 
                },
                {
                  value: ChangeToCondition_WeightChangeUpdate_Inputs.Weekly_Weight_Monitoring, 
                  label: 'Weight will be monitored weekly', 
                },
                {
                  value: ChangeToCondition_WeightChangeUpdate_Inputs.Dietician_Referral, 
                  label: 'Dietician referral', 
                },
                {
                  value: ChangeToCondition_WeightChangeUpdate_Inputs.Recommendations_For_Supplements, 
                  label: 'Recommendations for supplements', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: ChangeToCondition_WeightChangeUpdate_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_WeightChangeUpdate_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
    [Change_To_Condition_SubSections.Other_General_Update]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} had some changes in condition. 

        ${inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.Description_Of_Change]
          ? ` ${inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.Description_Of_Change]}.`
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[ChangeToCondition_OtherGeneralUpdate_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_OtherGeneralUpdate_Inputs.Description_Of_Change,
              label: 'Description of change',
              placeholder: 'Write the description of change',
              validationSchema: Yup.string().required('Write the description of change'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: ChangeToCondition_OtherGeneralUpdate_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: ChangeToCondition_OtherGeneralUpdate_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
  },
  [Sections.Medication_Incidents]: {
    [Medication_Incidents_SubSections.Medication_Incidents]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `There has been an incident in relation to ${mainValues.firstName}'s medication. 

        ${UpdateMessageSymbols.Paragraph_Break}

        Outline of what happened 

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Description_Of_What_Happened]}. 
        The medication this relates to is ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.What_Medication]}.

        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Possible_Side_Effects]
          ? `The possible side effects are ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Possible_Side_Effects]}. We will monitor closely to see if any of these side effects are evident and will take appropriate action if they occur. `
          : ''}
        ${UpdateMessageSymbols.Paragraph_Break}
        
        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Actions_Taken] && inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}

        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Actions_Taken]?.includes(MedicationIncidents_MedicationIncidents_Inputs.GP_Notified)
          ? 'The GP has been informed. '
          : ''}

        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Actions_Taken]?.includes(MedicationIncidents_MedicationIncidents_Inputs.Inform_The_Pharmacy)
          ? `The pharmacy has been informed. `
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}`
          : ''}
        
        ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[MedicationIncidents_MedicationIncidents_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: MedicationIncidents_MedicationIncidents_Inputs.Description_Of_What_Happened,
              label: 'Description of what happened',
              placeholder: 'Describe what happened',
              validationSchema: Yup.string().required('Describe what happened'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              name: MedicationIncidents_MedicationIncidents_Inputs.What_Medication,
              label: 'What medication',
              placeholder: 'Medication',
              validationSchema: Yup.string().required('Describe what medication'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: MedicationIncidents_MedicationIncidents_Inputs.Possible_Side_Effects,
              label: 'Possible side effects',
              placeholder: 'Describe possible side effects',
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: MedicationIncidents_MedicationIncidents_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: MedicationIncidents_MedicationIncidents_Inputs.GP_Notified, 
                  label: 'GP Notified', 
                },
                {
                  value: MedicationIncidents_MedicationIncidents_Inputs.Inform_The_Pharmacy, 
                  label: 'Inform the pharmacy', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: MedicationIncidents_MedicationIncidents_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: MedicationIncidents_MedicationIncidents_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
  },
  [Sections.Pain]: {
    [Pain_SubSections.Pain]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has pain 
          ${inputsValues[Pain_Pain_Inputs.Pain_Places]?.front?.length || inputsValues[Pain_Pain_Inputs.Pain_Places]?.back?.length || inputsValues[Pain_Pain_Inputs.Pain_Places]?.additional
            ? `  in the following body parts${inputsValues[Pain_Pain_Inputs.Pain_Places].front.length ? ` front: ${inputsValues[Pain_Pain_Inputs.Pain_Places].front.join(', ')}` : ''}
            ${inputsValues[Pain_Pain_Inputs.Pain_Places].back.length ? ` back: ${inputsValues[Pain_Pain_Inputs.Pain_Places].back.join(', ')}` : ''}${inputsValues[Pain_Pain_Inputs.Pain_Places].additional
              ? `${inputsValues[Pain_Pain_Inputs.Pain_Places].front.length || inputsValues[Pain_Pain_Inputs.Pain_Places].back.length
                  ? ', ' : ''}
                ${inputsValues[Pain_Pain_Inputs.Pain_Places].additional}`
              : ''}. `
            : '. '}

            ${inputsValues[Pain_Pain_Inputs.What_Is_The_Cause_Of_The_Pain]
              ? `The pain was caused by ${inputsValues[Pain_Pain_Inputs.What_Is_The_Cause_Of_The_Pain]}. `
              : ''}
            ${inputsValues[Pain_Pain_Inputs.Select_Type_Of_Pain]?.length
              ? ` The pain is ${inputsValues[Pain_Pain_Inputs.Select_Type_Of_Pain]?.join(', ')}. `
              : ''}
            ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Pain_Pain_Inputs.Actions_Taken] && inputsValues[Pain_Pain_Inputs.Actions_Taken]?.length
          ? 'Our team has taken the following actions: '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.GP_Notified)
          ? 'The GP has been informed. '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.Pain_Relief_Analygesic_Administered)
          ? `${inputsValues[Pain_Pain_Inputs.Pain_Relief_Was_Effective] === Pain_Pain_Inputs.Pain_Relief_Was_Effective_Yes
                ? 'Pain relief was effective. '
                : inputsValues[Pain_Pain_Inputs.Pain_Relief_Was_Effective] === Pain_Pain_Inputs.Pain_Relief_Was_Effective_No
                    ? `Pain relief wasn’t effective. ${inputsValues[Pain_Pain_Inputs.What_Other_Actions_Are_Being_Taken_To_Relieve_Pain] && `The following actions are being taken to relieve pain: ${inputsValues[Pain_Pain_Inputs.What_Other_Actions_Are_Being_Taken_To_Relieve_Pain]}. `}`
                    : `${mainValues.firstName} is waiting for effect of pain relief. `}`
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.Pain_Medication_Not_Required)
          ? 'Pain medication is not required. '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.RN_Has_Conducted_An_Assessment_Of_Pain)
          ? 'RN has conducted an assessment of pain. '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.Heat_Pack_Used)
          ? 'A heat pack has been used. '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.Ice_Pack_Used)
          ? 'An ice pack has been used. '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.Massage_Has_Been_Given)
          ? 'A message has been given. '
          : ''}
        ${inputsValues[Pain_Pain_Inputs.Actions_Taken]?.includes(Pain_Pain_Inputs.Repositioning_As_Required)
          ? 'Repositioning has been done as required. '
          : ''}

        Our team is regularly monitoring ${mainValues.firstName} and reassessing care as required.
        
        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Pain_Pain_Inputs.How_Is_The_Resident_Feeling]
          ? `How is ${mainValues.firstName}? ${inputsValues[Pain_Pain_Inputs.How_Is_The_Resident_Feeling]}. ${UpdateMessageSymbols.Paragraph_Break}` 
          : ''}
        
        ${inputsValues[Pain_Pain_Inputs.Other_Information]
          ? `Other Information: ${inputsValues[Pain_Pain_Inputs.Other_Information]}.`
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED && inputsValues[Pain_Pain_Inputs.Other_Information]
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      conjugateInputsBoxClasses: {
        0: [`${styles.Pain_Pain_row} ${styles.margin_top}`]
      },
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Pain_Pain_Inputs.What_Is_The_Cause_Of_The_Pain,
              label: 'What is the cause of the pain',
              placeholder: 'Write the cause of the pain',
              validationSchema: Yup.string().required('Write the cause of the pain'),
              required: true,
            },
            {
              type: UpdateInputsTypes.HumanInput,
              name: Pain_Pain_Inputs.Pain_Places,
              className: styles.Pain_Pain_human,
              headerType: 'pain',
              conjugateInputBoxId: 0,
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Pain_Pain_Inputs.Select_Type_Of_Pain,
              label: 'Select type of pain:',
              className: styles.Pain_Pain_Select_Type_Of_Pain,
              conjugateInputBoxId: 0,
              checkboxes: [
                {
                  value: Pain_Pain_Inputs.Stabbing, 
                  label: 'Stabbing', 
                },
                {
                  value: Pain_Pain_Inputs.Sharp, 
                  label: 'Sharp', 
                },
                {
                  value: Pain_Pain_Inputs.Dull, 
                  label: 'Dull', 
                },
                {
                  value: Pain_Pain_Inputs.Neuropathic, 
                  label: 'Neuropathic', 
                },
                {
                  value: Pain_Pain_Inputs.Burning, 
                  label: 'Burning', 
                },
                {
                  value: Pain_Pain_Inputs.Aching, 
                  label: 'Aching', 
                },
              ]
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Pain_Pain_Inputs.Actions_Taken,
              label: 'Actions taken (select all that apply):',
              checkboxes: [
                {
                  value: Pain_Pain_Inputs.GP_Notified, 
                  label: 'GP Notified', 
                },
                {
                  value: Pain_Pain_Inputs.Pain_Relief_Analygesic_Administered, 
                  label: 'Pain relief analgesic administered', 
                },
                {
                  value: Pain_Pain_Inputs.Pain_Medication_Not_Required, 
                  label: 'Pain medication not required', 
                },
                {
                  value: Pain_Pain_Inputs.RN_Has_Conducted_An_Assessment_Of_Pain, 
                  label: 'RN has conducted an assessment of pain ', 
                },
                {
                  value: Pain_Pain_Inputs.Heat_Pack_Used, 
                  label: 'Heat Pack Used', 
                },
                {
                  value: Pain_Pain_Inputs.Ice_Pack_Used, 
                  label: 'Ice Pack Used', 
                },
                {
                  value: Pain_Pain_Inputs.Massage_Has_Been_Given, 
                  label: 'Massage has been given ', 
                },
                {
                  value: Pain_Pain_Inputs.Repositioning_As_Required, 
                  label: 'Repositioning as required', 
                },
              ],
              dependentInputs: {
                [Pain_Pain_Inputs.Pain_Relief_Analygesic_Administered]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    radios: [
                      {value: Pain_Pain_Inputs.Pain_Relief_Was_Effective_Yes, label: 'Yes'},
                      {value: Pain_Pain_Inputs.Pain_Relief_Was_Effective_No, label: 'No'},
                      {value: Pain_Pain_Inputs.Pain_Relief_Was_Effective_Waiting, label: 'Waiting for effect '},
                    ],
                    name: Pain_Pain_Inputs.Pain_Relief_Was_Effective,
                    value: undefined,
                    label: 'Pain relief was effective',
                    className: `${styles.padding_1} ${styles.fullWidth}`,
                    required: true,
                    validationSchema: Yup.string().required('Select one'),
                    dependentInputs: {
                      [Pain_Pain_Inputs.Pain_Relief_Was_Effective_No]: [
                        {
                          type: UpdateInputsTypes.TextInput,
                          multiline: true,
                          className: styles.padding_1,
                          required: true,
                          validationSchema: Yup.string().required('Write iother actions are being taken to relieve pain'),
                          name: Pain_Pain_Inputs.What_Other_Actions_Are_Being_Taken_To_Relieve_Pain,
                          label: 'What other actions are being taken to relieve pain?',
                          placeholder: 'Write other actions are being taken to relieve pain',
                        },
                      ]
                    },
                  },
                ]
              }
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Pain_Pain_Inputs.How_Is_The_Resident_Feeling,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              validationSchema: Yup.string().required('Write how is the resident feeling'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Pain_Pain_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        },
      ]
    },
  },
/*   [Sections.Physiotherapy_Review]: {
    [Physiotherapy_Review_SubSections.General_Update_From_Physio]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(`NOT IMPLEMENTED`),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Physiotherapy_Review_General_Update_From_Physio_Inputs.General_Description,
              label: 'General description',
              placeholder: 'Write general description',
              validationSchema: Yup.string().required('Write general description'),
              required: true,
            }
          ]
        }
      ]
    },
    [Physiotherapy_Review_SubSections.New_Aid_Walker]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(`NOT IMPLEMENTED`),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Physiotherapy_Review_New_Aid_Walker_Inputs.General_Description,
              label: 'General description',
              placeholder: 'Write general description',
              validationSchema: Yup.string().required('Write general description'),
              required: true,
            }
          ]
        }
      ]
    },
    [Physiotherapy_Review_SubSections.New_Exercises]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(`NOT IMPLEMENTED`),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Physiotherapy_Review_New_Exercises_Inputs.General_Description,
              label: 'General description',
              placeholder: 'Write general description',
              validationSchema: Yup.string().required('Write general description'),
              required: true,
            }
          ]
        }
      ]
    },
    [Physiotherapy_Review_SubSections.Mobility_ReAssessed]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(`NOT IMPLEMENTED`),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Physiotherapy_Review_Mobility_ReAssessed_Inputs.General_Description,
              label: 'General description',
              placeholder: 'Write general description',
              validationSchema: Yup.string().required('Write general description'),
              required: true,
            }
          ]
        }
      ]
    },
  }, */
  [Sections.Request_For_Items]: {
    [Request_For_Items_SubSections.Clothing_Footwear]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} needs the following items: 
        ${inputsValues[Request_For_Items_Clothing_Footwear_Inputs.Description_Of_Items_That_Are_Needed]}. 
        The size of items are: ${inputsValues[Request_For_Items_Clothing_Footwear_Inputs.Size_Of_Items]}. 

        ${inputsValues[Request_For_Items_Clothing_Footwear_Inputs.Other_Information]
          ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Request_For_Items_Clothing_Footwear_Inputs.Other_Information]}.`
          : ''} 

        ${mainValues.phoneCall 
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Clothing_Footwear_Inputs.Description_Of_Items_That_Are_Needed,
              label: 'Describe items needed',
              placeholder: 'Write items needed',
              validationSchema: Yup.string().required('Write items needed'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Clothing_Footwear_Inputs.Size_Of_Items,
              label: 'Describe size of item',
              placeholder: 'Write size of item',
              validationSchema: Yup.string().required('Write size of item'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Clothing_Footwear_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Request_For_Items_SubSections.Food_Items]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} needs the following items: 
        ${inputsValues[Request_For_Items_Food_Items_Inputs.Description_Of_Items_That_Are_Needed]}. 
        The size of items are: ${inputsValues[Request_For_Items_Food_Items_Inputs.Size_Of_Items]}. 

        ${inputsValues[Request_For_Items_Food_Items_Inputs.Other_Information]
          ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Request_For_Items_Food_Items_Inputs.Other_Information]}.`
          : ''} 
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Food_Items_Inputs.Description_Of_Items_That_Are_Needed,
              label: 'Describe items needed',
              placeholder: 'Write items needed',
              validationSchema: Yup.string().required('Write items needed'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Food_Items_Inputs.Size_Of_Items,
              label: 'Describe size of item',
              placeholder: 'Write size of item',
              validationSchema: Yup.string().required('Write size of item'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Food_Items_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Request_For_Items_SubSections.Personal_Care_Items]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} needs the following items: 
        ${inputsValues[Request_For_Items_Personal_Care_Items_Inputs.Description_Of_Items_That_Are_Needed]}. 
        The size of items are: ${inputsValues[Request_For_Items_Personal_Care_Items_Inputs.Size_Of_Items]}. 

        ${inputsValues[Request_For_Items_Personal_Care_Items_Inputs.Other_Information]
          ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Request_For_Items_Personal_Care_Items_Inputs.Other_Information]}.`
          : ''} 
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Personal_Care_Items_Inputs.Description_Of_Items_That_Are_Needed,
              label: 'Describe items needed',
              placeholder: 'Write items needed',
              validationSchema: Yup.string().required('Write items needed'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Personal_Care_Items_Inputs.Size_Of_Items,
              label: 'Describe size of item',
              placeholder: 'Write size of item',
              validationSchema: Yup.string().required('Write size of item'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Personal_Care_Items_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
    [Request_For_Items_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} needs the following items: 
        ${inputsValues[Request_For_Items_Other_Inputs.Description_Of_Items_That_Are_Needed]}. 
        The size of items are: ${inputsValues[Request_For_Items_Other_Inputs.Size_Of_Items]}. 

        ${inputsValues[Request_For_Items_Other_Inputs.Other_Information]
          ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Request_For_Items_Other_Inputs.Other_Information]}.`
          : ''} 
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Other_Inputs.Description_Of_Items_That_Are_Needed,
              label: 'Describe items needed',
              placeholder: 'Write items needed',
              validationSchema: Yup.string().required('Write items needed'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Other_Inputs.Size_Of_Items,
              label: 'Describe size of item',
              placeholder: 'Write size of item',
              validationSchema: Yup.string().required('Write size of item'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Request_For_Items_Other_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },
  [Sections.Return_From_Hospital]: {
    [Return_From_Hospital_SubSections.Return_From_Hospital]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has returned from hospital. 
        They were transferred to hospital on ${moment(inputsValues[Return_From_Hospital_Inputs.Date_Transfer_To_Hospital]).format("DD MMM YYYY")} at ${moment(inputsValues[Return_From_Hospital_Inputs.Date_Transfer_To_Hospital]).format("hh:mm A")}
        and returned on ${moment(inputsValues[Return_From_Hospital_Inputs.Date_Returned_From_Hospital]).format("DD MMM YYYY")} at ${moment(inputsValues[Return_From_Hospital_Inputs.Date_Returned_From_Hospital]).format("hh:mm A")}. 
        
        ${UpdateMessageSymbols.Paragraph_Break}

        Reason for hospitalisation: ${inputsValues[Return_From_Hospital_Inputs.Reason_For_Hospitalisation]}. 

        ${UpdateMessageSymbols.Paragraph_Break}

        Summary of the hospital visit: ${inputsValues[Return_From_Hospital_Inputs.Summary_Of_The_Hospital_Visit]}. 

        ${UpdateMessageSymbols.Paragraph_Break}

        General summary of changes:
        ${inputsValues[Return_From_Hospital_Inputs.There_Been_A_Change_To_Medication] === RadioBoolean.No
          ? 'Medication has not been changed. '
          : `Medication has been changed: ${inputsValues[Return_From_Hospital_Inputs.Medication_Changes]}. `}

        ${inputsValues[Return_From_Hospital_Inputs.Has_There_Been_A_Change_To_Diet] === RadioBoolean.No
          ? 'There has been no change to diet. '
          : `${mainValues.firstName}  has a new diet: ${inputsValues[Return_From_Hospital_Inputs.Change_To_Diet]}. `}

        ${inputsValues[Return_From_Hospital_Inputs.Has_There_Been_A_Change_To_Mobility] === RadioBoolean.Yes
          ? `Mobility has been changed: ${inputsValues[Return_From_Hospital_Inputs.Describe_The_Change_To_Mobility]}. `
          : ''}

        ${inputsValues[Return_From_Hospital_Inputs.Has_There_Been_A_Change_To_Weight] === RadioBoolean.Yes
          ? `Weight has been changed: ${inputsValues[Return_From_Hospital_Inputs.Change_To_Weight]}. `
          : ''}

        ${inputsValues[Return_From_Hospital_Inputs.A_Skin_Integrity_Check_Has_Been_Completed_By_An_RN] === RadioBoolean.Yes
          ? 'A skin integrity check has been completed by an RN. '
          : ''}

        ${inputsValues[Return_From_Hospital_Inputs.Any_Skin_Issues_Found] === RadioBoolean.Yes
          ? `These skin issues were found: ${inputsValues[Return_From_Hospital_Inputs.Describe_The_Issues_Found]}. `
          : ''}

        ${inputsValues[Return_From_Hospital_Inputs.Followup_Appointments_Required] === RadioBoolean.Yes
          ? `Follow-up appointment(s) required: ${inputsValues[Return_From_Hospital_Inputs.Describe_The_Appointments_Required_And_When]}. `
          : ''}

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Return_From_Hospital_Inputs.How_Is_The_Resident]
          ? `How is ${mainValues.firstName}? ${inputsValues[Return_From_Hospital_Inputs.How_Is_The_Resident]}. ` 
          : ''}

        ${inputsValues[Return_From_Hospital_Inputs.Other_Information]
          ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Return_From_Hospital_Inputs.Other_Information]}.`
          : ''} 
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? UpdateMessageSymbols.Paragraph_Break
          : ''}
        ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
          ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
          : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              name: Return_From_Hospital_Inputs.Reason_For_Hospitalisation,
              label: 'Reason for hospitalisation',
              placeholder: 'Write reason for hospitalisation',
              multiline: true,
              validationSchema: Yup.string().required('Write reason for hospitalisation'),
              required: true,
            },
            {
              type: UpdateInputsTypes.DateInput,
              name: Return_From_Hospital_Inputs.Date_Transfer_To_Hospital,
              label: 'Date transfer to hospital',
              placeholder: 'Select date transfer to hospital',
              dateType: 'dateTime',
              validationSchema: Yup.date().required('Date transfer to hospital is required'),
            },
            {
              type: UpdateInputsTypes.DateInput,
              name: Return_From_Hospital_Inputs.Date_Returned_From_Hospital,
              label: 'Date returned from hospital',
              placeholder: 'Select date returned from hospital',
              dateType: 'dateTime',
              validationSchema: Yup.date().required('Date returned from hospital is required'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              name: Return_From_Hospital_Inputs.Summary_Of_The_Hospital_Visit,
              label: 'Summary of the hospital visit',
              placeholder: 'Write summary of the hospital visit',
              multiline: true,
              validationSchema: Yup.string().required('Write summary of the hospital visit'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.There_Been_A_Change_To_Medication,
              value: undefined,
              label: 'Has there been a change to medication?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Return_From_Hospital_Inputs.Medication_Changes,
                    required: true,
                    validationSchema: Yup.string().required('Write Medication changes'),
                    label: 'Medication changes',
                    placeholder: 'Write the medication changes',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.Has_There_Been_A_Change_To_Diet,
              value: undefined,
              label: 'Has there been a change to diet?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Return_From_Hospital_Inputs.Change_To_Diet,
                    required: true,
                    validationSchema: Yup.string().required('Write the change to diet'),
                    label: 'Diet change',
                    placeholder: 'Write the change to diet',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.Has_There_Been_A_Change_To_Mobility,
              value: undefined,
              label: 'Has there been a change to mobility?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Return_From_Hospital_Inputs.Describe_The_Change_To_Mobility,
                    required: true,
                    validationSchema: Yup.string().required('Write the change to mobility'),
                    label: 'Mobility change',
                    placeholder: 'Describe the change to mobility',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.Has_There_Been_A_Change_To_Weight,
              value: undefined,
              label: 'Has there been a change to weight?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Return_From_Hospital_Inputs.Change_To_Weight,
                    required: true,
                    validationSchema: Yup.string().required('Write the change to weight'),
                    label: 'Weight change',
                    placeholder: 'Describe the change to weight',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.A_Skin_Integrity_Check_Has_Been_Completed_By_An_RN,
              value: undefined,
              label: 'A skin integrity check has been completed by an RN',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.Any_Skin_Issues_Found,
              value: undefined,
              label: 'Any skin issues found?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Return_From_Hospital_Inputs.Describe_The_Issues_Found,
                    required: true,
                    validationSchema: Yup.string().required('Write the issues found'),
                    label: 'Issues found',
                    placeholder: 'Describe the issues found',
                  },
                ]
              },
            },

            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Return_From_Hospital_Inputs.Followup_Appointments_Required,
              value: undefined,
              label: 'Follow-up appointment(s) required?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Return_From_Hospital_Inputs.Describe_The_Appointments_Required_And_When,
                    required: true,
                    validationSchema: Yup.string().required('Write the appointment(s) required and when'),
                    label: 'Appointment(s) required and when',
                    placeholder: 'Describe the appointment(s) required and when',
                  },
                ]
              },
            },

            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Return_From_Hospital_Inputs.How_Is_The_Resident,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              required: true,
              validationSchema: Yup.string().required('Write how is the resident feeling'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Return_From_Hospital_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },
  [Sections.Specialist_Appointment]: {
    [Specialist_Appointment_SubSections.Referral_To_Specialist]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} has an appointment booked with a 
        ${inputsValues[Referral_To_Specialist_Inputs.Select_Type_Of_Specialist] !== Referral_To_Specialist_Inputs.Other
          ? inputsValues[Referral_To_Specialist_Inputs.Select_Type_Of_Specialist]
          : inputsValues[Referral_To_Specialist_Inputs.Type_Of_Specialist]}. 

        Reason for appointment: ${inputsValues[Referral_To_Specialist_Inputs.Reason_For_Appointment]}

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Referral_To_Specialist_Inputs.GP_Has_Provided_Referral] === RadioBoolean.Yes
          ? 'The GP has provided a referral letter. '
          : 'The GP will need to provide a referral letter. '}

        ${inputsValues[Referral_To_Specialist_Inputs.Referral_Has_Been_Provided_To_Specialist] === RadioBoolean.Yes
          ? `Referral letter has been provided to the 
              ${inputsValues[Referral_To_Specialist_Inputs.Select_Type_Of_Specialist] !== Referral_To_Specialist_Inputs.Other
                ? inputsValues[Referral_To_Specialist_Inputs.Select_Type_Of_Specialist]
                : inputsValues[Referral_To_Specialist_Inputs.Type_Of_Specialist]}. `
          : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          Details of the appointment:

          ${UpdateMessageSymbols.Paragraph_Break}

          Location: ${inputsValues[Referral_To_Specialist_Inputs.Location_Of_The_Appointment] === Referral_To_Specialist_Inputs.At_Nursing_Home
                      ? nursingStore.activeNursingHome?.name
                      : inputsValues[Referral_To_Specialist_Inputs.Address_Of_Appointment]}. 

          ${UpdateMessageSymbols.Paragraph_Break}

          When: ${moment(inputsValues[Referral_To_Specialist_Inputs.Time_And_Date_Of_The_Appointment]).format("DD MMM YYYY")} 
          at ${moment(inputsValues[Referral_To_Specialist_Inputs.Time_And_Date_Of_The_Appointment]).format("hh:mm A")}.
          
          ${UpdateMessageSymbols.Paragraph_Break}
          
          ${inputsValues[Referral_To_Specialist_Inputs.Contact_Details_Of_The_Allied_Health_Professional] ? `Contact phone number: ${inputsValues[Referral_To_Specialist_Inputs.Contact_Details_Of_The_Allied_Health_Professional]} ${UpdateMessageSymbols.Paragraph_Break}` : ''}

          ${inputsValues[Referral_To_Specialist_Inputs.A_Family_Member_Will_Need_To_Take_The_Resident] === RadioBoolean.Yes
           ? 'A family member will need to take the resident to the appointment. '
           : ''}

          ${inputsValues[Referral_To_Specialist_Inputs.Transport_Is_Required] === RadioBoolean.Yes
           ? 'Transport is required. '
           : ''}

          ${inputsValues[Referral_To_Specialist_Inputs.Payment_Is_Required] === RadioBoolean.Yes
           ? 'Payment is required. '
           : 'No Payment is required. '}

          ${inputsValues[Referral_To_Specialist_Inputs.Items_Need_To_Be_Taken_To_The_Appointment] === RadioBoolean.Yes
           ? `These items will be needed at the appointment: ${inputsValues[Referral_To_Specialist_Inputs.What_Items_Are_Required_To_Be_Taken]}. `
           : ''}

          ${inputsValues[Referral_To_Specialist_Inputs.An_Interpreter_Is_Require] === RadioBoolean.Yes
           ? `An interpreter is required. 
             ${inputsValues[Referral_To_Specialist_Inputs.Interpreter_Has_Been_Booked] === RadioBoolean.Yes
               ? 'An Interpreter has been booked. '
               : ''}`
           : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Referral_To_Specialist_Inputs.How_Is_The_Resident]
            ? `How is ${mainValues.firstName}? ${inputsValues[Referral_To_Specialist_Inputs.How_Is_The_Resident]}. ` 
            : ''}
    
          ${inputsValues[Referral_To_Specialist_Inputs.Other_Information]
            ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Referral_To_Specialist_Inputs.Other_Information]}.`
            : ''} 
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
        steps: [
          {
            inputs: [
              {
                type: UpdateInputsTypes.Select,
                name: Referral_To_Specialist_Inputs.Select_Type_Of_Specialist,
                label: 'Select type of Specialist',
                placeholder: 'Select',
                items: [
                  {title: 'Geriatrician ', value: Referral_To_Specialist_Inputs.Geriatrician, id: Referral_To_Specialist_Inputs.Geriatrician},
                  {title: 'Nurse Practitioner', value: Referral_To_Specialist_Inputs.Nurse_Practitioner, id: Referral_To_Specialist_Inputs.Nurse_Practitioner},
                  {title: 'Psychogeriatrician', value: Referral_To_Specialist_Inputs.Psychogeriatrician, id: Referral_To_Specialist_Inputs.Psychogeriatrician},
                  {title: 'Wound Consult', value: Referral_To_Specialist_Inputs.Wound_Consult, id: Referral_To_Specialist_Inputs.Wound_Consult},
                  {title: 'Cardiologist', value: Referral_To_Specialist_Inputs.Cardiologist, id: Referral_To_Specialist_Inputs.Cardiologist},
                  {title: 'Neurologist', value: Referral_To_Specialist_Inputs.Neurologist, id: Referral_To_Specialist_Inputs.Neurologist},
                  {title: 'Other', value: Referral_To_Specialist_Inputs.Other, id: Referral_To_Specialist_Inputs.Other},
                ],
                validationSchema: Yup.string().required('Select type of Specialist'),
                required: true,
              },
              {
                type: UpdateInputsTypes.TextInput,
                name: Referral_To_Specialist_Inputs.Type_Of_Specialist,
                label: 'What the other Specialist?',
                placeholder: 'Write what the other Specialist',
                required: true,
                validationSchema: Yup.string().required('Write reason for appointment'),
                conditions: [
                  {[Referral_To_Specialist_Inputs.Select_Type_Of_Specialist]: Referral_To_Specialist_Inputs.Other}
                ]
              },
              {
                type: UpdateInputsTypes.TextInput,
                name: Referral_To_Specialist_Inputs.Reason_For_Appointment,
                label: 'Reason for appointment',
                multiline: true,
                placeholder: 'Write reason for appointment',
                validationSchema: Yup.string().required('Write reason for appointment'),
                required: true,
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.GP_Has_Provided_Referral,
                value: undefined,
                label: 'GP has provided referral',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: Referral_To_Specialist_Inputs.At_Nursing_Home, label: 'At nursing home '},
                  {value: Referral_To_Specialist_Inputs.External_Appointment, label: 'External appointment'},
                ],
                name: Referral_To_Specialist_Inputs.Location_Of_The_Appointment,
                value: undefined,
                label: 'Location of the appointment (please select one)',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
                dependentInputs: {
                  [Referral_To_Specialist_Inputs.External_Appointment]: [
                    {
                      type: UpdateInputsTypes.TextInput,
                      multiline: true,
                      className: styles.padding_1,
                      name: Referral_To_Specialist_Inputs.Address_Of_Appointment,
                      required: true,
                      validationSchema: Yup.string().required('Write the address of appointment'),
                      label: 'Address of appointment',
                      placeholder: 'Write address of appointment',
                    },
                  ]
                },
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.Referral_Has_Been_Provided_To_Specialist,
                value: undefined,
                label: 'Referral has been provided to Specialist',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
              },
              {
                type: UpdateInputsTypes.DateInput,
                name: Referral_To_Specialist_Inputs.Time_And_Date_Of_The_Appointment,
                dateType: 'dateTime',
                label: 'Time and Date of the appointment',
                placeholder: 'Select Time and Date of the appointment',
                validationSchema: Yup.date().required('Time and Date of the appointment is required'),
              },
              {
                type: UpdateInputsTypes.TextInput,
                name: Referral_To_Specialist_Inputs.Contact_Details_Of_The_Allied_Health_Professional,
                label: 'Contact details of the Specialist',
                multiline: true,
                placeholder: 'Write contact details of the Specialist',
                required: false,
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.A_Family_Member_Will_Need_To_Take_The_Resident,
                value: undefined,
                label: 'A family member will need to take the resident',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.Transport_Is_Required,
                value: undefined,
                label: 'Transport is required',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.Payment_Is_Required,
                value: undefined,
                label: 'Payment is required',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.Items_Need_To_Be_Taken_To_The_Appointment,
                value: undefined,
                label: 'Items need to be taken to the appointment?',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
                dependentInputs: {
                  [RadioBoolean.Yes]: [
                    {
                      type: UpdateInputsTypes.TextInput,
                      multiline: true,
                      className: styles.padding_1,
                      name: Referral_To_Specialist_Inputs.What_Items_Are_Required_To_Be_Taken,
                      required: true,
                      validationSchema: Yup.string().required('Write  items are required to be taken, referral, other'),
                      label: 'What items are required to be taken, referral, other',
                      placeholder: 'Describe what items are required to be taken',
                    },
                  ]
                },
              },
              {
                type: UpdateInputsTypes.RadioInput,
                radios: [
                  {value: RadioBoolean.Yes, label: 'Yes'},
                  {value: RadioBoolean.No, label: 'No'},
                ],
                name: Referral_To_Specialist_Inputs.An_Interpreter_Is_Require,
                value: undefined,
                label: 'An interpreter is required?',
                required: true,
                validationSchema: Yup.string().required('Please select one'),
                dependentInputs: {
                  [RadioBoolean.Yes]: [
                    {
                      type: UpdateInputsTypes.RadioInput,
                      className: styles.padding_1,
                      name: Referral_To_Specialist_Inputs.Interpreter_Has_Been_Booked,
                      value: undefined,
                      required: true,
                      validationSchema: Yup.string().required('Select one'),
                      label: 'Interpreter has been booked',
                      radios: [
                        {value: RadioBoolean.Yes, label: 'Yes'},
                        {value: RadioBoolean.No, label: 'No'},
                      ],
                    },
                  ]
                },
              },
              {
                type: UpdateInputsTypes.TextInput,
                multiline: true,
                value: undefined,
                name: Referral_To_Specialist_Inputs.How_Is_The_Resident,
                label: 'How is the resident?',
                subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
                placeholder: 'Write how is the resident feeling',
                required: true,
                validationSchema: Yup.string().required('Write how is the resident feeling'),
              },
              {
                type: UpdateInputsTypes.TextInput,
                multiline: true,
                name: Referral_To_Specialist_Inputs.Other_Information,
                label: 'Other information',
                placeholder: 'Write other information',
              },
            ]
          }
        ]
    },
    [Specialist_Appointment_SubSections.Referral_To_Allied_Health_Professiona]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `${mainValues.firstName} as an appointment booked with a 
        ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Select_Type_Of_Allied_Health_Professional] !== Referral_To_Allied_Health_Professiona_Inputs.Other
          ? inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Select_Type_Of_Allied_Health_Professional]
          : inputsValues[Referral_To_Allied_Health_Professiona_Inputs.What_The_Other_Allied_Health_Professional_Is]}. 

        Reason for appointment: ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Reason_For_Appointment]}

        ${UpdateMessageSymbols.Paragraph_Break}

        ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.GP_Has_Provided_Referral] === RadioBoolean.Yes
          ? 'The GP has provided a referral letter. '
          : 'The GP will need to provide a referral letter. '}

        ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Referral_Has_Been_Provided_To_Allied_Health_Professional] === RadioBoolean.Yes
          ? `Referral letter has been provided to the 
              ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Select_Type_Of_Allied_Health_Professional] !== Referral_To_Allied_Health_Professiona_Inputs.Other
                ? inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Select_Type_Of_Allied_Health_Professional]
                : inputsValues[Referral_To_Allied_Health_Professiona_Inputs.What_The_Other_Allied_Health_Professional_Is]}. `
          : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          Details of the appointment:

          ${UpdateMessageSymbols.Paragraph_Break}

          Location: ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Location_Of_The_Appointment] === Referral_To_Allied_Health_Professiona_Inputs.At_Nursing_Home
                      ? nursingStore.activeNursingHome?.name
                      : inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Address_Of_Appointment]}. 

          ${UpdateMessageSymbols.Paragraph_Break}

          When: ${moment(inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Time_And_Date_Of_The_Appointment]).format("DD MMM YYYY")} 
          at ${moment(inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Time_And_Date_Of_The_Appointment]).format("hh:mm A")}. 

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Contact_Details_Of_The_Allied_Health_Professional] ? `Contact phone number: ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Contact_Details_Of_The_Allied_Health_Professional]} ${UpdateMessageSymbols.Paragraph_Break}` : ''}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.A_Family_Member_Will_Need_To_Take_The_Resident] === RadioBoolean.Yes
           ? 'A family member will need to take the resident to the appointment. '
           : ''}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Transport_Is_Required] === RadioBoolean.Yes
           ? 'Transport is required. '
           : ''}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Payment_Is_Required] === RadioBoolean.Yes
           ? 'Payment is required. '
           : 'No Payment is required. '}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Items_Need_To_Be_Taken_To_The_Appointment] === RadioBoolean.Yes
           ? `These items will be needed at the appointment: ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.What_Items_Are_Required_To_Be_Taken]}. `
           : ''}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.An_Interpreter_Is_Require] === RadioBoolean.Yes
           ? `An interpreter is required. 
             ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Interpreter_Has_Been_Booked] === RadioBoolean.Yes
               ? 'An Interpreter has been booked. '
               : ''}`
           : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.How_Is_The_Resident]
            ? `How is ${mainValues.firstName}? ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.How_Is_The_Resident]}. ` 
            : ''}
    
          ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Other_Information]
            ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Referral_To_Allied_Health_Professiona_Inputs.Other_Information]}.`
            : ''} 
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.Select,
              name: Referral_To_Allied_Health_Professiona_Inputs.Select_Type_Of_Allied_Health_Professional,
              label: 'Select type of Allied Health Professional',
              placeholder: 'Select',
              items: [
                {title: 'Dietician', value: Referral_To_Allied_Health_Professiona_Inputs.Dietician, id: Referral_To_Allied_Health_Professiona_Inputs.Dietician},
                {title: 'Speech Pathology', value: Referral_To_Allied_Health_Professiona_Inputs.Speech_Pathology, id: Referral_To_Allied_Health_Professiona_Inputs.Speech_Pathology},
                {title: 'Physiotherapist', value: Referral_To_Allied_Health_Professiona_Inputs.Physiotherapist, id: Referral_To_Allied_Health_Professiona_Inputs.Physiotherapist},
                {title: 'Podiatrist ', value: Referral_To_Allied_Health_Professiona_Inputs.Podiatrist, id: Referral_To_Allied_Health_Professiona_Inputs.Podiatrist},
                {title: 'Palliative care nurse', value: Referral_To_Allied_Health_Professiona_Inputs.Palliative_Care_Nurse, id: Referral_To_Allied_Health_Professiona_Inputs.Palliative_Care_Nurse},
                {title: 'Optometrist ', value: Referral_To_Allied_Health_Professiona_Inputs.Optometrist, id: Referral_To_Allied_Health_Professiona_Inputs.Optometrist},
                {title: 'Dementia Support Australia', value: Referral_To_Allied_Health_Professiona_Inputs.Dementia_Support_Australia, id: Referral_To_Allied_Health_Professiona_Inputs.Dementia_Support_Australia},
                {title: 'Other', value: Referral_To_Allied_Health_Professiona_Inputs.Other, id: Referral_To_Allied_Health_Professiona_Inputs.Other},
              ],
              validationSchema: Yup.string().required('Select type of Allied Health Professional'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              name: Referral_To_Allied_Health_Professiona_Inputs.What_The_Other_Allied_Health_Professional_Is,
              label: 'What the other Allied Health Professional?',
              placeholder: 'Write what the other Allied Health Professional',
              conditions: [
                {[Referral_To_Allied_Health_Professiona_Inputs.Select_Type_Of_Allied_Health_Professional]: Referral_To_Allied_Health_Professiona_Inputs.Other}
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              name: Referral_To_Allied_Health_Professiona_Inputs.Reason_For_Appointment,
              label: 'Reason for appointment',
              multiline: true,
              placeholder: 'Write reason for appointment',
              validationSchema: Yup.string().required('Write reason for appointment'),
              required: true,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
                {value: Referral_To_Allied_Health_Professiona_Inputs.No_Referral_Required, label: 'No referral required'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.GP_Has_Provided_Referral,
              value: undefined,
              label: 'GP has provided referral',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: Referral_To_Allied_Health_Professiona_Inputs.At_Nursing_Home, label: 'At nursing home '},
                {value: Referral_To_Allied_Health_Professiona_Inputs.External_Appointment, label: 'External appointment'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.Location_Of_The_Appointment,
              value: undefined,
              label: 'Location of the appointment (please select one)',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [Referral_To_Allied_Health_Professiona_Inputs.External_Appointment]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Referral_To_Allied_Health_Professiona_Inputs.Address_Of_Appointment,
                    required: true,
                    validationSchema: Yup.string().required('Write address of appointment'),
                    label: 'Address of appointment',
                    placeholder: 'Write address of appointment',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.Referral_Has_Been_Provided_To_Allied_Health_Professional,
              value: undefined,
              label: 'Referral has been provided to Allied Health Professional',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.DateInput,
              name: Referral_To_Allied_Health_Professiona_Inputs.Time_And_Date_Of_The_Appointment,
              dateType: 'dateTime',
              label: 'Time and Date of the appointment',
              placeholder: 'Select Time and Date of the appointment',
              validationSchema: Yup.date().required('Time and Date of the appointment is required'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              name: Referral_To_Allied_Health_Professiona_Inputs.Contact_Details_Of_The_Allied_Health_Professional,
              label: 'Contact details of the Allied Health Professional',
              multiline: true,
              placeholder: 'Write contact details of the Allied Health Professional',
              required: false,
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.A_Family_Member_Will_Need_To_Take_The_Resident,
              value: undefined,
              label: 'A family member will need to take the resident',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.Transport_Is_Required,
              value: undefined,
              label: 'Transport is required',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.Payment_Is_Required,
              value: undefined,
              label: 'Payment is required',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.Items_Need_To_Be_Taken_To_The_Appointment,
              value: undefined,
              label: 'Items need to be taken to the appointment?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Referral_To_Allied_Health_Professiona_Inputs.What_Items_Are_Required_To_Be_Taken,
                    required: true,
                    validationSchema: Yup.string().required('Write items are required to be taken, referral, other'),
                    label: 'What items are required to be taken, referral, other',
                    placeholder: 'Describe what items are required to be taken',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Referral_To_Allied_Health_Professiona_Inputs.An_Interpreter_Is_Require,
              value: undefined,
              label: 'An interpreter is required?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    className: styles.padding_1,
                    name: Referral_To_Allied_Health_Professiona_Inputs.Interpreter_Has_Been_Booked,
                    required: true,
                    validationSchema: Yup.string().required('Select one'),
                    value: undefined,
                    label: 'Interpreter has been booked',
                    radios: [
                      {value: RadioBoolean.Yes, label: 'Yes'},
                      {value: RadioBoolean.No, label: 'No'},
                    ],
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Referral_To_Allied_Health_Professiona_Inputs.How_Is_The_Resident,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              required: true,
              validationSchema: Yup.string().required('Write how is the resident feeling'),
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Referral_To_Allied_Health_Professiona_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },

  [Sections.Nutritional]: {
    [Nutritional_SubSections.Nutritional]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `
          ${mainValues.firstName} has nutrition changes: ${inputsValues[Nutritional_Nutritional_Inputs.Update_To_Nutrition]?.join(', ')}. 

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Nutritional_Nutritional_Inputs.Has_The_Resident_Commenced_A_New_Diet] === RadioBoolean.Yes
            ? `${inputsValues[Nutritional_Nutritional_Inputs.Select_New_Diet]?.includes(Nutritional_Nutritional_Inputs.Other_Diet)
                ? `${mainValues.firstName} has a new diet: ${inputsValues[Nutritional_Nutritional_Inputs.Other_Diet_Text]}. `
                : `${mainValues.firstName} has a new diet: ${inputsValues[Nutritional_Nutritional_Inputs.Select_New_Diet]}. `}`
            : ''}

          ${UpdateMessageSymbols.Paragraph_Break}
         
 
          ${inputsValues[Nutritional_Nutritional_Inputs.Reasons_For_Change_In_Appetite_Or_Change_To_Diet] === RadioBoolean.Yes
            ? `Appetite change: ${inputsValues[Nutritional_Nutritional_Inputs.Describe_The_Appetite_Change]}. `
            : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Nutritional_Nutritional_Inputs.Reasons_For_Change_In_Appetite_Or_Change_To_Diet]?.length
            ? inputsValues[Nutritional_Nutritional_Inputs.Reasons_For_Change_In_Appetite_Or_Change_To_Diet]?.includes(Nutritional_Nutritional_Inputs.Other_Reason)
              ? `Reasons for change in appetite or change to diet: ${inputsValues[Nutritional_Nutritional_Inputs.Write_Other_Reason]}. `
              : `Reasons for change in appetite or change to diet: ${inputsValues[Nutritional_Nutritional_Inputs.Reasons_For_Change_In_Appetite_Or_Change_To_Diet]?.join(', ')}.`
            : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Nutritional_Nutritional_Inputs.Has_There_Been_A_Weight_Change] === RadioBoolean.Yes
            ? `Change to weight: ${inputsValues[Nutritional_Nutritional_Inputs.The_Change_In_Weight]}. `
            : ''}

          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Nutritional_Nutritional_Inputs.Update_To_Nutrition]?.includes(Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician)
            ? `
              ${mainValues.firstName} has been referred to ${inputsValues[Nutritional_Nutritional_Inputs.Resident_Has_Been_Referred_To]}.
              ${UpdateMessageSymbols.Paragraph_Break}

              Details of the appointment:

              ${UpdateMessageSymbols.Paragraph_Break}

              Location: ${inputsValues[Nutritional_Nutritional_Inputs.Location_Of_The_Appointment] === Nutritional_Nutritional_Inputs.At_Nursing_Home
                ? nursingStore.activeNursingHome?.name
                : inputsValues[Nutritional_Nutritional_Inputs.Address_Of_Appointment]}. 

              ${UpdateMessageSymbols.Paragraph_Break}

              When: ${moment(inputsValues[Nutritional_Nutritional_Inputs.Time_And_Date_Of_The_Appointment]).format("DD MMM YYYY")} 
              at ${moment(inputsValues[Nutritional_Nutritional_Inputs.Time_And_Date_Of_The_Appointment]).format("hh:mm A")}. 

              ${UpdateMessageSymbols.Paragraph_Break}

              Contact phone number: ${inputsValues[Nutritional_Nutritional_Inputs.Contact_Details_Of_The_Allied_Health_Professional]}

              ${UpdateMessageSymbols.Paragraph_Break}

              ${inputsValues[Nutritional_Nutritional_Inputs.A_Family_Member_Will_Need_To_Take_The_Resident] === RadioBoolean.Yes
                ? 'A family member will need to take the resident to the appointment. '
                : ''}

              ${inputsValues[Nutritional_Nutritional_Inputs.Transport_Is_Required] === RadioBoolean.Yes
                ? 'Transport is required. '
                : ''}

              ${inputsValues[Nutritional_Nutritional_Inputs.Payment_Is_Required] === RadioBoolean.Yes
                ? 'Payment is required. '
                : 'No Payment is required. '}

              ${inputsValues[Nutritional_Nutritional_Inputs.Items_Need_To_Be_Taken_To_The_Appointment] === RadioBoolean.Yes
                ? `These items will be needed at the appointment: ${inputsValues[Nutritional_Nutritional_Inputs.What_Items_Are_Required_To_Be_Taken]}. `
                : ''}

              ${inputsValues[Nutritional_Nutritional_Inputs.An_Interpreter_Is_Require] === RadioBoolean.Yes
                ? `An interpreter is required. 
                  ${inputsValues[Nutritional_Nutritional_Inputs.Interpreter_Has_Been_Booked] === RadioBoolean.Yes
                    ? 'An Interpreter has been booked. '
                    : ''}`
                : ''}
              `
            : ''}


          ${UpdateMessageSymbols.Paragraph_Break}

          ${inputsValues[Nutritional_Nutritional_Inputs.Has_The_Resident_Commenced_A_New_Diet] === Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician
            ? `How is ${mainValues.firstName}? ${inputsValues[Nutritional_Nutritional_Inputs.Resident_Has_Been_Referred_To]}. 
              ` 
            : ''}
      
          ${inputsValues[Nutritional_Nutritional_Inputs.Other_Information]
            ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Nutritional_Nutritional_Inputs.Other_Information]}.`
            : ''} 
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Nutritional_Nutritional_Inputs.Update_To_Nutrition,
              label: 'Update to nutrition',
              checkboxes: [
                {label: 'Diet change', value: Nutritional_Nutritional_Inputs.Diet_Change},
                {label: 'Appetite change', value: Nutritional_Nutritional_Inputs.Appetite_Change},
                {label: 'Referral to - Speech Pathologist / Dietician', value: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician},
                {label: 'Change to assistance required', value: Nutritional_Nutritional_Inputs.Change_To_Assistance_Required},
                {label: 'Fluid restrictions', value: Nutritional_Nutritional_Inputs.Fluid_Restrictions},
              ],
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.Has_The_Resident_Commenced_A_New_Diet,
              value: undefined,
              label: 'Has the resident commenced a new diet?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.Select,
                    name: Nutritional_Nutritional_Inputs.Select_New_Diet,
                    required: true,
                    validationSchema: Yup.string().required('Select one'),
                    label: 'Select new diet',
                    className: styles.padding_1,
                    items: [
                      {title: 'Minced diet', id: Nutritional_Nutritional_Inputs.Minced_Diet, value: Nutritional_Nutritional_Inputs.Minced_Diet},
                      {title: 'Thickened fluids', id: Nutritional_Nutritional_Inputs.Thickened_Fluids, value: Nutritional_Nutritional_Inputs.Thickened_Fluids},
                      {title: 'Puree Diet', id: Nutritional_Nutritional_Inputs.Puree_Diet, value: Nutritional_Nutritional_Inputs.Puree_Diet},
                      {title: 'Soft Diet', id: Nutritional_Nutritional_Inputs.Soft_Diet, value: Nutritional_Nutritional_Inputs.Soft_Diet},
                      {title: 'Cut-up (food needs to be cut smaller)', id: Nutritional_Nutritional_Inputs.Cutup, value: Nutritional_Nutritional_Inputs.Cutup},
                      {title: 'Other', id: Nutritional_Nutritional_Inputs.Other_Diet, value: Nutritional_Nutritional_Inputs.Other_Diet}, 
                    ],
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              className: styles.padding_1,
              name: Nutritional_Nutritional_Inputs.Other_Diet_Text,
              label: 'Other diet',
              placeholder: 'Write other diet',
              conditions: [
                {[Nutritional_Nutritional_Inputs.Select_New_Diet]: Nutritional_Nutritional_Inputs.Other_Diet}
              ]
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.Does_The_Resident_Have_Change_To_Appetite,
              value: undefined,
              label: 'Does the resident have a change to appetite?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Nutritional_Nutritional_Inputs.Describe_The_Appetite_Change,
                    required: true,
                    validationSchema: Yup.string().required('Write the appetite change'),
                    label: 'Appetite change',
                    placeholder: 'Describe the appetite change',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.CheckboxInput,
              name: Nutritional_Nutritional_Inputs.Reasons_For_Change_In_Appetite_Or_Change_To_Diet,
              label: 'Reasons for change in appetite or change to diet',
              checkboxes: [
                {label: 'Cognition mood', value: Nutritional_Nutritional_Inputs.Cognition_Mood},
                {label: 'Disease progression', value: Nutritional_Nutritional_Inputs.Disease_Progression},
                {label: 'Swallowing difficulties', value: Nutritional_Nutritional_Inputs.Swallowing_Difficulties},
                {label: 'Other', value: Nutritional_Nutritional_Inputs.Other_Reason},
              ],
              dependentInputs: {
                [Nutritional_Nutritional_Inputs.Other_Reason]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Nutritional_Nutritional_Inputs.Write_Other_Reason,
                    required: true,
                    validationSchema: Yup.string().required('Write other diet'),
                    label: 'Other diet',
                    placeholder: 'Write other diet',
                  },
                ]
              }
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.Has_There_Been_A_Weight_Change,
              value: undefined,
              label: 'Has there been a weight change?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Nutritional_Nutritional_Inputs.The_Change_In_Weight,
                    required: true,
                    validationSchema: Yup.string().required('Write what is the change in weight'),
                    label: 'What is the change in weight',
                    placeholder: 'Describe what is the change in weight',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: Nutritional_Nutritional_Inputs.Speech_Pathologist, label: 'Speech Pathologist'},
                {value: Nutritional_Nutritional_Inputs.Dietician, label: 'Dietician'},
                {value: Nutritional_Nutritional_Inputs.Other, label: 'Other'},
              ],
              name: Nutritional_Nutritional_Inputs.Resident_Has_Been_Referred_To,
              value: undefined,
              label: 'Resident has been referred to',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: Nutritional_Nutritional_Inputs.At_Nursing_Home, label: 'At nursing home '},
                {value: Nutritional_Nutritional_Inputs.External_Appointment, label: 'External appointment'},
              ],
              name: Nutritional_Nutritional_Inputs.Location_Of_The_Appointment,
              value: undefined,
              label: 'Location of the appointment (please select one)',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [Nutritional_Nutritional_Inputs.External_Appointment]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Nutritional_Nutritional_Inputs.Address_Of_Appointment,
                    required: true,
                    validationSchema: Yup.string().required('Write address of appointment'),
                    label: 'Address of appointment',
                    placeholder: 'Write address of appointment',
                  },
                ]
              },
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.DateInput,
              name: Nutritional_Nutritional_Inputs.Time_And_Date_Of_The_Appointment,
              dateType: 'dateTime',
              label: 'Time and Date of the appointment',
              placeholder: 'Select Time and Date of the appointment',
              validationSchema: Yup.date().required('Time and Date of the appointment is required'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.TextInput,
              name: Nutritional_Nutritional_Inputs.Contact_Details_Of_The_Allied_Health_Professional,
              label: 'Contact details of the Allied Health Professional',
              multiline: true,
              placeholder: 'Write contact details of the Allied Health Professional',
              validationSchema: Yup.string().required('Write contact details of the Allied Health Professional'),
              required: true,
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.A_Family_Member_Will_Need_To_Take_The_Resident,
              value: undefined,
              label: 'A family member will need to take the resident',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.Transport_Is_Required,
              value: undefined,
              label: 'Transport is required',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.Payment_Is_Required,
              value: undefined,
              label: 'Payment is required',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ]
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.Items_Need_To_Be_Taken_To_The_Appointment,
              value: undefined,
              label: 'Items need to be taken to the appointment?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ],
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Nutritional_Nutritional_Inputs.What_Items_Are_Required_To_Be_Taken,
                    required: true,
                    validationSchema: Yup.string().required('Write items are required to be taken, referral, other'),
                    label: 'What items are required to be taken, referral, other',
                    placeholder: 'Describe what items are required to be taken',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Nutritional_Nutritional_Inputs.An_Interpreter_Is_Require,
              value: undefined,
              label: 'An interpreter is required?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              conditions: [
                {[Nutritional_Nutritional_Inputs.Update_To_Nutrition]: Nutritional_Nutritional_Inputs.Referral_To_Speech_Pathologist_Dietician}
              ],
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.RadioInput,
                    className: styles.padding_1,
                    name: Nutritional_Nutritional_Inputs.Interpreter_Has_Been_Booked,
                    required: true,
                    validationSchema: Yup.string().required('Select one'),
                    value: undefined,
                    label: 'Interpreter has been booked',
                    radios: [
                      {value: RadioBoolean.Yes, label: 'Yes'},
                      {value: RadioBoolean.No, label: 'No'},
                    ],
                  },
                ]
              },
            },
/*             {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Nutritional_Nutritional_Inputs.How_Is_The_Resident,
              label: 'How is the resident?',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              placeholder: 'Write how is the resident feeling',
              required: true,
              validationSchema: Yup.string().required('Write how is the resident feeling'),
            }, */
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              name: Nutritional_Nutritional_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },

  [Sections.Resident_Of_The_Day]: {
    [Resident_Of_The_Day_SubSections.Resident_Of_The_Day]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
        `
          ${mainValues.firstName} has been reviewed by the care team today.
          We have completed a series of checks on ${mainValues.firstName} and their room.

          
          ${UpdateMessageSymbols.Paragraph_Break}
          ${inputsValues[Resident_Of_The_Day_Inputs.Checklist] === Resident_Of_The_Day_Inputs.All_Observations_Within_Normal_Range
            ? 'All observations within normal range. No issues to report. '
            : `All observations within normal range other than the following: ${inputsValues[Resident_Of_The_Day_Inputs.Outline_Key_Findings]}.`}

          ${UpdateMessageSymbols.Paragraph_Break}
          ${inputsValues[Resident_Of_The_Day_Inputs.Has_There_Been_Change_To_Weigh] === RadioBoolean.Yes
            ? `Weight change: ${inputsValues[Resident_Of_The_Day_Inputs.Write_The_Change_To_Weight]}. `
            : ''}
 
          ${UpdateMessageSymbols.Paragraph_Break}
          How is ${mainValues.firstName}? ${inputsValues[Resident_Of_The_Day_Inputs.How_Is_The_Resident]}.

          ${inputsValues[Resident_Of_The_Day_Inputs.Other_Information]
           ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Resident_Of_The_Day_Inputs.Other_Information]}.`
           : ''} 
        `),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: Resident_Of_The_Day_Inputs.All_Observations_Within_Normal_Range, label: 'All observations within normal range. No issues to report.'},
                {value: Resident_Of_The_Day_Inputs.The_Following_Issues_Were_Found, label: 'The following issues were found.'},
              ],
              name: Resident_Of_The_Day_Inputs.Checklist,
              value: undefined,
              label: 'Please select one',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [Resident_Of_The_Day_Inputs.The_Following_Issues_Were_Found]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Resident_Of_The_Day_Inputs.Outline_Key_Findings,
                    required: true,
                    validationSchema: Yup.string().required('Write what is outline key findings'),
                    label: 'Outline key findings',
                    placeholder: 'Describe what is outline key findings',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.RadioInput,
              radios: [
                {value: RadioBoolean.Yes, label: 'Yes'},
                {value: RadioBoolean.No, label: 'No'},
              ],
              name: Resident_Of_The_Day_Inputs.Has_There_Been_Change_To_Weigh,
              value: undefined,
              label: 'Has there been a change to weight?',
              required: true,
              validationSchema: Yup.string().required('Please select one'),
              dependentInputs: {
                [RadioBoolean.Yes]: [
                  {
                    type: UpdateInputsTypes.TextInput,
                    multiline: true,
                    className: styles.padding_1,
                    name: Resident_Of_The_Day_Inputs.Write_The_Change_To_Weight,
                    required: true,
                    validationSchema: Yup.string().required('Write the change to weight'),
                    label: 'Change to weight',
                    placeholder: 'Write the change to weight',
                  },
                ]
              },
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Resident_Of_The_Day_Inputs.How_Is_The_Resident,
              label: 'How is the resident',
              placeholder: 'Write how is the resident',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              validationSchema: Yup.string().required('Write how is the resident'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Resident_Of_The_Day_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
          ]
        }
      ]
    },
  },
  [Sections.Other]: {
    [Other_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
          `${mainValues.firstName} has the following update:
          ${inputsValues[Other_Other_Inputs.Describe_The_Update]}. 
          ${UpdateMessageSymbols.Paragraph_Break}
          How is ${mainValues.firstName}? ${inputsValues[Other_Other_Inputs.How_Is_The_Resident]}. 

          ${inputsValues[Other_Other_Inputs.Other_Information]
            ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Other_Other_Inputs.Other_Information]}.`
            : ''} 
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
          `
        ),
        messageMultiple: (mainValues: IMainUpdateForm, inputsValues: Array<{[key in InputsName]: any}>) => formatSummaryMessage(
          `${residentsStore.getMultipleUpdateSelectedResidents()?.map((selectedResident) => selectedResident.firstName).join(', ')} has the following update:
          ${inputsValues[Other_Other_Inputs.Describe_The_Update]}. 
          ${UpdateMessageSymbols.Paragraph_Break}
          How is ${residentsStore.getMultipleUpdateSelectedResidents()?.map((selectedResident) => selectedResident.firstName).join(', ')}? ${inputsValues[Other_Other_Inputs.How_Is_The_Resident]}. 

          ${inputsValues[Other_Other_Inputs.Other_Information]
            ? `${UpdateMessageSymbols.Paragraph_Break} Other Information: ${inputsValues[Other_Other_Inputs.Other_Information]}.`
            : ''} 
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? UpdateMessageSymbols.Paragraph_Break
            : ''}
          ${mainValues.phoneCall && mainValues.phoneCall !== PhoneCall.NOT_REQUIRED
            ? `${`${PhoneCallNames[mainValues.phoneCall]}.`}.`
            : ''}
          `
          ),
      steps: [
        {
          inputs: [
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Other_Other_Inputs.Describe_The_Update,
              label: 'Describe the update',
              placeholder: 'Write the update',
              validationSchema: Yup.string().required('Write the update'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Other_Other_Inputs.How_Is_The_Resident,
              label: 'How is the resident',
              placeholder: 'Write how is the resident',
              subLabel: 'What have they done today (e.g. rested, participated in activities)?\nHave they left their room or not? Have they been eating / drinking?\nIn pain or not? Current emotions and mood?',
              validationSchema: Yup.string().required('Write how is the resident'),
              required: true,
            },
            {
              type: UpdateInputsTypes.TextInput,
              multiline: true,
              value: undefined,
              name: Other_Other_Inputs.Other_Information,
              label: 'Other information',
              placeholder: 'Write other information',
            },
            {
              type: UpdateInputsTypes.File,
              value: undefined,
              name: Other_Other_Inputs.Files,
              label: 'files',
            },
          ]
        }
      ]
    },
  },
}

export function getUpdateSchema(section?: Sections, subSection?: SubSections, initialSchema?: IInitialSchema) {
  const cloneSchema = deepClone(UpdateSchema) as IUpdateSchema;

  if(section && subSection && initialSchema?.steps) {
    cloneSchema[section][subSection].steps = initialSchema.steps;
  } 

  return cloneSchema
}

