import { AgencyType, EmployeeType } from "./authModels"

export interface IGetUserResponse {
  accountType: string,
  email: string,
  phoneNumber: string,
  firstName: string,
  isDisabled: boolean,
  isConfirmed: boolean,
  loginDate: string,
  lastName: string,
  photo: string,
  _id: string,
  account: {
    stuffId: string,
    role: string,
    position: string,
    agencyInfo?: {
      registrationNumber: string,
      rootId: string,
      type: AgencyType,
    },
    employeeType: EmployeeType,
    nursingHomes: {
      _id: string,
    }[],
    organization: {
      _id: string,
      name: string,
      description: string,
      owner: {
        email: string,
        firstName: string,
        lastName: string,
        phone: string,
      }
    },
    permissions: {
      global: string[] | null,
      homes: {
        home: {
          address: string,
          email: string,
          name: string,
          organization: {
            _id: string,
          },
          phone: string,
          photo: string,
          _id: string,
        },
        permissions: string[],
      }[],
      organization: {
        organization: {
          description: string,
          name: string,
          owner: {
            firstName: string,
            lastName: string,
            email: string,
            phone: string,
          },
          email: string,
          firstName: string,
          lastName: string,
          phone: string,
          _id: string,
        },
        permissions: string[],
      }
    }
    _id: string,
  }
}

export interface IUpdateProfileInfoReq {
  firstName: string,
  lastName: string,
  phoneNumber: string,
}

export enum AuthActions {
  LOGIN_AS_AGENCY = 'login_as_agency',
  REGISTER_NEW_AGENCY = 'register_new_agency',
  NEED_ENABLE_MFA = 'need_enable_mfa',
  MFA_AUTH = 'mfa_auth',
}

export interface MFALoginStore {
  email: string,
  password: string,
}

export interface IUser  {
  accountType: string,
  email: string,
  phoneNumber: string,
  firstName: string,
  isDisabled: boolean,
  isConfirmed: boolean,
  loginDate: string,
  lastName: string,
  photo: string,
  _id: string,
  account: {  
    stuffId: string,
    role: string,
    position: string,
    agencyInfo?: {
      registrationNumber: string,
      rootId: string,
      type: AgencyType,
    },
    employeeType: EmployeeType,
    nursingHomes: {
      _id: string,
    }[],
    organization: {
      _id: string,
      name: string,
      description: string,
      owner: {
        email: string,
        firstName: string,
        lastName: string,
        phone: string,
      }
    },
    permissions: {
      global: string[] | null,
      homes: {
        home: {
          address: string,
          email: string,
          name: string,
          organization: {
            _id: string,
          },
          phone: string,
          photo: string,
          _id: string,
        },
        permissions: string[],
      }[],
      organization: {
        organization: {
          description: string,
          name: string,
          owner: {
            firstName: string,
            lastName: string,
            email: string,
            phone: string,
          },
          email: string,
          firstName: string,
          lastName: string,
          phone: string,
          _id: string,
        },
        permissions: string[],
      }
    }
    _id: string,
  }
}

export interface ISupportReq {
  content: string,
  fromEmail: string,
  type: IssueType,
  additionalInfo?: {
    name: string,
    phoneNumber: string,
    home: string,
  }
}

export interface ISupportRes {
  content: string,
  createdDate: string,
  fromEmail: string,
  subject: string,
  type: string,
  userExists: boolean,
  _id: string,
}

export enum IssueType {
  EMPLOYEE_FEEDBACK = 'employee:feedback',
  EMPLOYEE_SUPPORT = 'employee:support',
  MEMBER_SUPPORT = 'member:support',
  EMPLOYEE_LOGIN_SUPPORT = 'employee:login_support',
}
