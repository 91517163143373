export interface IUploadFileResidentsReq {
  nursingHomeId: string,
  body: FormData,
}

export interface IUploadFileResidentsRes {
  familyCount: 0,
  residentCount: 0
}

export interface IUploadFileStaffReq {
  nursingHomeId: string,
  body: FormData,
}

export interface IUploadFileStaffRes {
  employeeCount: 0
}

export enum UploadType {
  STAFF = "STAFF",
  RESIDENTS = "RESIDENTS",
  RESIDENTS_RESEND = "RESIDENTS_RESEND"
}

export enum OnboardingResponseActions {
  TRIAL_PERIOD = 'trial_period'
}

export enum OnboardingTrialModeModalActions  {
  CREATE_UPDATE = "create update",
  SAVE_AS_DRAFT = "save as draft",
  CREATE_ANNOUNCEMENT = "create announcement",
  EDIT_ANNOUNCEMENT = "edit announcement",
  DELETE_ANNOUNCEMENT = "delete announcement",
  CREATE_EMPLOYEE = "create employee",
  EDIT_EMPLOYEE = "edit employee",
  DEACTIVATE_EMPLOYEE = "deactivate employee",
  ACTIVATE_EMPLOYEE = "activate employee",
  CREATE_RESIDENT = "create resident",
  EDIT_RESIDENT = "edit resident",
  DEACTIVATE_RESIDENT = "deactivate resident",
  CREATE_FAMILY_MEMBER = "create family member",
  EDIT_FAMILY_MEMBER = "edit family member",
  DELETE_FAMILY_MEMBER = "delete family member",
  DEACTIVATE_FAMILY_MEMBER = "deactivate family member",
  ACTIVATE_FAMILY_MEMBER = "activate family member",
  CREATE_NOTE = "create note",
  DELETE_NOTE = "delete note",
  EDIT_NURSING_HOME = "edit nursing home",
  CREATE_AGENCY_ACCOUNT = "create agency account",
  RESEND_INVITATION_AGENCY_ACCOUNT = "resend invitation agency account",
  DEACTIVATE_AGENCY_ACCOUNT = "deactivate agency account",
  ACTIVATE_AGENCY_ACCOUNT = "activate agency account",
  CHANGE_PASSWORD_AGENCY_ACCOUNT = "change password agency account",
  FORGOT_PASSWORD_AGENCY_ACCOUNT = "forgot password agency account",
  HELP_TECH_SUPPORT = "send urgent technical support",
  HELP_FEEDBACK = "send feedback",
}

export enum OnboardingTrialModeModalType {
  CAN_DO = "can_do",
  CAN_NOT_DO = "can_not_do",
}