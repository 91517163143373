import { useHistory } from "react-router-dom";
import styles from "./blockNewUpdatesModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import { ContactTypeEnum } from "@source/api/models/allUsersModels";
import residentsStore from "@store/residentsStore";

const BlockNewUpdatesModal = observer((props: BlockNewUpdatesModalProps) => {
  const {} = props;
  const router = useHistory();


  const getActiveResidentPrimaryContact = () => {
    return residentsStore.getModalBlockNewUpdatesResident()?.familyRelations.find((familyRelation) => familyRelation.contactType === ContactTypeEnum.primary);
  }

  const isChoseNotUseApp = () => {
    return (!!getActiveResidentPrimaryContact()?.familyMember.loginDate) && getActiveResidentPrimaryContact()?.familyMember.account.isTemporaryPassword;
  }

  const notActivePrimaryComponent = isChoseNotUseApp() ? (
      <div className={styles.prmiraryContactNotActivated}>
                <span className={styles.row}>
                    <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName} {getActiveResidentPrimaryContact()?.familyMember.lastName}</span>, the primary contact, has chosen not to use Brenna app.
                </span>
                <span className={styles.row}>
                Updates for <span className={styles.row_bold}>{residentsStore.getModalBlockNewUpdatesResident()?.firstName} {residentsStore.getModalBlockNewUpdatesResident()?.lastName}</span> cannot be sent via Brenna.
                </span>
                <span className={styles.row}>
                Please call <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName}</span> on <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.phoneNumber}</span> with any updates on <span className={styles.row_bold}>{residentsStore.getModalBlockNewUpdatesResident()?.firstName}'s</span> condition.
                </span>
      </div>
  ) : (
      <div className={styles.prmiraryContactNotActivated}>
              <span className={styles.row}>
                Unfortunately you can’t create updates until the primary contact is activated.
              </span>
        <span className={styles.row}>
                Please, contact <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName} {getActiveResidentPrimaryContact()?.familyMember.lastName}</span> using the following number <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.phoneNumber}</span> to remind them about activation.
              </span>
      </div>
  )

  return(
      <Modal open={!!residentsStore.getModalBlockNewUpdatesResident()} className={styles.modalComment} close={() => residentsStore.setModalBlockNewUpdatesResident(null)}>
        <div className={styles.modalCantCreateUpdate}>
          {
            getActiveResidentPrimaryContact() ?
                notActivePrimaryComponent
                :
                <div className={styles.noPrimaryContact}>
            <span className={styles.row}>
              Resident must have the primary contact to create new updates.
            </span>
                </div>
          }

          <Button
              className={styles.confirmBtn}
              onClick={() => residentsStore.setModalBlockNewUpdatesResident(null)}
          >
            Confirm
          </Button>
        </div>
      </Modal>
  )
})

export default BlockNewUpdatesModal;

interface BlockNewUpdatesModalProps {

}
