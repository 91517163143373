import { IRadio } from "@source/components/UI/buttons/radio/radio";
import { ISelectItem } from "@source/components/UI/inputs/selectInput/selectInput";
import { IMainUpdateForm } from "@source/pages/HomePage/homeRouter/updateTab/updateTab";

export enum Sections {
  Fall='Fall',
  Infection='Infection',
  GP_Review_Medication_Change='GP Review / Medication Change',
  Incident='Incident',
  Change_To_Condition='Change to Condition',
  Medication_Incidents='Medication Incidents',
  Pain='Pain',
  Physiotherapy_Review='Physiotherapy Review',
  Request_For_Items='Request for Items',
  Personal_Care='Personal Care',
  Nutritional='Nutritional',
  Specialist_Appointment='Specialist / Allied Health Professional Appointment',
  Other='Other',
  Resident_Of_The_Day='Resident of the Day',
  Return_From_Hospital='Return from Hospital'
}

export enum Fall_SubSections {
  Fall='Fall',
}

export enum Infection_SubSections {
  Urinary_Infection='Urinary',
  Respiratory='Respiratory',
  Wound='Wound',
  Skin_Infection='Skin',
  Cellulitis='Cellulitis',
  Ear_Nose_Throat_ENT='Ear, Nose, Throat ENT',
  Covid_First_Update_To_Family='Covid-19 Infection - First Update',
  Covid_Ongoing_Update='Covid-19 Infection - Ongoing Update',
  Eye='Eye',
  Other='Other',
}

export enum GP_Review_Medication_Change_SubSections {
  General_Review='General review',
  Deterioration_Physical_Or_Cognitive='Deterioration physical or cognitive',
  Medication_Review='Medication review',
  Review_Post_Fall_Or_Incident='Review post fall or incident',
  Other='Other',
}

export enum Incident_SubSections {
  Bruising='Bruising',
  Skin_Tear='Skin Tear',
  Pressure_Injury='Pressure Injury',
  Ulcer='Ulcer',
  Absconding='Absconding',
  Behaviour='Behaviour (Agressive/Violent)',
  Choking='Choking',
  Other='Other'
}

export enum Change_To_Condition_SubSections {
  Change_To_Cognition = 'Change to Cognition',
  Change_To_Mobility = 'Change to Mobility',
  Change_To_Appetite='Change to Appetite',
  Change_To_Behaviour_Mood ='Change to Behaviour / Mood',
  Weight_Change_Update='Weight Change Update',
  Other_General_Update='Other / General Update '
}

export enum Medication_Incidents_SubSections {
  Medication_Incidents='Medication Incidents',
}

export enum Pain_SubSections {
  Pain='Pain',
}

export enum Physiotherapy_Review_SubSections {
  General_Update_From_Physio='General Update from Physio',
  New_Aid_Walker='New Aid / Walker',
  New_Exercises='New Exercises',
  Mobility_ReAssessed='Mobility re-assessed'
}

export enum Request_For_Items_SubSections {
  Clothing_Footwear='Clothing/Footwear',
  Food_Items='Food Items',
  Personal_Care_Items='Personal care items',
  Other='Other'
}

export enum Personal_Care_SubSections {
  Change_In_Habits='Change in continence/bowel habits',
  Wearing_Of_Pads='Wearing of Pads',
  Change_In_Personal_Hygiene_Needs='Change in personal hygiene needs  (e.g. needs help with dressing etc)'
}

export enum Nutritional_SubSections {
  Nutritional='Nutritional'
}

export enum Specialist_Appointment_SubSections {
  Referral_To_Allied_Health_Professiona='Allied Health Professional Appointment',
  Referral_To_Specialist='Specialist Appointment'
}

export enum Other_SubSections {
  Other='other'
}

export enum Return_From_Hospital_SubSections {
  Return_From_Hospital='Return from hospital'
}

export enum Resident_Of_The_Day_SubSections {
  Resident_Of_The_Day='Resident Of The Day'
}


export type SubSections = Fall_SubSections | Infection_SubSections | GP_Review_Medication_Change_SubSections 
                          | Incident_SubSections | Change_To_Condition_SubSections | Medication_Incidents_SubSections 
                          | Pain_SubSections | Physiotherapy_Review_SubSections | Request_For_Items_SubSections
                          | Personal_Care_SubSections | Nutritional_SubSections | Specialist_Appointment_SubSections
                          | Other_SubSections | Resident_Of_The_Day_SubSections | Return_From_Hospital_SubSections


export enum UpdateInputsTypes {
  TextInput='text',
  Checkbox='checkbox',
  HumanInput='humanInput',
  CheckboxInput='checkboxInput',
  DateInput='date',
  Radio='radio',
  RadioInput='radioInput',
  Select='selectInput',
  File='fileInput',
}

export enum SectionsId {
  Fall='fall',
  Infection='infection',
  GP_Review_Medication_Change='gp_review',
  Incident = 'incident',
  Change_To_Condition = 'change_to_condition',
  Other = 'other',
  Resident_Of_The_Day = 'resident_of_the_day',
  Specialist_Appointment = 'specialist_appointment',
  Nutritional = 'nutritional',
  Personal_Care = 'personal_care',
  Request_For_Items = 'request_for_items',
  Physiotherapy_Review = 'physiotherapy_review',
  Pain = 'pain',
  Medication_Incidents = 'medication_incidents',
  Return_From_Hospital='return_from_hospital'
}


export enum Fall_SubSectionsId {
  Fall='',
}

export enum Infection_SubSectionsId {
  Urinary_Infection='urinary_infection',
  Respiratory='respiratory',
  Wound='wound',
  Skin_Infection='skin_infection',
  Cellulitis='cellulitis',
  Ear_Nose_Throat_ENT='ent',
  Eye='eye',
  Covid_First_Update_To_Family='covid_first_update',
  Covid_Ongoing_Update='covid_ongoing_update',
  Other='unknown',
}

export enum GP_Review_Medication_Change_SubSectionsId {
  General_Review='general_review',
  Deterioration_Physical_Or_Cognitive='deterioration',
  Medication_Review='medication_review',
  Review_Post_Fall_Or_Incident='review_post_fall_or_incident',
  Other='other',
}

export enum Incident_SubSectionsId {
  Bruising='bruising',
  Skin_Tear='skin_tear',
  Pressure_Injury='pressure_injury',
  Ulcer='ulcer',
  Absconding='absconding',
  Behaviour='behavior',
  Choking='choking',
  Other='other'
}

export enum Change_To_Condition_SubSectionsId {
  Change_To_Cognition = 'change_to_condition',
  Change_To_Mobility = 'change_to_mobility',
  Change_To_Appetite='change_to_appetite',
  Change_To_Behaviour_Mood ='change_to_behavior',
  Weight_Change_Update='weight_change',
  Other_General_Update='other'
}

export enum Medication_Incidents_SubSectionsId {
  Medication_Incidents='',
}

export enum Pain_SubSectionsId {
  Pain='',
}

export enum Physiotherapy_Review_SubSectionsId {
  General_Update_From_Physio='general_update_from_physio',
  New_Aid_Walker='new_aid',
  New_Exercises='new_exercises',
  Mobility_ReAssessed='mobility_re-assessed'
}

export enum Request_For_Items_SubSectionsId {
  Clothing_Footwear='clothing_footwear',
  Food_Items='food_items',
  Personal_Care_Items='personal_care_items',
  Other='other'
}

export enum Personal_Care_SubSectionsId {
  Change_In_Habits='change_in_continence',
  Wearing_Of_Pads='wearing_of_pads',
  Change_In_Personal_Hygiene_Needs='change_in_personal_hygiene_needs'
}

export enum Nutritional_SubSectionsId {
  Nutritional=''
}

export enum Specialist_Appointment_SubSectionsId {
  Referral_To_Allied_Health_Professiona='referral_to_allied_health_professional',
  Referral_To_Specialist='referral_to_specialist'
}

export enum Other_SubSectionsId {
  Other=''
}

export enum Return_From_Hospital_SubSectionsId {
  Return_From_Hospital=''
}

export enum Resident_Of_The_Day_SubSectionsId {
  Resident_Of_The_Day = '',
}

export type SubSectionsId = Fall_SubSectionsId | Infection_SubSectionsId | GP_Review_Medication_Change_SubSectionsId | Incident_SubSectionsId 
                            | Change_To_Condition_SubSectionsId | Medication_Incidents_SubSectionsId | Pain_SubSectionsId | Physiotherapy_Review_SubSectionsId
                            | Request_For_Items_SubSectionsId | Personal_Care_SubSectionsId | Nutritional_SubSectionsId | Specialist_Appointment_SubSectionsId
                            | Other_SubSectionsId | Resident_Of_The_Day_SubSectionsId | Return_From_Hospital_SubSectionsId


export enum UpdatePriorities {
  Low='Low',
  Medium='Medium',
  High='High'
}

export enum UpdatePrioritiesId {
  Minor='minor',
  Major='major',
  Critical='critical'
}


export interface IUpdateSchema {
  [Sections.Fall]: {
    [Fall_SubSections.Fall]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    }
  },
  [Sections.Infection]: {
    [Infection_SubSections.Urinary_Infection]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Infection_SubSections.Respiratory]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Infection_SubSections.Wound]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Infection_SubSections.Skin_Infection]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Infection_SubSections.Cellulitis]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Infection_SubSections.Ear_Nose_Throat_ENT]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Infection_SubSections.Eye]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>  
    },
    [Infection_SubSections.Covid_First_Update_To_Family]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>  
    },
    [Infection_SubSections.Covid_Ongoing_Update]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>  
    },
    [Infection_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep> 
    }
  },
  [Sections.GP_Review_Medication_Change]: {
    [GP_Review_Medication_Change_SubSections.General_Review]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [GP_Review_Medication_Change_SubSections.Deterioration_Physical_Or_Cognitive]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [GP_Review_Medication_Change_SubSections.Medication_Review]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [GP_Review_Medication_Change_SubSections.Review_Post_Fall_Or_Incident]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [GP_Review_Medication_Change_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep> 
    }
  },
  [Sections.Incident]: {
    [Incident_SubSections.Bruising]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Skin_Tear]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Pressure_Injury]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Ulcer]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Absconding]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Behaviour]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Choking]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Incident_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Change_To_Condition]: {
    [Change_To_Condition_SubSections.Change_To_Cognition]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Change_To_Condition_SubSections.Change_To_Mobility]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Change_To_Condition_SubSections.Change_To_Appetite]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Change_To_Condition_SubSections.Change_To_Behaviour_Mood]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Change_To_Condition_SubSections.Weight_Change_Update]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Change_To_Condition_SubSections.Other_General_Update]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Medication_Incidents]: {
    [Medication_Incidents_SubSections.Medication_Incidents]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    }
  },
  [Sections.Pain]: {
    [Pain_SubSections.Pain]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    }
  },
/*   [Sections.Physiotherapy_Review]: {
    [Physiotherapy_Review_SubSections.General_Update_From_Physio]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Physiotherapy_Review_SubSections.New_Aid_Walker]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Physiotherapy_Review_SubSections.New_Exercises]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Physiotherapy_Review_SubSections.Mobility_ReAssessed]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  }, */
  [Sections.Request_For_Items]: {
    [Request_For_Items_SubSections.Clothing_Footwear]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Request_For_Items_SubSections.Food_Items]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Request_For_Items_SubSections.Personal_Care_Items]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Request_For_Items_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Specialist_Appointment]: {
    [Specialist_Appointment_SubSections.Referral_To_Allied_Health_Professiona]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
    [Specialist_Appointment_SubSections.Referral_To_Specialist]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Return_From_Hospital]: {
    [Return_From_Hospital_SubSections.Return_From_Hospital]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Nutritional]: {
    [Nutritional_SubSections.Nutritional]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Resident_Of_The_Day]: {
    [Resident_Of_The_Day_SubSections.Resident_Of_The_Day]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
  [Sections.Other]: {
    [Other_SubSections.Other]: {
      message: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      messageMultiple: (mainValues: IMainUpdateForm, inputsValues: any) => string | JSX.Element,
      conjugateInputsBoxClasses?: {[key: string | number]: Array<string>}
      steps: Array<IUpdateStep>
    },
  },
}


export type IUpdateValues = {[key in InputsName]?: any}

export interface IUpdateStep {
  inputs: UpdateInputs
}

export interface IUpdateCheckboxInput {
  type: UpdateInputsTypes.CheckboxInput,
  name: string,
  label: string,
  value?: Array<string | number>,
  checkboxes: Array<{
    value: string,
    label: string,
  }>,
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
  dependentInputs?: {
    [key: string]: UpdateInputs
  }
}

export interface IUpdateTextInput {
  type: UpdateInputsTypes.TextInput,
  name: string,
  label: string,
  value?: string,
  placeholder?: string,
  validationSchema?: any,
  multiline?: boolean,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
  required?: boolean,
  subLabel?: string,
}

export interface ISelectInput {
  type: UpdateInputsTypes.Select,
  name: string,
  label: string,
  value?: string,
  placeholder?: string,
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
  required?: boolean,
  items: Array<ISelectItem>,
  disabled?: boolean
}

export interface IUpdateHumanInput {
  type: UpdateInputsTypes.HumanInput,
  name: string,
  headerType?: string,
  value?: {front: Array<string>, back: Array<string>},
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
}

export interface IUpdateCheckbox {
  type: UpdateInputsTypes.Checkbox,
  name: string,
  checked?: boolean,
  label: string,
  value: string,
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
}

export interface IUpdateDateInput {
  type: UpdateInputsTypes.DateInput,
  name: string,
  label: string,
  value?: null | Date,
  placeholder?: string,
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
  required?: boolean,
  dateType?: 'date' | 'dateTime' | 'dateRange'
}

export interface IUpdateRadio {
  type: UpdateInputsTypes.Radio,
  name: string,
  checked?: boolean,
  label: string,
  value: string,
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
}

export interface IUpdateRadioInput {
  type: UpdateInputsTypes.RadioInput,
  name: string,
  label: string,
  value?: string | number,
  radios: Array<IRadio>,
  validationSchema?: any,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
  required?: boolean,
  dependentInputs?: {
    [key: string]: UpdateInputs
  }
}

export interface IUpdateFileInput {
  type: UpdateInputsTypes.File,
  name: string,
  label: string,
  value?: File[],
  placeholder?: string,
  validationSchema?: any,
  multiline?: boolean,
  conditions?: Array<IUpdateConditions>,
  conjugateInputBoxId?: string | number,
  className?: string,
  required?: boolean,
  subLabel?: string,
}

export type UpdateInputs = Array<UpdateInput>
export type UpdateInput = IUpdateCheckboxInput | IUpdateTextInput | IUpdateHumanInput | IUpdateCheckbox 
                          | IUpdateDateInput | IUpdateRadio | IUpdateRadioInput | ISelectInput | IUpdateFileInput

interface IUpdateConditions {
  [key: string]: any
}
export interface IInitialSchema {
  steps: Array<IUpdateStep>
}

export enum UpdateStatus {
  Complete='complete',
  Incomplete='incomplete',
  New='new'
}

export enum RadioBoolean {
  Yes='yes',
  No='no',
}

export enum Fall_Fall_Inputs {
  Describe_How_The_Resident_Fell='describeHowTheResidentFell',
  Did_Anyone_See_The_Fall='didAnyoneSeeTheFall',
  Did_Resident_Hit_Their_Head='didResidentHitTheirHead',
  Was_The_Resident_Injured='wasTheResidentInjured',
  Injured_Type='injuredType',
  Resident_Takes_Blood_Thinners='Resident takes Blood Thinners',
  Bruise='Bruise',
  Skin_Tear='Skin tear',
  Other='Other',
  Further_Investigation_Required='Further investigation required',
  Further_Investigation='furtherInvestigation',
  Transfer_To_Hospital='Transfer to hospital',
  Resident_Family_Says_No_To_Transfer_To_Hospital='Resident / family says no to transfer to hospital',
  GP_Notified='GP Notified',
  Physio_Notified='Physio notified',
  Further_Investigation_Comments='furtherInvestigationComments',
  Neuro_Observations_Have_Been_Completed='Neuro observations have been completed',
  Neuro_Observations_Have_Been_Completed_Yes='Neuro_Observations_Have_Been_Completed_Yes',
  Neuro_Observations_Have_Been_Completed_No='Neuro_Observations_Have_Been_Completed_No',
  Family_Member_Was_Called_To_Discuss_What_Happened='A family member was called to discuss what happened',
  Family_Member_Was_Called_To_Discuss_What_Happened_primary_secondary='Family_Member_Was_Called_To_Discuss_What_Happened_primary_secondary',
  Family_Member_Was_Called_To_Discuss_What_Happened_Couldnt_reach_them='Family_Member_Was_Called_To_Discuss_What_Happened_Couldnt_reach_them',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',

  Did_Resident_Hit_Their_Head_Yes='Did_Resident_Hit_Their_Head_Yes',
  Did_Resident_Hit_Their_Head_No='Did_Resident_Hit_Their_Head_No',
  Did_Resident_Hit_Their_Head_Not_Remember='Did_Resident_Hit_Their_Head_Not_Remember',

  Bruise_Place_Bruise='Bruice: Bruise Place',
  Actions_Taken_Bruise='Bruice: actionsTaken',
  RN_Assessment_Of_Pain_Bruise='Bruice: RN Assessment of Pain',
  GP_Informed_Bruise='Bruice: GP Informed',
  GP_Prescribed_Pain_Medication_Bruise='Bruice: GP Prescribed pain medication',
  Clinical_Nurse_Specialist_Review_Of_Bruising_Bruise='Bruice: Clinical nurse specialist review of bruising',
  Cream_Applied_Bruise='Bruice: Cream applied',
  Staff_Monitoring_Their_Condition_Bruise='Bruice: Staff monitoring their condition',
  Nutritional_Supplements_Have_Been_Commenced_Bruise='Bruice: Nutritional supplements have been commenced',

  Skin_Tear_Place_Skin_Tear='Skin_Tear: Skin tear place',
  Actions_Taken_Skin_Tear='Skin_Tear: actionsTaken',
  RN_Assessment_Of_Pain_Skin_Tear='Skin_Tear: RN Assessment of Pain',
  GP_Informed_Skin_Tear='Skin_Tear: GP Informed',
  GP_Prescribed_Pain_Medication_Skin_Tear='Skin_Tear: GP Prescribed pain medication',
  Clinical_Nurse_Specialist_Review_Of_Skin_Tear_Skin_Tear='Skin_Tear: Clinical nurse specialist review of skin tear',
  Dressing_Applied_Skin_Tear='Skin_Tear: Dressing applied',
  Cream_Applied_Skin_Tear='Skin_Tear: Cream applied',
  Staff_Monitoring_Their_Condition_Skin_Tear='Skin_Tear: Staff monitoring their condition',
  Nutritional_Supplements_Have_Been_Commenced_Skin_Tear='Skin_Tear: Nutritional supplements have been commenced',
  Pain_Relief_Analygesic_Was_Given_Skin_Tear='Skin_Tear: Pain relief analygesic was given',
  Pain_Relief_Was_Effective_Yes_Skin_Tear='Skin_Tear: Pain_Relief_Was_Effective_Yes',
  Pain_Relief_Was_Effective_No_Skin_Tear='Skin_Tear: Pain_Relief_Was_Effective_No',
  Pain_Relief_Was_Effective_Waiting_Skin_Tear='Skin_Tear: Pain_Relief_Was_Effective_Waiting',
  Pain_Relief_Was_Effective_Skin_Tear='Skin_Tear: Pain_Relief_Was_Effective',

  Other_Injury_Other='Other: other injury'
}

export enum Infection_Urinary_Inputs {
  Actions_Taken='actionsTaken',
  Urine_Test_Taken_And_Sent_To_Pathology='Urine Test taken and sent to pathology',
  GP_Prescribed_Antibiotics='GP Prescribed Antibiotics',
  GP_Informed='GP Informed',
  Fluids_Increased='Fluids increased',
  Managing_Symptoms_With_Common_UTI_Treatment_Approaches='Managing symptoms with common UTI treatment approaches',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_Respiratory_Inputs {
  Actions_Taken='actionsTaken',
  GP_Informed='GP Informed',
  GP_Prescribed_Antibiotics='GP Prescribed Antibiotics',
  Observations_Monitored='Observations Monitored',
  Fluids_Increased='Fluids increased',
  Managing_Symptoms_With_Common_UTI_Treatment_Approaches='Managing symptoms with common UTI treatment approaches',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_Wound_Inputs {
  The_Cause_Of_The_Wound_Is='theCauseOfTheWoundIs',
  Wound_Place='woundPlace',
  Actions_Taken='actionsTaken',
  Swab_Of_Wound_Taken_And_Sent_To_Pathology='Swab of wound taken and sent to pathology',
  RN_Assessment_Of_Pain='RN Assessment of Pain',
  GP_Informed='GP Informed',
  GP_Prescribed_Pain_Medication='GP Prescribed pain medication',
  GP_Prescribed_Antibiotics='GP Prescribed Antibiotics',
  Dressing_Applied='Dressing applied',
  Clinical_Nurse_Specialist_Review_Of_Wound='Clinical nurse specialist review of wound',
  Nutritional_Supplements_Are_Being_Taken_To_Manage_Condition='Nutritional supplements are being taken to manage condition',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_Skin_Inputs {
  Actions_Taken='actionsTaken',
  Swab_Of_Infection_Taken_And_Sent='Swab of infection taken and sent to pathology',
  GP_Prescribed_Medication_Or_Treatment='GP prescribed medication or treatment',
  Dressing_Applied='Dressing applied',
  GP_Informed='GP Informed',
  Antibiotics_Commenced='Antibiotics commenced',
  Referral_To_Wound_Specialist='Referral to wound specialist',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
  Description_Of_The_Infection='Description of the infection'
}

export enum Infection_Cellulitis_Inputs {
  Actions_Taken='actionsTaken',
  GP_Informed='GP Informed',
  GP_Prescribed_Medication_Or_Treatment='GP prescribed medication or treatment',
  Antibiotics_Commenced='Antibiotics commenced',
  Monitoring_For_Any_Spread='Monitoring for any spread',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_EarNoseThroatENT_Inputs {
  Actions_Taken='actionsTaken',
  RN_Assessment_Conducted='RN assessment conducted',
  GP_Informed='GP Informed',
  Monitoring_Fluids_And_Food_Intake='Monitoring fluids and food intake',
  GP_Prescribed_Medication_Or_Treatment='GP prescribed medication or treatment',
  Antibiotics_Commenced='Antibiotics commenced',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_Eye_Inputs {
  Actions_Taken='actionsTaken',
  RN_Assessment_Conducted='RN assessment conducted',
  GP_Informed='GP Informed',
  Dressing_Applied='Dressing applied',
  GP_Prescribed_Medication_Or_Treatment='GP prescribed medication or treatment',
  Antibiotics_Commenced='Antibiotics commenced',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_Covid_First_Update_To_Family_Inputs {
  Covid_Tests='Covid-19 tests',
  Covid_RAT_Test='Covid-19 RAT Test',
  Covid_RAT_Test_Result='Covid RAT Test Result',
  Covid_PCR_Test='Covid-19 PCR Test',
  Covid_PCR_Test_Result='Covid-19 PCR Test Result',
  Actions_Taken='Actions taken',
  Increased_Fluids_Encouraged='Increased fluids encouraged',
  Observations_Being_Monitored_Regularly='Observations being monitored regularly',
  GP_Informed='GP Informed',
  GP_Prescribed_Antiviral_Medication='GP Prescribed Antiviral medication',
  Antiviral='Antiviral',
  Lagevrio='Lagevrio® (molnupiravir)',
  Paxlovid='Paxlovid® (nirmatrelvir + ritonavir)',
  Isolation_Rules_As_Per_Isolation_Protocol_Apply='Isolation rules as per isolation protocol apply',
  Covid_Visitation_Rules_Apply='Covid visitation rules apply',
  Visitation_Rules='visitation rules',
  Isolation_To_Finish_On='Isolation to finish on',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Infection_Covid_Ongoing_Update_Inputs {
  Update_On_The_Residents_Condition='Update on the residents condition',
  Covid_Tests_Have_Been_Completed_Today='Covid-19 tests have been completed today',
  Covid_RAT_Test='Covid-19 RAT Test',
  Covid_PCR_Test='Covid-19 PCR Test',
  Covid_PCR_Test_Result='Covid-19 PCR Test Result',
  Covid_RAT_Test_Result='Covid RAT Test Result',
  Actions_Taken_By_Team='Actions taken by team',
  Isolation_Rules_As_Per_Isolation_Protocol_Apply='Isolation rules as per isolation protocol apply',
  Isolation_To_Finish_On='Isolation to finish on',
  Covid_Visitation_Rules_Apply='Covid visitation rules apply',
  Outline_The_Current_Visitation_Rules='outline the current visitation rules',
  Other_Information='otherInformation',
}

export enum Infection_Other_Inputs {
  Description_Of_The_Infection='descriptionOfTheInfection',
  Description_Of_Any_Treatment='descriptionOfAnyTreatment',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum GPReview_GeneralReview_Inputs {
  Name_Of_GP='Name of GP GeneralReview',
  Description_Of_The_GP_Review='descriptionOfTheGPReview',
  GP_Changed_Medication='GP changed medication',
  Describe_Change_To_Medication='Describe change to medication',
  Other_Information='Other information',
}

export enum GPReview_Deterioration_Inputs {
  Name_Of_GP='Name of GP Deterioration',
  Description_Of_The_GP_Review='descriptionOfTheGPReview',
  GP_Changed_Medication='GP changed medication',
  Describe_Change_To_Medication='Describe change to medication',
  Other_Information='Other information',
}

export enum GPReview_MedicationReview_Inputs {
  Name_Of_GP='Name of GP MedicationReview',
  Description_Of_The_GP_Review='descriptionOfTheGPReview',
  GP_Changed_Medication='GP changed medication',
  Describe_Change_To_Medication='Describe change to medication',
  Other_Information='Other information',
}

export enum GPReview_ReviewPost_Inputs {
  Name_Of_GP='Name of GP ReviewPost',
  Description_Of_The_GP_Review='descriptionOfTheGPReview',
  GP_Changed_Medication='GP changed medication',
  Describe_Change_To_Medication='Describe change to medication',
  Other_Information='Other information',
}

export enum GPReview_Other_Inputs {
  Name_Of_GP='Name of GP Other',
  Description_Of_The_GP_Review='descriptionOfTheGPReview',
  GP_Changed_Medication='GP changed medication',
  Describe_Change_To_Medication='Describe change to medication',
  Other_Information='Other information',
}

export enum Incident_Bruise_Inputs {
  How_Did_The_Bruise_Happen='How did the bruise happen',
  Bruise_Place='Bruise Place',
  Actions_Taken='actionsTaken',
  RN_Assessment_Of_Pain='RN Assessment of Pain',
  GP_Informed='GP Informed',
  GP_Prescribed_Pain_Medication='GP Prescribed pain medication',
  Clinical_Nurse_Specialist_Review_Of_Bruising='Clinical nurse specialist review of bruising',
  Cream_Applied='Cream applied',
  Nutritional_Supplements_Have_Been_Commenced='Nutritional supplements have been commenced',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_SkinTear_Inputs {
  Cause_Of_The_Skin_Tear='Cause of the skin tear',
  Skin_Tear_Place='Skin tear place',
  Actions_Taken='actionsTaken',
  RN_Assessment_Of_Pain='RN Assessment of Pain',
  Pain_Relief_Analygesic_Was_Given='Pain relief analygesic was given',
  Pain_Relief_Was_Effective='Pain relief was effective',
  Pain_Relief_Was_Effective_Yes='Pain relief was effective: YES',
  Pain_Relief_Was_Effective_No='Pain relief was effective: NO',
  Pain_Relief_Was_Effective_Waiting='Pain relief was effective: WAITING FOR EFFECT',
  GP_Informed='GP Informed',
  GP_Prescribed_Pain_Medication='GP Prescribed pain medication',
  Clinical_Nurse_Specialist_Review_Of_Skin_Tear='Clinical nurse specialist review of skin tear',
  Dressing_Applied='Dressing applied',
  Cream_Applied='Cream applied',
  Nutritional_Supplements_Have_Been_Commenced='Nutritional supplements have been commenced',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_PressureInjury_Inputs {
  Cause_Of_The_Pressure_Injury='Cause of the pressure injury',
  Happened_Outside_Of_The_Aged_Care_Home='Happened outside of the aged care home',
  Happened_Outside_Of_The_Aged_Care_Home_Value='Happened outside of the aged care home: Value',
  Where_Did_The_Injury_Happen='Where did the injury happen?',
  Pressure_Injury_Place='Pressure injury place',
  Actions_Taken='actionsTaken',
  RN_Assessment_Of_Pain='RN Assessment of Pain',
  GP_Informed='GP Informed',
  Our_Team_Are_Repositioning_Them_Regularly='Our team are repositioning them regularly as required to relieve pressure',
  GP_Prescribed_Pain_Medication='GP Prescribed pain medication',
  Clinical_Nurse_Specialist_Review_Of_Pressure_Sore='Clinical nurse specialist review of pressure sore',
  Dressing_Applied='Dressing applied',
  Cream_Applied='Cream applied',
  Nutritional_Supplements_Have_Been_Commenced='Nutritional supplements have been commenced',
  Air_Mattress_Used_To_Relieve_Pressure='Air mattress used to relieve pressure',
  Sheepskin_To_Relieve_Pressure='Sheepskin to relieve pressure',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_Ulcer_Inputs {
  Cause_Of_The_Ulcer='Cause of the ulcer',
  Ulcer_Place='Ulcer place',
  Actions_Taken='actionsTaken',
  RN_Assessment_Of_Pain='RN Assessment of Pain',
  GP_Informed='GP Informed',
  GP_Prescribed_Pain_Medication='GP Prescribed pain medication',
  Clinical_Nurse_Review_Of_Ulcer='Clinical nurse review of ulcer',
  Dressing_Applied='Dressing applied',
  Cream_Applied='Cream applied',
  Nutritional_Supplements_Have_Been_Commenced='Nutritional supplements have been commenced',
  Refer_To_Wound_Specialist='Refer to wound specialist',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_Absconding_Inputs {
  Description_Of_What_Happened='Description of what happened',
  Actions_Taken='actionsTaken',
  GP_Informed='GP Informed',
  Commence_Delirium_Screening='commence delirium screening',
  RN_Completed_Head_To_Toe_Assessment='RN completed head to toe assessment',
  Referral_To_Dementia_Support_Australia='referral to dementia support Australia',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_Behavior_Inputs {
  Description_Of_What_Happened='Description of what happened',
  Actions_Taken='actionsTaken',
  GP_Informed='GP Informed',
  Commence_Delirium_Screening='commence delirium screening',
  Referral_To_Dementia_Support_Australia='referral to dementia support Australia',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_Choking_Inputs {
  Description_Of_What_Happened='Description of what happened',
  Actions_Taken='actionsTaken',
  GP_Informed='GP Informed',
  First_Aid_Provided_By_RN='First aid provided by RN',
  Geriatrician_Review='geriatrician review',
  Referral_To_Speech_Pathologist='Referral to speech pathologist',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Incident_Other_Inputs {
  Description_Of_What_Happened='Description of what happened',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum ChangeToCondition_ChangeToCognition_Inputs {
  Description_Of_Change='Description of change',
  ReviewBy='Review by',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum ChangeToCondition_ChangeToMobility_Inputs {
  Description_Of_Change='Description of change',
  Actions_Taken='actionsTaken',
  Review_By_Physio='Review by physio',
  Physio_Recommendation='physio recommendation',
  GP_Notified='GP notified',
  Prescribed_Treatment='Prescribed treatment',
  GP_Prescribed_Treatment='GP prescribed treatment',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum ChangeToCondition_ChangeToAppetite_Inputs {
  Description_Of_Change='Description of change',
  Actions_Taken='actionsTaken',
  Commenced_Food_Chart='Commenced Food Chart',
  Weight_Reviewed='Weight reviewed',
  Weekly_Weight_Monitoring='Weekly weight monitoring',
  Dietician_Referral='Dietician referral',
  GP_Notified='GP notified',
  Recommendations_For_Supplements='Recommendations for supplements',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum ChangeToCondition_ChangeToBehaviourMood_Inputs {
  Description_Of_Change='Description of change',
  Action_Taken_To_Care_For_This='Action taken to care for this',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum ChangeToCondition_WeightChangeUpdate_Inputs {
  Description_Of_Change='Description of change',
  Actions_Taken='actionsTaken',
  Commenced_Food_Chart='Commenced Food Chart',
  Weight_Reviewed='Weight reviewed',
  Weekly_Weight_Monitoring='Weekly weight monitoring',
  Dietician_Referral='Dietician referral',
  GP_Notified='GP notified',
  Recommendations_For_Supplements='Recommendations for supplements',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum ChangeToCondition_OtherGeneralUpdate_Inputs {
  Description_Of_Change='Description of change',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum MedicationIncidents_MedicationIncidents_Inputs {
  Description_Of_What_Happened='Description of what happened',
  What_Medication='What medication',
  Possible_Side_Effects='Possible side effects',
  Actions_Taken='actionsTaken',
  GP_Notified='GP notified',
  Inform_The_Pharmacy='Inform the pharmacy',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  Other_Information='otherInformation',
}

export enum Pain_Pain_Inputs {
  Pain_Places='Pain places',
  Select_Type_Of_Pain='Select type of pain:',
  Stabbing='Stabbing',
  Sharp='Sharp',
  Dull='Dull',
  Neuropathic='Neuropathic',
  Burning='Burning',
  Aching='Aching',
  How_Is_The_Resident_Feeling='howIsTheResidentFeeling',
  What_Is_The_Cause_Of_The_Pain='What is the cause of the pain',
  Other_Information='otherInformation',
  Actions_Taken='actionsTaken',
  GP_Notified='GP notified',
  Pain_Relief_Analygesic_Administered ='Pain relief analygesic administered',
  Pain_Relief_Was_Effective='Pain relief was effective',
  Pain_Relief_Was_Effective_Yes='Pain relief was effective: YES',
  Pain_Relief_Was_Effective_No='Pain relief was effective: NO',
  Pain_Relief_Was_Effective_Waiting='Pain relief was effective: WAITING FOR EFFECT',
  What_Other_Actions_Are_Being_Taken_To_Relieve_Pain='What other actions are being taken to relieve pain',
  Pain_Medication_Not_Required ='Pain medication not required',
  RN_Has_Conducted_An_Assessment_Of_Pain ='RN has conducted an assessment of pain',
  Heat_Pack_Used ='Heat Pack Used',
  Ice_Pack_Used ='Ice Pack Used ',
  Massage_Has_Been_Given ='Massage has been given',
  Repositioning_As_Required='Repositioning as required'
}

export enum Physiotherapy_Review_General_Update_From_Physio_Inputs {
  General_Description='General description',
}

export enum Physiotherapy_Review_New_Aid_Walker_Inputs {
  General_Description='General description',
}

export enum Physiotherapy_Review_New_Exercises_Inputs {
  General_Description='General description',
}

export enum Physiotherapy_Review_Mobility_ReAssessed_Inputs {
  General_Description='General description',
}


export enum Request_For_Items_Clothing_Footwear_Inputs {
  Description_Of_Items_That_Are_Needed='Description of items that are needed',
  Size_Of_Items='Size of items',
  Other_Information='Other Information'
}

export enum Request_For_Items_Food_Items_Inputs {
  Description_Of_Items_That_Are_Needed='Description of items that are needed',
  Size_Of_Items='Size of items',
  Other_Information='Other Information'
}

export enum Request_For_Items_Personal_Care_Items_Inputs {
  Description_Of_Items_That_Are_Needed='Description of items that are needed',
  Size_Of_Items='Size of items',
  Other_Information='Other Information'
}

export enum Request_For_Items_Other_Inputs {
  Description_Of_Items_That_Are_Needed='Description of items that are needed',
  Size_Of_Items='Size of items',
  Other_Information='Other Information'
}


export enum Personal_Care_Change_In_Habits_Inputs {

}
export enum Personal_Care_Wearing_Of_Pads_Inputs {

}
export enum Personal_Care_Change_In_Personal_Hygiene_Needs_Inputs {

}

export enum Nutritional_Nutritional_Inputs {
  Update_To_Nutrition='Update to nutrition',
  Diet_Change='Diet change',
  Appetite_Change='Appetite change',
  Referral_To_Speech_Pathologist_Dietician='Referral to - Speech Pathologist / Dietician',
  Change_To_Assistance_Required='Change to assistance required',
  Fluid_Restrictions='Fluid restrictions',
  Has_The_Resident_Commenced_A_New_Diet='Has the resident commenced a new diet',
  Select_New_Diet='Select new diet',
  Minced_Diet='Minced diet',
  Thickened_Fluids='Thickened fluids',
  Puree_Diet='Puree Diet',
  Soft_Diet='Soft Diet',
  Cutup='Cut-up',
  Other_Diet='Other_Diet',
  Other_Diet_Text='Other_Diet text',
  Does_The_Resident_Have_Change_To_Appetite='Does the resident have a change to appetite',
  Describe_The_Appetite_Change='describe the appetite change',
  Reasons_For_Change_In_Appetite_Or_Change_To_Diet='Reasons for change in appetite or change to diet',
  Cognition_Mood='Cognition mood',
  Disease_Progression='Disease progression',
  Swallowing_Difficulties='Swallowing difficulties',
  Other_Reason ='other reason',
  Write_Other_Reason='writeother reason',
  Has_There_Been_A_Weight_Change='Has_There_Been_A_Weight_Change',
  The_Change_In_Weight=' the change in weight',
  Resident_Has_Been_Referred_To='Resident has been referred to',
  Speech_Pathologist='Speech Pathologist',
  Dietician ='Dietician ',
  Other='Other specialist',
  Location_Of_The_Appointment='Location of the appointment',
  At_Nursing_Home='At nursing home',
  External_Appointment='External appointment',
  Address_Of_Appointment='address of appointment',
  Time_And_Date_Of_The_Appointment='Time and Date of the appointment',
  Contact_Details_Of_The_Allied_Health_Professional='Contact details of the Allied Health Professional',
  A_Family_Member_Will_Need_To_Take_The_Resident='A family member will need to take the resident',
  Transport_Is_Required='Transport is required',
  Payment_Is_Required='Payment is required',
  Items_Need_To_Be_Taken_To_The_Appointment='Items need to be taken to the appointment',
  What_Items_Are_Required_To_Be_Taken='what items are required to be taken',
  An_Interpreter_Is_Require='An interpreter is require',
  Interpreter_Has_Been_Booked='Interpreter has been booked',
  How_Is_The_Resident='How is the resident',
  Other_Information='Other information'
}

export enum Referral_To_Allied_Health_Professiona_Inputs {
  Select_Type_Of_Allied_Health_Professional='Select type of Allied Health Professional',
  Dietician='Dietician',
  Speech_Pathology='Speech Pathology',
  Physiotherapist='Physiotherapist',
  Podiatrist='Podiatrist',
  Palliative_Care_Nurse='Palliative care nurse',
  Optometrist='Optometrist',
  Dementia_Support_Australia='Dementia Support Australia',
  Other ='other ',
  What_The_Other_Allied_Health_Professional_Is=' what the other Allied Health Professional is',
  Reason_For_Appointment='Reason for appointment',
  Location_Of_The_Appointment='Location of the appointment',
  At_Nursing_Home='At nursing home',
  External_Appointment='External appointment',
  Address_Of_Appointment='address of appointment',
  GP_Has_Provided_Referral='GP has provided referral',
  No_Referral_Required='no referral required',
  Referral_Has_Been_Provided_To_Allied_Health_Professional='Referral has been provided to Allied Health Professional',
  Time_And_Date_Of_The_Appointment='Time and Date of the appointment',
  Contact_Details_Of_The_Allied_Health_Professional='Contact details of the Allied Health Professional',
  A_Family_Member_Will_Need_To_Take_The_Resident='A family member will need to take the resident',
  Transport_Is_Required='Transport is required',
  Payment_Is_Required='Payment is required',
  Items_Need_To_Be_Taken_To_The_Appointment='Items need to be taken to the appointment',
  What_Items_Are_Required_To_Be_Taken='what items are required to be taken',
  An_Interpreter_Is_Require='An interpreter is require',
  Interpreter_Has_Been_Booked='Interpreter has been booked',
  How_Is_The_Resident='How is the resident',
  Other_Information='Other information'
}
export enum Referral_To_Specialist_Inputs {
  Select_Type_Of_Specialist='Select type of Specialist',
  Geriatrician ='Geriatrician',
  Nurse_Practitioner='Nurse Practitioner',
  Psychogeriatrician ='Psychogeriatrician ',
  Wound_Consult='Wound Consult',
  Cardiologist='Cardiologist',
  Neurologist='Neurologist',
  Other='Other',
  Type_Of_Specialist='Type of specialist',
  Location_Of_The_Appointment='Location of the appointment',
  At_Nursing_Home='At nursing home',
  External_Appointment='External appointment',
  Address_Of_Appointment='address of appointment',
  Reason_For_Appointment='Reason for appointment',
  GP_Has_Provided_Referral='GP has provided referral',
  Referral_Has_Been_Provided_To_Specialist='Referral has been provided to Specialist',
  Time_And_Date_Of_The_Appointment='Time and Date of the appointment',
  Contact_Details_Of_The_Allied_Health_Professional='Contact details of the Allied Health Professional',
  A_Family_Member_Will_Need_To_Take_The_Resident='A family member will need to take the resident',
  Transport_Is_Required='Transport is required',
  Payment_Is_Required='Payment is required',
  Items_Need_To_Be_Taken_To_The_Appointment='Items need to be taken to the appointment',
  What_Items_Are_Required_To_Be_Taken='what items are required to be taken',
  An_Interpreter_Is_Require='An interpreter is require',
  Interpreter_Has_Been_Booked='Interpreter has been booked',
  How_Is_The_Resident='How is the resident',
  Other_Information='Other information'
}

export enum Return_From_Hospital_Inputs {
  Reason_For_Hospitalisation='Reason for hospitalisation',
  Date_Transfer_To_Hospital='Date transfer to hospital',
  Date_Returned_From_Hospital='Date returned from hospital',
  Summary_Of_The_Hospital_Visit='Summary of the hospital visit',
  There_Been_A_Change_To_Medication='Has there been a change to medication',
  Medication_Changes='medication changes',
  Has_There_Been_A_Change_To_Diet='Has there been a change to diet',
  Change_To_Diet='change to diet',
  Has_There_Been_A_Change_To_Mobility='Has there been a change to mobility',
  Describe_The_Change_To_Mobility='describe the change to mobility',
  Has_There_Been_A_Change_To_Weight='Has there been a change to weight',
  Change_To_Weight='change to weight',
  A_Skin_Integrity_Check_Has_Been_Completed_By_An_RN='A skin integrity check has been completed by an RN',
  Any_Skin_Issues_Found='Any skin issues found',
  Describe_The_Issues_Found='describe the issues found',
  Followup_Appointments_Required='Follow-up appointment(s) required',
  Describe_The_Appointments_Required_And_When='describe the appointment(s) required and when',
  How_Is_The_Resident='How is the resident',
  Other_Information='Other information'
}

export enum Other_Other_Inputs {
  Describe_The_Update='Describe the update',
  How_Is_The_Resident='How is the resident',
  Other_Information='Other information',
  Files='files',
}

export enum Resident_Of_The_Day_Inputs {
  Checklist='Checklist',
  All_Observations_Within_Normal_Range='All observations within normal range',
  The_Following_Issues_Were_Found ='The following issues were found',
  Outline_Key_Findings='outline key findings',
  Has_There_Been_Change_To_Weigh='Has there been a change to weigh',
  Write_The_Change_To_Weight='write the change to weight',
  How_Is_The_Resident='How is the resident',
  Other_Information='Other information'
}

export type InputsName = Fall_Fall_Inputs 
                          | Infection_Urinary_Inputs | Infection_Respiratory_Inputs | Infection_Wound_Inputs 
                          | GPReview_GeneralReview_Inputs | GPReview_Deterioration_Inputs | GPReview_MedicationReview_Inputs 
                          | GPReview_ReviewPost_Inputs | GPReview_Other_Inputs | Infection_Skin_Inputs | Infection_Cellulitis_Inputs
                          | Infection_EarNoseThroatENT_Inputs | Infection_Eye_Inputs | Infection_Other_Inputs | Incident_Bruise_Inputs
                          | Infection_Covid_First_Update_To_Family_Inputs | Infection_Covid_Ongoing_Update_Inputs 
                          | Incident_SkinTear_Inputs | Incident_PressureInjury_Inputs | Incident_Ulcer_Inputs | Incident_Absconding_Inputs
                          | Incident_Behavior_Inputs | Incident_Choking_Inputs | Incident_Other_Inputs
                          | ChangeToCondition_ChangeToCognition_Inputs | ChangeToCondition_ChangeToMobility_Inputs | ChangeToCondition_ChangeToAppetite_Inputs
                          | ChangeToCondition_ChangeToBehaviourMood_Inputs | ChangeToCondition_OtherGeneralUpdate_Inputs | ChangeToCondition_WeightChangeUpdate_Inputs
                          | MedicationIncidents_MedicationIncidents_Inputs | Pain_Pain_Inputs
                          | Physiotherapy_Review_General_Update_From_Physio_Inputs | Physiotherapy_Review_New_Aid_Walker_Inputs
                          | Physiotherapy_Review_New_Exercises_Inputs | Physiotherapy_Review_Mobility_ReAssessed_Inputs
                          | Request_For_Items_Clothing_Footwear_Inputs | Request_For_Items_Food_Items_Inputs | Request_For_Items_Personal_Care_Items_Inputs
                          | Request_For_Items_Other_Inputs | Personal_Care_Change_In_Habits_Inputs | Personal_Care_Wearing_Of_Pads_Inputs
                          | Personal_Care_Change_In_Personal_Hygiene_Needs_Inputs 
                          | Nutritional_Nutritional_Inputs
                          | Referral_To_Allied_Health_Professiona_Inputs | Referral_To_Specialist_Inputs 
                          | Other_Other_Inputs
                          | Resident_Of_The_Day_Inputs
                          | Return_From_Hospital_Inputs

export enum UpdateMessageSymbols {
  Line_Break = '//n',
  Paragraph_Break = '<br />'
}