import ResidentController from "@source/api/controllers/residentController";
import { IDownloadUnreadUpdateHistoryRequest, IGetAllUpdatesRequest, ResidentStatus } from "@source/api/models/residentModel";
import DateRangePicker from "@source/components/UI/inputs/dateRangePicker/dateRangePicker";
import SearchInput from "@source/components/UI/inputs/searchInput/searchInput";
import SelectInput from "@source/components/UI/inputs/selectInput/selectInput";
import Loader from "@source/components/UI/loader/loader";
import useFetching from "@source/hooks/useFetching";
import nursingHomeStore from "@source/store/nursingHomeStore";
import updatesStore from "@source/store/updatesStore";
import { IFillVariant } from "@source/utils/enums";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import HistoryCard from "../../../../components/historyCard/historyCard";
import styles from "./historyTab.module.scss";
import Svg from "@components/UI/svg/svg";
import iconPlus from "@source/assets/icons/iconPlus.svg";
import iconDownload from "@source/assets/icons/iconDownload.svg";
import EmployeeController from "@source/api/controllers/employeeController";
import { ISelectItem } from "@components/UI/inputs/autocompleteInput/autocompleteInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import Checkbox from "@source/components/UI/buttons/checkbox/checkbox";
import { SelectChangeEvent } from "@mui/material";
import userStore from "@store/userStore";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import DownloadUnreadUpdateHistoryModal from "@components/downloadUnreadUpdateHistoryModal/downloadUnreadUpdateHistoryModal";
import FileSaver from 'file-saver';

const HistoryTab = observer((props: HistoryTabProps) => {
  const {} = props;
  const [getUpdates, isLoading, error] = useFetching<ReturnType<typeof ResidentController.getAllUpdates>>(ResidentController.getAllUpdates);
  const [getEmployeesNames, getEmployeesNamesLoading, getEmployeesNamesError] = useFetching<ReturnType<typeof EmployeeController.getNames>>(EmployeeController.getNames);
  const [downloadUnreadUpdateHistory, downloadUnreadUpdateHistoryLoading, downloadUnreadUpdateHistoryError] = useFetching<ReturnType<typeof ResidentController.downloadUnreadUpdateHistory>>(ResidentController.downloadUnreadUpdateHistory);
  const router = useHistory();

  const [employeesNames, setEmployeesNames] = useState<ISelectItem[]>([])
  
  useEffect(() => {
    const fetchUpdates = async () => {
      const request: IGetAllUpdatesRequest = {
        nursingHomeId: nursingHomeStore.getId(),
      };

      const response = await getUpdates(request);

      if (response) {
        updatesStore.setUpdates(response.updates);
      }
    };

    const fetchEmployeesNames = async () => {
      const getEmployeesNamesRes = await getEmployeesNames(nursingHomeStore.activeNursingHome?._id);

      if (getEmployeesNamesRes) {
        const getEmployeesNamesResSelectItems = getEmployeesNamesRes.map((employeeName) => {
          return {
            title: `${employeeName.firstName} ${employeeName.lastName}`,
            value: employeeName._id,
            id: employeeName._id,
          }
        });
        
        setEmployeesNames([
          {
            title: 'Staff members',
            value: 'all',
            id: '0',
          },
          {
            title: 'Agency staff',
            value: 'agencyStaff',
            id: '1',
          },
          ...getEmployeesNamesResSelectItems
        ])
      }
    }

    resetFilters();
    fetchEmployeesNames();
    fetchUpdates();
  }, [nursingHomeStore.activeNursingHome])

  const selectItems = useMemo(
    () => [
      {
        title: "All priority",
        value: "all",
        id: 1,
      },
      {
        title: "High",
        value: "critical",
        id: 2,
      },
      {
        title: "Medium",
        value: "major",
        id: 3,
      },
      {
        title: "Low",
        value: "minor",
        id: 4,
      },
    ],
    []
  );

  const [search, setSearch] = useState("");
  const [filtersDirty, setFiltersDirty] = useState(false);
  const [downloadUnreadUpdatesModal, setDownloadUnreadUpdatesModal] = useState(false);

  const validateSchema = Yup.object({
  });

  const mainFormik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      priority: '',
      dateRange: null,
      isRead: null,
      employee: "",
    },
    onSubmit: () => {

    }
  });

  useEffect(() => {
    const { priority, dateRange, isRead, employee } = mainFormik.values;

    if (filtersDirty) {
      const fetchUpdates = async () => {
        const request: IGetAllUpdatesRequest = {
          nursingHomeId: nursingHomeStore.getId(),
          searchString: search,
          priority: (!priority || priority === selectItems[0].value) ? "" : priority,
          startDate: dateRange ? moment(dateRange[0]).startOf("day").toISOString() : "",
          endDate: dateRange && dateRange[1] ? moment(dateRange[1]).endOf("day").toISOString() : "",
          isRead: isRead ? false : null,
          isAgency: employee === employeesNames[1]?.value ? true : null,
          employeeIds: employee && employee !== employeesNames[0]?.value && employee !== employeesNames[1]?.value ? [employee] : [],
        };

        const response = await getUpdates(request);
  
        if (response) {
          updatesStore.setUpdates(response.updates);
        }
      };

      const timer = setTimeout(() => {
        fetchUpdates();
      }, 500)

      return () => {
        clearTimeout(timer);
      }
    }
  }, [search]);

  useEffect(() => {
    const { priority, dateRange, isRead, employee } = mainFormik.values;

    if (filtersDirty) {
      const fetchUpdates = async () => {
        const request: IGetAllUpdatesRequest = {
          nursingHomeId: nursingHomeStore.getId(),
          searchString: search,
          priority: priority === selectItems[0].value ? "" : priority,
          startDate: dateRange ? moment(dateRange[0]).startOf("day").toISOString() : "",
          endDate: dateRange && dateRange[1] ? moment(dateRange[1]).endOf("day").toISOString() : "",
          isRead: isRead ? false : null,
          isAgency: employee === employeesNames[1]?.value ? true : null,
          employeeIds: employee && employee !== employeesNames[0]?.value && employee !== employeesNames[1]?.value ? [employee] : [],
        };
  
        const response = await getUpdates(request);
  
        if (response) {
          updatesStore.setUpdates(response.updates);
        }
      };
  
      fetchUpdates();
    }

  }, [mainFormik.values]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  };

  const onDateRangeChange = (dateRange: [Date, Date] | null) => {
    mainFormik.setFieldValue('dateRange', dateRange);
    
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  }

  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const selectedItem = selectItems.find((selectItem) => selectItem.value === e.target.value);

    if (selectedItem) {
      mainFormik.setFieldValue('priority', selectedItem.value);
    }
        
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  };

  const onSelectStaffChange = (e: SelectChangeEvent<string>) => {
    const selectedStaffItem = employeesNames.find((employeesName) => employeesName.value === e.target.value);

    if (selectedStaffItem) {
      mainFormik.setFieldValue('employee', selectedStaffItem.value);
    }
        
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  };

  const onTabUpdateDelete = async (updateId: string) => {
    updatesStore.deleteUpdate(updateId);
  }

  const resetFilters = () => {
    setSearch('');
    mainFormik.resetForm();
    setFiltersDirty(false);
  }

  const clearFilters = () => {
    mainFormik.resetForm();
    setSearch('');
  }

  const handleCheckboxOnlyReadChange = (e) => {
    mainFormik.setFieldValue("isRead", !mainFormik.values.isRead);
        
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  }

  const getFiltersCounter = () => {
    const { priority, dateRange, isRead, employee } = mainFormik.values;

    let counter = 0;

    if (search) {
      counter++;
    };

    if (priority && priority !== selectItems[0].value) {
      counter++;
    };

    if (dateRange) {
      counter++;
    }

    if (isRead) {
      counter++;
    }
    
    if (employee && employee !== employeesNames[0]?.value) {
      counter++;
    }

    return counter;
  }

  const toggleDownloadUpdatesModal = () => {
    setDownloadUnreadUpdatesModal(!downloadUnreadUpdatesModal);
  }

  const onDownloadUnreadUpdateHistory = async (req: IDownloadUnreadUpdateHistoryRequest) => {
    const downloadResidentUpdateHistoryRes = await downloadUnreadUpdateHistory(nursingHomeStore.activeNursingHome?._id, req);

    if (downloadResidentUpdateHistoryRes) {
      let blob =  new Blob([downloadResidentUpdateHistoryRes], { type: req.type })

      FileSaver.saveAs(blob, `report_${moment().format("DD.MM.YYYY")}.xlsx`)
    }
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit, isValid} = mainFormik;
  return(
    <div className={styles.history}>
      <DownloadUnreadUpdateHistoryModal open={downloadUnreadUpdatesModal} setOpen={toggleDownloadUpdatesModal} onDownload={onDownloadUnreadUpdateHistory}/>

      <div className={styles.filters}>
        <div className={styles.visibleFilters}>
          <SearchInput className={styles.search} value={search}
                onChange={onSearchChange} fill={IFillVariant.Fill} 
                placeHolder="Search"/>

          {
            getFiltersCounter() > 0 ?
              <div className={styles.clearFilters} onClick={clearFilters}>
                <span className={styles.text}>Clear filters ({getFiltersCounter()})</span>
              </div>
              :
              <></>
          }
        </div>

        <div className={`${styles.hiddenFilters}`}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <SelectInput className={`${styles.priority} ${!values.priority && styles.priority_placeholder}`} value={values.priority} 
                  handleChange={onSelectChange} id="residentsSelect" 
                  items={selectItems} placeholder="Priority"/>

            <SelectInput className={`${styles.staff} ${!values.employee && styles.staff_placeholder}`} placeholder="Staff members" value={values.employee} 
                  handleChange={onSelectStaffChange} id="staffSelect" 
                  items={employeesNames}/>

            <DateRangePicker className={styles.dateRange} value={values.dateRange} maxDate={new Date()}
                  onChange={onDateRangeChange} fill={IFillVariant.Fill} id="dateRange" />

            <Checkbox
              label="Only unread"
              value={String(values.isRead)}
              className={styles.radio_input}
              onChange={handleCheckboxOnlyReadChange}
              checked={!!values.isRead}
              id="isRead"
            />
          </form>

          {
            userStore.isPermission(HomePermissionsEnum.DOWNLOAD_UNREAD_UPDATES) ?
              <div className={styles.downloadUnreadUpdates} onClick={toggleDownloadUpdatesModal}>
                <Svg className={styles.icon} width={24} height={24} src={iconDownload} color="#3956C7"/>

                <span className={styles.text}>Download unread updates</span>
              </div>
              :
              <></>  
          }
        </div>
      </div>

      {isLoading
        ? <div className={styles.loader}>
            <Loader width={80} height={80} borderWidth={6}/>
          </div>
        : <div className={styles.list}>
            {updatesStore.updates?.length
              ? updatesStore.updates.map((update) => (
                  <HistoryCard 
                    residentStatus={ResidentStatus.ACTIVE}
                    key={update._id} 
                    className={styles.card} 
                    onTabUpdateDelete={onTabUpdateDelete} 
                    isChatIcon={false}
                    update={update} 
                    /> 
                ))
              : <></>}
          </div>
      }
    </div>
  );
});

export default HistoryTab;

interface HistoryTabProps {}
