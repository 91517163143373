import { 
  Route, 
  Switch, 
  useLocation, 
  Redirect, 
  RouteProps, 
  RouteComponentProps, 
  useHistory} from "react-router-dom";
import React, { FC, useEffect } from 'react';
import { ROUTES } from './utils/utils';
import { useAuth } from './hooks/useAuth';
import LoginPage from './pages/LoginPage/LoginPage';
import HomePage from "./pages/HomePage/HomePage";
import AccessDeniedPage from "./pages/AccessDeniedPage/AccessDeniedPage";
import InteractionObserver from "./api/interactionObserver";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import ChangePasswordPage from "./pages/ChangePasswordPage/ChangePasswordPage";
import SetPasswordPage from "./pages/SetPasswordPage/SetPasswordPage";
import ResetPasswordPage from "@pages/ResetPasswordPage/ResetPasswordPage";
import ForgotPasswordPage from "@pages/ForgotPasswordPage/ForgotPasswordPage";
import AccountHasBeenActivatedPage from "@pages/AccountHasBeenActivatedPage/AccountHasBeenActivatedPage";
import AgencyAccountHasBeenActivatedPage from "@pages/AgencyAccountHasBeenActivatedPage/AgencyAccountHasBeenActivatedPage";
import AgencyLoginNamePage from "@pages/AgencyLoginNamePage/AgencyLoginNamePage";
import AgencyLoginTypeNumberPage from "@pages/AgencyLoginTypeNumberPage/AgencyLoginTypeNumberPage";
import { useFirebaseLogEvent } from "./utils/hooks/useFirebaseLogEvent";
import TwoFactorLoginPage from "@pages/TwoFactorLoginPage/TwoFactorLoginPage";
import TrialModePage from "@pages/TrialModePage/TrialModePage";
import AdminSuperAdminPanel from "@pages/AdminSuperAdminPanel/AdminSuperAdminPanel";

export const PublicRoutes = [
  {path: ROUTES.LoginPage, component: LoginPage, exact: false},
  {path: ROUTES.AgencyLoginNamePage, component: AgencyLoginNamePage, exact: false},
  {path: ROUTES.AgencyLoginTypeNumberPage, component: AgencyLoginTypeNumberPage, exact: false},
  {path: ROUTES.SetPasswordPage, component: SetPasswordPage, exact: false},
  {path: ROUTES.AccountHasBeenActivatedPage, component: AccountHasBeenActivatedPage, exact: false},
  {path: ROUTES.AgencyAccountHasBeenActivatedPage, component: AgencyAccountHasBeenActivatedPage, exact: false},
  {path: ROUTES.ChangePasswordPage, component: ChangePasswordPage, exact: false},
  {path: ROUTES.ResetPasswordPage, component: ResetPasswordPage, exact: false},
  {path: ROUTES.ForgotPasswordPage, component: ForgotPasswordPage, exact: false},
  {path: ROUTES.TwoFactorLoginPage, component: TwoFactorLoginPage, exact: false},
  {path: ROUTES.TrialModePage, component: TrialModePage, exact: false},
  
  {path: ROUTES.EmptyURL, component: LoginPage, exact: true},
  {path: ROUTES.NotFoundPage, component: NotFoundPage, exact: true},
  {path: ROUTES.AccessDeniedPage, component: AccessDeniedPage, exact: true},
];

export const PrivateRoutes = [
  {path: ROUTES.HomePage, component: HomePage, exact: false},
  {path: ROUTES.AdminSuperAdminPanel, component: AdminSuperAdminPanel, exact: false},
];

const AppRouter = () => {
  const location = useLocation();
  const authed = useAuth();
  const [logEvent] = useFirebaseLogEvent();

  useEffect(() => {
    if(authed) {
      new InteractionObserver();
    }
  }, [])


  const onPageChange = () => {
    logEvent('routes_navigation', {
      path: location.pathname
    })
  }


  return (
    <Switch location={location}>
      {PublicRoutes.map((route) => <Route {...route} key={route.path} />)}
      {PrivateRoutes.map((route) => <PrivateRoute {...route} key={route.path} />)}
      {onPageChange()}
      <Route component={NotFoundPage}/>
    </Switch>
  );
};

export default AppRouter;

export const PrivateRoute: FC<IPrivateRouteProps> = (props: IPrivateRouteProps) => {
  const { component, ...rest } = props;
  const Component = component as FC<RouteComponentProps>;
  const authed = useAuth();
  const router = useHistory()

  return (
    <Route
      { ...rest }
      
      render={
        (routeProps) => (
          authed
          ? <Component { ...routeProps } />
          : <Redirect to={{ pathname: ROUTES.LoginPage, state: { from: routeProps.location } }} />)
      }
    />
  );
};

interface IPrivateRouteProps extends RouteProps {
  component: React.FC<any>,
};
