import { IAgencyLogInRequest, IAgencyLogInResponse, IAgencyRegisterRequest, IAgencyRegisterResponse, IChangeAgencyAccountPasswordReq, ILogInRequest, ILogInResponse, MFALoginReq, MFAVerifyReq } from '@source/api/models/authModels';
import AuthService from '@source/api/services/authService';
import userStore from '@source/store/userStore';
import InteractionObserver from '../interactionObserver';
import { SELECTED_NURSING_HOME_ID } from '../models/nursingHomeModels';

export default class AuthController {
  static async login(req: ILogInRequest): Promise<ILogInResponse> {
    return await AuthService.login(req);
  }

  static async agencyLogin(req: IAgencyLogInRequest): Promise<IAgencyLogInResponse> {
    return await AuthService.agencyLogin(req);
  }

  static async agencyRegister(req: IAgencyRegisterRequest): Promise<IAgencyRegisterResponse> {
    return await AuthService.agencyRegister(req);
  }
  
  static async resetPassword(oldPassword: string, newPassword: string) {
    return await AuthService.resetPassword(oldPassword, newPassword)
  }

  static async changePassword(email: string, password: string, oldPassword: string) {
    return await AuthService.changePassword(email, password, oldPassword)
  }

  static async changeAgencyAccountPassword(agencyId: string, req: IChangeAgencyAccountPasswordReq) {
    return await AuthService.changeAgencyAccountPassword(agencyId, req);
  }
  
  static async resendConfirmationEmail(email: string) {
    return await AuthService.resendConfirmationEmail(email);
  }

  static async refreshTokens(refreshToken: string) {
    return await AuthService.refresh(refreshToken);
  }

  static async mfaGenerate(email: string) {
    return await AuthService.mfaGenerate(email);
  }

  static async mfaVerify(req: MFAVerifyReq) {
    return await AuthService.mfaVerify(req);
  }
  
  static async mfaLogin(req: MFALoginReq) {
    return await AuthService.mfaLogin(req);
  }
  
  static async logout() {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem(SELECTED_NURSING_HOME_ID);
    userStore.resetUserInfo();
    new InteractionObserver().clearInstance();
  }
}