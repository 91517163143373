import { HomePermissionsEnum, OrganizationPermissionsEnum } from "@source/api/models/permissionsModels";
import { IUser, MFALoginStore } from "@source/api/models/userModels";
import { makeAutoObservable } from "mobx";
import nursingHomeStore from "./nursingHomeStore";
import { Role } from "@source/utils/models/adminModels";
import { SELECTED_ORGANIZATION_ID } from "@source/api/models/nursingHomeModels";

class UserStore {
  loggedUser: IUser | null = null;
  loginEmail: string | null = null;
  expiredModal: boolean | null = null;
  organizationId: string | null = null;
  agencyUser: any = null;
  mfaLogin: MFALoginStore | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setMfaLogin(mfaLogin: MFALoginStore | null) {
    this.mfaLogin = mfaLogin;
  }

  getMfaLogin() {
    return this.mfaLogin;
  }

  setAgencyUserEmailPassword(userParts: any) {
    this.agencyUser = {
      rootEmail: userParts.email,
      rootPassword: userParts.password,
    }
  }

  setAgencyUserFirsLastNames(userParts: any) {
    this.agencyUser = {
      ...this.agencyUser,
      firstName: userParts.firstName,
      lastName: userParts.lastName
    }
  }

  setAgencyUserTypeRegNumber(userParts: any) {
    this.agencyUser = {
      ...this.agencyUser,
      agencyType: userParts.agencyType,
      registrationNumber: userParts.registrationNumber
    }
  }

  setAgencyUserNull() {
    this.agencyUser = null;
  }

  getAgencyUser() {
    return this.agencyUser;
  }

  setExpiredModal(expiredModal: boolean | null) {
    this.expiredModal = expiredModal;
  }

  getExpiredModal(): boolean | null {
    return this.expiredModal;
  }

  setUserInfo(userInfo: IUser) {
    this.loggedUser = userInfo;
  }

  setUserPhoto(photoUrl: string) {
    if (this.loggedUser) {
      this.loggedUser = {
        ...this.loggedUser,
        photo: photoUrl,
      }
    }
  }

  resetUserInfo() {
   this.loggedUser = null;
   this.loginEmail = null;
   this.organizationId = null;
   localStorage.removeItem(SELECTED_ORGANIZATION_ID);
  }

  getUserInfo() {
    return this.loggedUser;
  }

  getOrganizationId() {
    return this.organizationId || "";
  }

  setOrganizationId(organizationId: string | null) {
    this.organizationId = organizationId;

    if (this.loggedUser?.account.role === Role.SUPER_ADMIN && organizationId) {
      localStorage.setItem(SELECTED_ORGANIZATION_ID, organizationId);
    }
  }

  getLocalStorageOrganizationId() {
    return localStorage.getItem(SELECTED_ORGANIZATION_ID);
  }

  getId() {
    return this.loggedUser?._id || "";
  }

  setLoginEmail(loginEmail: string) { // to redirect user to change password page (if pass is expired)
    this.loginEmail = loginEmail;
  }

  getLoginEmail() {
    return this.loginEmail || "";
  }
  resetLoginEmail() {
    this.loginEmail = '';
  }

  isPermission(permission: HomePermissionsEnum | OrganizationPermissionsEnum): boolean {
    if (this.loggedUser?.account.role === Role.SUPER_ADMIN && this.loggedUser?.account.permissions.global) {
      return !!this.loggedUser?.account.permissions.global.some((foundedPermission) => foundedPermission === permission);
    } else {
      if (Object.values(HomePermissionsEnum).includes(permission as HomePermissionsEnum)) {
        const foundedHomePermission = this.loggedUser?.account.permissions.homes.find((homePermission) => homePermission.home._id === nursingHomeStore.getId());
  
        return !!foundedHomePermission?.permissions.some((foundedPermission) => foundedPermission === permission);
      }
  
      if (Object.values(OrganizationPermissionsEnum).includes(permission as OrganizationPermissionsEnum)) {
        return !!this.loggedUser?.account.permissions.organization.permissions.some((foundedPermission) => foundedPermission === permission);
      }
    }

    return false;
  }
}

export default new UserStore();

