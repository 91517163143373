import { useHistory } from "react-router-dom";
import styles from "./newUpdatesDisabledModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import { ContactTypeEnum } from "@source/api/models/allUsersModels";
import residentsStore from "@store/residentsStore";

const NewUpdatesDisabledModal = observer((props: NewUpdatesDisabledModalProps) => {
  const {} = props;
  const router = useHistory();


  const getActiveResidentPrimaryContact = () => {
    return residentsStore.getModalNewUpdatesDisabledResident()?.familyRelations.find((familyRelation) => familyRelation.contactType === ContactTypeEnum.primary);
  }

  return(
    <Modal open={!!residentsStore.getModalNewUpdatesDisabledResident()} className={styles.modalComment} close={() => residentsStore.setModalNewUpdatesDisabledResident(null)}>
      <div className={styles.modalCreateUpdateDisabled}>
        {
          getActiveResidentPrimaryContact() ?
            <div className={styles.prmiraryContactDontUseBrenna}>
              <span className={styles.row}>
                <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName} {getActiveResidentPrimaryContact()?.familyMember.lastName}</span>, the primary contact, has chosen not to use Brenna app.
              </span>

              <span className={styles.row}>
                Updates for <span className={styles.row_bold}>{residentsStore.getModalNewUpdatesDisabledResident()?.firstName} {residentsStore.getModalNewUpdatesDisabledResident()?.lastName}</span> cannot be sent via Brenna.
              </span>

              <span className={styles.row}>
                Please call <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName}</span> on <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.phoneNumber}</span> with any updates on <span className={styles.row_bold}>{residentsStore.getModalNewUpdatesDisabledResident()?.firstName}'s</span> condition.
              </span>
            </div>
          :
          <div className={styles.noPrimaryContact}>
            <span className={styles.row}>
              Resident must have the primary contact to create new updates.
            </span>
          </div>
        }
        
        <Button
          className={styles.confirmBtn}
          onClick={() => residentsStore.setModalNewUpdatesDisabledResident(null)}
        >
          OK
        </Button>
      </div>
    </Modal>
  )
})

export default NewUpdatesDisabledModal;

interface NewUpdatesDisabledModalProps {

}
