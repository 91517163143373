import { ChangeEvent, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styles from "./downloadUnreadUpdateHistoryModal.module.scss";
import Modal from "@components/UI/modal/modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import RadioInput from "@components/UI/inputs/radioInput/radioInput";
import { DownloadFileType, DonwloadFileTypeDateRange, DonwloadFileTypeTranslate, IDownloadUnreadUpdateHistoryRequest } from "@source/api/models/residentModel";
import moment from "moment";
import DateRangePicker from "@source/components/UI/inputs/dateRangePicker/dateRangePicker";
import { IFillVariant } from "@source/utils/enums";
import iconExcel from "@source/assets/icons/iconExcel.svg";

const DownloadUnreadUpdateHistoryModal = (props: DownloadUnreadUpdateHistoryModalProps) => {
  const { open, setOpen, onDownload } = props;
  const router = useHistory();
  
  const validateSchema = Yup.object({
    dateRangeOption: Yup.string().required("Option reason is required"),
    dateRange: Yup.array().nullable().required("Date is required"),
  });

  const mainFormik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      dateRangeOption: DonwloadFileTypeDateRange.FULL_HISTORY,
      dateRange: null,
    },
    onSubmit: () => {
    }
  });

  const getRadios = useCallback(() => {
    let radios = [
      {value: DonwloadFileTypeDateRange.FULL_HISTORY, label: "Full history"},
      {value: DonwloadFileTypeDateRange.DATE_RANGE, label: "Select date range"},
    ];

    return radios;
  }, [])

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    mainFormik.setFieldValue("dateRangeOption", e.target.value);
  }

  const handleDateRange = (date: [Date, Date] | null) => {
    mainFormik.setFieldValue("dateRange", date);
  }

  const handleDownload = (downloadType: string) => {
    if (mainFormik.values.dateRangeOption === DonwloadFileTypeDateRange.DATE_RANGE && !mainFormik.values.dateRange) {
      mainFormik.handleSubmit();

      return;
    }

    const downloadUpdateHistoryReq: IDownloadUnreadUpdateHistoryRequest = {
      type: downloadType,
      startDate: mainFormik.values.dateRange ? moment(mainFormik.values.dateRange[0]).format() : "",
      endDate: mainFormik.values.dateRange ? moment(mainFormik.values.dateRange[1]).format() : "",
    }

    if (mainFormik.values.dateRangeOption !== DonwloadFileTypeDateRange.DATE_RANGE) {
      delete downloadUpdateHistoryReq.startDate;
      delete downloadUpdateHistoryReq.endDate;
    }
    
    onDownload(downloadUpdateHistoryReq);
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = mainFormik;
  return(
    <Modal open={open} className={styles.downloadUnreadUpdateHistoryModal} close={setOpen} maxWidth={484}>
      <div className={styles.downloadUnreadUpdateHistory}>
        <span className={styles.title}>Download unread update history</span>

        <span className={`${styles.text} ${errors.dateRangeOption && styles.text_error}`}>
          Please select option.
        </span>

        <form className={styles.form} onSubmit={handleSubmit}>
          <RadioInput
            id="dateRangeOption" 
            name="dateRangeOption_radio"
            value={values.dateRangeOption}
            labelPlace="end"
            className={styles.radio_input}
            onChange={handleRadioChange}
            radios={getRadios()}
            touched={touched.dateRangeOption}
            error={errors.dateRangeOption} 
          />

          {
            values.dateRangeOption === DonwloadFileTypeDateRange.DATE_RANGE ?
              <DateRangePicker
                label=""
                placeholder="Select date"
                className={styles.dateRange} 
                value={values.dateRange} 
                onChange={handleDateRange} 
                fill={IFillVariant.Blank}
                maxDate={new Date()}
                touched={touched.dateRange}
                error={errors.dateRange}
              />
              :
              <></>
          }

          <div className={styles.downloadBtns}>
            <div className={styles.downloadFile} onClick={() => handleDownload(DownloadFileType.XLSX)}>
              <div className={styles.left}>
                <img className={styles.icon} src={iconExcel} alt="xlsx"/>
              </div>

              <div className={styles.right}>
                <span className={styles.text}>Download unread update history (.xlsx)</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default DownloadUnreadUpdateHistoryModal;

interface DownloadUnreadUpdateHistoryModalProps {
  open: boolean,
  setOpen: () => void,
  onDownload: (req: IDownloadUnreadUpdateHistoryRequest) => void,
}

