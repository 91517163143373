import styles from "./loginTwoFactorAuthModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import TextInput from "@components/UI/inputs/textInput/textInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@components/UI/buttons/button/button";
import useFetching from "@source/hooks/useFetching";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import {QRCodeSVG} from 'qrcode.react';
import { useEffect, useRef, useState } from "react";
import AuthController from "@source/api/controllers/authController";
import { MFAGenerateResponse, MFAVerifyReq } from "@source/api/models/authModels";
import Loader from "@components/UI/loader/loader";
import Notification from "@components/snackMessage/notification";

const LoginTwoFactorAuthModal = observer((props: LoginTwoFactorAuthModalProps) => {
  const { open, setOpen, email } = props;

  const [mfaVerify, mfaVerifyIsLoading, mfaVerifyError] = useFetching<ReturnType<typeof AuthController.mfaVerify>>(AuthController.mfaVerify);
  const [mfaGenerate, mfaGenerateIsLoading, mfaGenerateError] = useFetching<ReturnType<typeof AuthController.mfaGenerate>>(AuthController.mfaGenerate);

  const validateSchema = Yup.object({
    code: Yup.string().required("Code is required"),
  });

  const mainFormik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      code: '',
    },
    onSubmit: () => {
      onVerify();
    }
  });

  const [generateState, setGenereateState] = useState<MFAGenerateResponse | null>(null);

  useEffect(() => {
    const onGenerateMfa = async () => {
      const mfaGenerateRes = await mfaGenerate(email);

      if (mfaGenerateRes) {
        setGenereateState(mfaGenerateRes);
      }
    }

    if (open) {
      onGenerateMfa();
    }
  }, [email, open])

  const onVerify = async () => {
    const req: MFAVerifyReq = {
      code: mainFormik.values.code,
      ownerEmail: email,
    }

    const mfaVerifyRes = await mfaVerify(req);

    if (mfaVerifyRes) {
      new Notification().success('Your account has been successfully verified');
      onClose();
    }
  }

  const onClose = () => {
    setOpen();
    mainFormik.resetForm();
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue} = mainFormik;
  return(
    <Modal open={open} className={styles.loginTwoFactorAuthModal} maxWidth={480}>
      <div className={styles.loginTwoFactorAuthModalContainer}>
        <div className={styles.content}>
          <span className={styles.title}>Two-factor authentication (2FA)</span>

          <div className={styles.line}/>

          <div className={styles.requirements}>
            <span className={styles.title}>You need authentication app on your smartphone:</span>

            <ul className={styles.list}>
              <li className={styles.listItem}>Google Authenticator</li>
              <li className={styles.listItem}>Apple Authenticator</li>
              <li className={styles.listItem}>Duo</li>
              <li className={styles.listItem}>Authy</li>
            </ul>
          </div>

          <div className={styles.qrCode}>
            {
              mfaGenerateIsLoading ?
                <Loader width={32} height={32} borderWidth={5} color="#3956C7"/>
                :
                <QRCodeSVG size={200}  value={generateState?.authURL || ""} />
            }
          </div>
          
          <div className={styles.underQrCode}>
            Scan this QR code. Enter 6-digit code to verify your account and complete the setup.
          </div>

          <form className={styles.form} onSubmit={handleSubmit}>
            <TextInput id="code" label=""
              placeholder="Authentication code"
              className={styles.input}
              value={values.code} 
              handleChange={handleChange}
              touched={touched.code}
              error={errors.code} 
            />

            <div className={styles.troubles}>
              <span className={styles.text}>Trouble scanning? Enter this key in your app instead:</span>

              <span className={styles.key}>{generateState?.base32}</span>
            </div>

            <div className={styles.buttons}>
              <Button
                className={styles.button_close}
                size="small"
                onClick={onClose}
                disabled={mfaGenerateIsLoading}
              >
                Close
              </Button>

              <Button
                className={styles.button}
                size="small"
                event="submit"
                isLoading={mfaVerifyIsLoading}
                disabled={mfaGenerateIsLoading}
              >
                Verify & Activate
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
})

export default LoginTwoFactorAuthModal;

interface LoginTwoFactorAuthModalProps {
  open: boolean;
  setOpen: () => void;
  email: string,
}
