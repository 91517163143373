import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import { ICreateNursingHomeResponse, ICreateNursingHomeRequest, INursingHome, IEditNursingHomeResponse, IEditNursingHomeRequest, IEditNursingHomePhotoRequest, IGetNursingHomeResponse, IGetNursingHomesResponse, IGetNursingHomesRequest, IGetAvailableNursingHomesRequest, IGetAvailableNursingHomesResponse, IGetOrganizationsResponse, IGetOrganizationsReq } from "@source/api/models/nursingHomeModels";

export default class NursingHomeService {
  static async getNursingHome(nursingHomeId: string) {
    try {
      return (await api.get<IGetNursingHomeResponse>(`nursingHome/${nursingHomeId}`)).data
    } catch (e: any) {
      throw ReqError.getNursingHomeError(e.response)
    }
  }
  
  static async createNursingHome(req: ICreateNursingHomeRequest) {
    try {
      return (await api.post<ICreateNursingHomeResponse>('nursingHome', {...req})).data
    } catch (e: any) {
      throw ReqError.createNursingHomeError(e.response)
    }
  }

  static async editNursingHome(req: IEditNursingHomeRequest, nursingHomeId: string) {
    try {
      return (await api.put<IEditNursingHomeResponse>(`nursingHome/${nursingHomeId}`, {...req})).data
    } catch (e: any) {
      throw ReqError.editNursingHomeError(e.response)
    }
  }
  
  static async editNursingHomePhoto(req: FormData, nursingHomeId: string) {
    try {
      return (await api.patch<string>(`nursingHome/${nursingHomeId}/photo`, req)).data
    } catch (e: any) {
      throw ReqError.editNursingHomePhotoError(e.response)
    }
  }

  static async getNursingHomes({searchString = "", organizationId, limit = 100, page = 0}: IGetNursingHomesRequest) {
    try {
      return (await api.get<IGetNursingHomesResponse>(`nursingHome?searchString=${searchString}&organizationId=${organizationId}&limit=${limit}&page=${page}`)).data
    } catch (e: any) {
      throw ReqError.createNursingHomeError(e.response)
    }
  }

  static async getAvailableNursingHomes({organizationId}: IGetAvailableNursingHomesRequest) {
    try {
      return (await api.get<IGetAvailableNursingHomesResponse[]>(`nursingHome/available/employee?organizationId=${organizationId}`)).data
    } catch (e: any) {
      throw ReqError.getAvailableNursingHomesError(e.response)
    }
  }

  static async getOrganizations({searchString = '', limit = 25, page = 0}: IGetOrganizationsReq) {
    try {
      return (await api.get<IGetOrganizationsResponse>(`organization?searchString=${searchString}&limit=${limit}&page=${page}`)).data
    } catch (e: any) {
      throw ReqError.getOrganizationsError(e.response)
    }
  }
}