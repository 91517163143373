import { IAnnouncement } from "@source/api/models/announcementsModels";
import { makeAutoObservable } from "mobx";

class AnnouncementsStore {
  announcements: IAnnouncement[] = [];
  editAnnouncement: IAnnouncement | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAnnouncements(announcements: any[]) {
    this.announcements = announcements;
  }

  setEditAnnouncement(announcement: IAnnouncement) {
    this.editAnnouncement = announcement;
  }

  deleteAnnouncement(announcementId: string) {
    this.announcements = this.announcements.filter((announcement) => announcement._id !== announcementId);
  }

  getEditAnnouncement() {
    return this.editAnnouncement;
  }
}

export default new AnnouncementsStore();