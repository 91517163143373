import nursingHomeStore from '@source/store/nursingHomeStore';
import Notification from "@source/components/snackMessage/notification";
import axios, { AxiosRequestConfig } from "axios";
import { LogicExceptionList } from './models/errorTypes';
import AuthController from './controllers/authController';

export const URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  withCredentials: false,
  baseURL: URL
});


api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
  config.headers!.Accept = "application/json";
  config.headers!["Content-Type"] = "application/json";
  config.params = {...config.params, nursingHomeId: nursingHomeStore.getId()};

  return config;
});

api.interceptors.response.use((config: AxiosRequestConfig) => {
  return config;
}, (async (error) => {
  const originalRequest = error.config;

  // lack of permissions or use network not from white list
  if(error.response?.status === 403) {
    if(error.response.stringCode === LogicExceptionList.NOT_ENOUGH_PERMISSIONS || 
       error.response.stringCode === LogicExceptionList.PERMISSIONS_NOT_FOUND) {
        new Notification().warning('Not Enough Permissions');
    }
    else if(error.response.stringCode === LogicExceptionList.INVALID_ACCESS_KEY) {
      window.location.hash = '#/accessDenied';
    }
  }

  // expire token
  if(error.response?.status === 401 && error.config && !originalRequest._isRetry) {
    originalRequest._isRetry = true;
    localStorage.removeItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if(refreshToken) {
      const response = await AuthController.refreshTokens(refreshToken);
      if(response) {
        return api.request(originalRequest);
      }
    } else {
      new Notification().warning('You need to login');
      window.location.hash = '#/login'
    }
  }
  throw error;
}))

export interface AuthResponse {
  accessToken: string,
  refreshToken: string,
  email: string
}