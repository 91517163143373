import { OnboardingTrialModeModalActions, OnboardingTrialModeModalType } from "@source/api/models/onboadringModels";
import { makeAutoObservable } from "mobx";
import { setTimeout } from "timers";

class TrialModeStore {
  trialModal: boolean = false;
  trialModalAction: OnboardingTrialModeModalActions | null = null;
  trialModalType: OnboardingTrialModeModalType = OnboardingTrialModeModalType.CAN_NOT_DO;

  constructor() {
    makeAutoObservable(this);
  }

  setTrialModal(trialModal: boolean, action: OnboardingTrialModeModalActions | null, trialModalType: OnboardingTrialModeModalType = OnboardingTrialModeModalType.CAN_NOT_DO) {
    this.trialModal = trialModal;
    this.trialModalAction = action;
    this.trialModalType = trialModalType;
  }

  getTrialModal() {
    return {
      trialModal: this.trialModal,
      trialModalAction: this.trialModalAction,
      trialModalType: this.trialModalType
    };
  }

  get isTrialModal() {
    return this.trialModal;
  }

  setIsTrialModal() {
    this.trialModal = !this.trialModal;
  }
}

export default new TrialModeStore();

