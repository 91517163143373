import { getUrlId, HOME_ROUTES, ROUTES, stringToHslColor, SUPER_ADMIN_PANEL_ROUTES } from "@source/utils/utils";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../UI/buttons/button/button";
import styles from "./appHeader.module.scss";
import iconHouseFilled from "@source/assets/icons/iconHouseFilled.svg";
import iconLogout from "@source/assets/icons/iconLogout.svg";
import Dropdown, { IDropdownItem } from "../UI/dropdown/dropdown";
import { observer } from "mobx-react-lite";
import Svg from "../UI/svg/svg";
import AuthController from "@source/api/controllers/authController";
import residentsStore from "@source/store/residentsStore";
import userStore from "@source/store/userStore";
import nursingHomeStore from "@source/store/nursingHomeStore";
import { INursingHome, SELECTED_NURSING_HOME_ID } from "@source/api/models/nursingHomeModels";
import appStore from "@source/store/appStore";
import { HomePermissionsEnum, OrganizationPermissionsEnum } from "@source/api/models/permissionsModels";
import moment from "moment";
import CacheImg from "@components/UI/cacheImg/cacheImg";
import ConfirmModal from "@components/confirmModal/confirmModal";
import iconConfirmed from '@source/assets/icons/iconConfirmed.svg';
import { ProgressBar } from "react-rainbow-components";
import SuccessModal from "@components/successModal/successModal";
import useFetching from "@source/hooks/useFetching";
import { Role } from "@source/utils/models/adminModels";
import adminAgencyStore from '@source/store/adminAgencyStore';
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import trialModeStore from "@store/trialModeStore";
import { OnboardingTrialModeModalActions, OnboardingTrialModeModalType } from "@source/api/models/onboadringModels";

const AppHeader = observer((props: AppHeaderProps) => {
  const {} = props;

  const [resendConfirmationEmail, resendConfirmationEmailLoading, resendConfirmationEmailError] = useFetching<ReturnType<typeof AuthController.resendConfirmationEmail>>(AuthController.resendConfirmationEmail);

  const [dropdownItems, setDropdownItems] = useState<IDropdownItem[]>([]);
  
  const router = useHistory();
  const location = useLocation();
  const params = useParams<{residentId?: string, updateId?: string}>();
  const searchParams = new URLSearchParams(location.search);

  const [logEvent] = useFirebaseLogEvent();

  useEffect(() => {
    const newDropdownItems = nursingHomeStore.nursingHomes.map((nursingHome) => {
      return {
        onClick: () => onNursingHomeClick(nursingHome),
        text: nursingHome.name,
      }
    })

    setDropdownItems(newDropdownItems);
  }, [nursingHomeStore.nursingHomes])

  const onNewResident = () => {
    if (nursingHomeStore.isNursingHomeTrialMode) {
      trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.CREATE_RESIDENT, OnboardingTrialModeModalType.CAN_DO);
    }
    router.push(HOME_ROUTES.NewResident);
  }

  const onNursingHomeClick = (nursingHome: INursingHome) => {
    logEvent('change_home', {
      home: nursingHome.name,
      organization: nursingHome.organization.name,
    })

    nursingHomeStore.setActiveNursingHome(nursingHome);
    nursingHomeStore.setLocalStorageActiveNursingHome(nursingHome._id);
  }

  const getResidentCountPercents = () => {
    const activeCount = residentsStore.getResidentsCount()?.activeCount;
    const totalCount = residentsStore.getResidentsCount()?.totalCount;

    return activeCount && totalCount ? Math.round((activeCount / totalCount) * 100) : 0
  }
  
  const onResendEmail = async () => {
    const resendConfirmationEmailRes = await resendConfirmationEmail(residentsStore.editFamilyMember?.familyMember.email);

    if (resendConfirmationEmailRes) {
      logEvent('admin__family_members__resend_invitation', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })
    }

    return !!resendConfirmationEmailRes
  }

  const getResendInvitaionItems = (invitations: string[]) => {
    return invitations.map((invitation, index) => {
      return {
        onClick: () => {},
        text: (<span>{index === 0 ? "Initial" : "Reminder"}: <span style={{fontWeight: '600'}}>{moment(invitation).format("DD MMM YYYY")} at {moment(invitation).format("hh:mm A")}</span></span>),
        style: {
          pointerEvents: 'none',
        },
      } 
    })
  }

  const getHeader = () => {
    if(location.pathname.includes(HOME_ROUTES.ResidentsArchive)) {
      return (
        <>
          <span className={styles.title}>
            {
              residentsStore.getActiveArchiveResident() ?
              "Resident"
              :
              "Residents archive"
            }
          </span>

          {
            residentsStore.getActiveArchiveResident() ?
              <div className={styles.buttons}>
                <span className={styles.acrchive}>Archived {residentsStore.getActiveArchiveResident()?.archivedDate && moment(residentsStore.getActiveArchiveResident()?.archivedDate).format("DD MMM YYYY")}</span>

                <Button  onClick={onBackResidentsArchive} size="small"  
                  variant="additional" className={styles.backBtnList}
                  >Back to acrhive
                </Button>
              </div>
              :
              getHomeDropdown()
          }
        </>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.Residents)) {
      if(location.pathname.includes(HOME_ROUTES.NewResident)) {
        if (residentsStore.editResident) {
          return (
            <>
              <span className={styles.title}>Edit resident</span>
            </>
          )
        }

        return (
          <>
            <span className={styles.title}>Add resident</span>
          </>
        )
      }

      if(residentsStore.editFamilyMember) {
        return (
          <>
            <span className={styles.title}>Edit family member</span>

            {
              residentsStore.editFamilyMember?.familyMember?.account?.isTemporaryPassword ?
                <div className={styles.resendInvitation}>
                  <SuccessModal
                      cb={() => onResendEmail()}
                      text="Account activation reminder was sent successfully."
                    >
                      <Button
                        className={styles.confirmationAccountBtn}
                        event="visual"
                        isLoading={resendConfirmationEmailLoading}
                      >
                        Resend Invitation
                      </Button>
                    </SuccessModal>

                    {
                      residentsStore.editFamilyMember.familyMember.invitations.length > 0 ?
                        <div className={styles.invitations}>
                          <Dropdown className={styles.dropdown} items={getResendInvitaionItems(residentsStore.editFamilyMember.familyMember.invitations)} arrow>
                              <span className={styles.dropdown__text}>Click to show email history</span>
                          </Dropdown>
                        </div>
                        :
                        <></>
                    }
                </div>
              :
              <></>
            }
          </>
        )
      }

      if (residentsStore.addResidentFamilyMembers) {
        return (
          <>
            <span className={styles.title}>Add family member</span>
          </>
        )
      }

      
      if(location.pathname.includes('update')) {
        const residentId = getUrlId(location.pathname, 'residents');

        if(!residentsStore.getResidents()?.length) {
          router.push(HOME_ROUTES.Residents);
          return;
        }

        const resident = residentsStore.getResidents()?.find((resident) => resident._id === residentId);
        const multipleUpdateSelectedResidents = residentsStore.getMultipleUpdateSelectedResidents();
        return (
          <div className={styles.update}>
            {
              multipleUpdateSelectedResidents ?
                <>
                  {
                    multipleUpdateSelectedResidents.length > 3 ?
                      (
                        <>
                          {
                            multipleUpdateSelectedResidents.slice(0, 2).map((selectedResident) => (
                              <CacheImg 
                                url={selectedResident?.photo} 
                                alt="avatar" 
                                className={`${styles.photo} ${styles.photo_multiple}`} 
                                imgClassName={`${styles.photo} ${styles.photo_border}`} 
                                skeletonProps={{variant: 'circular'}}
                              >
                                <div className={styles.noPhoto} style={{backgroundColor: selectedResident ? stringToHslColor(selectedResident.firstName + selectedResident.lastName) : ""}}>
                                  {selectedResident?.firstName[0] + " " + selectedResident?.lastName[0]}
                                </div>
                              </CacheImg>
                            ))
                          }

                          <CacheImg 
                            url={""} 
                            alt="avatar" 
                            className={`${styles.photo} ${styles.photo_multiple}`} 
                            imgClassName={`${styles.photo} ${styles.photo_border}`} 
                            skeletonProps={{variant: 'circular'}}
                          >
                            <div className={styles.noPhoto} style={{backgroundColor: "hsl(0.62, 100, 99)"}}>
                              +{multipleUpdateSelectedResidents.length - 3}
                            </div>
                          </CacheImg>
                        </>
                      )
                      : 
                      (
                        multipleUpdateSelectedResidents.map((selectedResident) => (
                          <CacheImg 
                            url={selectedResident?.photo} 
                            alt="avatar" 
                            className={`${styles.photo} ${styles.photo_multiple}`} 
                            imgClassName={`${styles.photo} ${styles.photo_border}`} 
                            skeletonProps={{variant: 'circular'}}
                          >
                            <div className={styles.noPhoto} style={{backgroundColor: selectedResident ? stringToHslColor(selectedResident.firstName + selectedResident.lastName) : ""}}>
                              {selectedResident?.firstName[0] + " " + selectedResident?.lastName[0]}
                            </div>
                          </CacheImg>
                        )
                    ))
                  }
      
                  Multiple Resident Update
                </>
                :
                <>
                  <CacheImg 
                    url={resident?.photo} 
                    alt="avatar" 
                    className={styles.photo} 
                    imgClassName={styles.photo} 
                    skeletonProps={{variant: 'circular'}}
                  >
                    <div className={styles.noPhoto} style={{backgroundColor: resident ? stringToHslColor(resident.firstName + resident.lastName) : ""}}>
                      {resident?.firstName[0] + " " + resident?.lastName[0]}
                    </div>
                  </CacheImg>
      
                  Update on {resident?.firstName + ' ' + resident?.lastName}
                </>
            }

            {!!appStore.getUpdateHeaderBtn()
              ? <div className={styles.backBtn}>
                  {appStore.getUpdateHeaderBtn()}
                </div>
              : <></>}
          </div>
        )
      }
    }

    if(location.pathname.includes(HOME_ROUTES.Residents)) {
      return (
        <>
          {
            residentsStore.activeResident ?
              <span className={styles.title}>
                Resident
              </span>
              :
              <div className={`${styles.blockTitle} ${styles.blockTitle_residents}`}>
                <span className={styles.title}>
                  Residents

                  {
                    userStore.isPermission(HomePermissionsEnum.ADD_NEW_RESIDENT) ?
                      <div className={styles.adminIcon} onClick={onNewResident}>+</div>
                      :
                      ""
                  }
                </span>
                
                {
                  residentsStore.getResidentsCount() && residentsStore.getResidentsTotalCount() ?
                    <div className={styles.residentsCount}>
                      <div className={styles.info}>
                        <span className={styles.text}>Active: <span className={styles.bold}>{residentsStore.getResidentsCount()?.activeCount}</span> (out of {residentsStore.getResidentsCount()?.totalCount})</span>

                        <div className={styles.percents}>
                          <span className={styles.text}>{getResidentCountPercents()}%</span>

                          <img className={styles.confirmedIcon} src={iconConfirmed} alt="confirmed"/>
                        </div>
                      </div>

                      <div className={styles.progressBar}>
                        <ProgressBar className={styles.bar} value={getResidentCountPercents()} size="medium"/>
                      </div>
                    </div>
                  :
                  <div className={styles.residentsCount}/>
                }
              </div>
          }

          {
            residentsStore.getActiveResident() ?
              <div className={styles.buttons}>
                <Button  onClick={onBackResidents} size="small"  
                  variant="additional" className={styles.backBtnList}
                  >Back to list of residents
                </Button>

                {
                  userStore.isPermission(HomePermissionsEnum.ADD_UPDATE) ?
                  <Button 
                    className={styles.newUpdateBtn}
                    onClick={onNewUpdate}
                    size="small"
                    event="submit"
                  >
                    New Update
                  </Button>
                  :
                  <></>
                }
              </div>
              :
              getHomeDropdown()
          }
        </>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.Chat)) {
      return <span className={styles.title}>Chat with familys</span>
    }

    if(location.pathname.includes(HOME_ROUTES.History)) {
      return (
        <>
          <span className={styles.title}>Updates history</span>
          
          {getHomeDropdown()}
        </>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.Chat)) {
      return <span className={styles.title}>Chat with familys</span>
    }

    if(location.pathname.includes(HOME_ROUTES.Settings)) {
      return (
        <>
          <div className={styles.settings}>
            <span className={styles.title}>Settings</span>

            <span className={styles.loginDate}>
              Last login date: {userStore.getUserInfo()?.loginDate ? moment(userStore.getUserInfo()?.loginDate).format("DD.MM.YYYY hh:mm A") : ""}
            </span>
          </div>

          <div className={styles.settingsRight}>
            {
              userStore.getUserInfo()?.account.role === Role.SUPER_ADMIN ?
                <ConfirmModal
                  confirmCb={() => onChangeOrganization()}
                  className={styles.changeOrganization}
                  title="Are you sure you want to change the organisation?"
                >
                  <Button   
                    className={styles.changeOrganizationBtn}
                    size="small"
                    event="submit"
                  >
                    Change organisation
                  </Button>
                </ConfirmModal>
                :
                <></>
            }
            <ConfirmModal
              confirmCb={() => onLogout()}
              className={styles.logout}
              title="Are you sure you want to logout?"
            >
              <Svg
                  className={styles.icon}
                  src={iconLogout}
                  width={24}
                  height={24}
                  color="#E7313C"
              />

              <span className={styles.text}>Logout</span>
            </ConfirmModal>
          </div>
        </>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.NewAnnouncement)) {
      return (
        <span className={styles.title}>New announcement</span>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.Announcements)) {
      return (
        <>
          <span className={styles.title}>Announcements</span>

          <div className={styles.buttons}>
            {
              userStore.isPermission(HomePermissionsEnum.ADD_NEW_ANNOUNCEMENT_EVENT) ?
                  <Button 
                    className={styles.newAnnouncementBtn}
                    onClick={onNewAnnouncement}
                    size="small"
                    event="submit"
                  >
                    New
                  </Button>
                :
                <></>
              }
          </div>
        </>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.NewHome)) {
      return (
        <>
          <span className={styles.title}>
            {
              nursingHomeStore.editedNursingHome ?
              "Edit nursing home"
              :
              "New nursing home"
            }
          </span>
        </>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.NewEmployee)) {
      return (
          <span className={styles.title}>
            New employee
          </span>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.NewAgencyAccount)) {
      return (
        <span className={styles.title}>
          Add new agency nurse account
        </span>
      )
    }
    
    if(location.pathname.includes(HOME_ROUTES.AgencyAccountChangePassword)) {
      return (
        <span className={styles.title}>
          Change password for&nbsp;<span className={styles.blueBold}>{adminAgencyStore.getEditAgencyAccountPassword()?.email}</span>
        </span>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.AgencyAccountForgotPassword)) {
      return (
        <div className={styles.agencyForgotPassword}>
          <span className={styles.title}>
            Forgot password
          </span>

          <span className={styles.subTitle}>
            We will send a confirmation link to reset your password
          </span>
        </div>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.AgencyAccountForgotPassword)) {
      return (
        <div className={styles.agencyForgotPassword}>
          <span className={styles.title}>
            Forgot password
          </span>

          <span className={styles.subTitle}>
            We will send a confirmation link to reset your password
          </span>
        </div>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.AgencyAccountSetPassword)) {
      return (
        <div className={styles.agencySetPassword}>
          <span className={styles.title}>
            Forgot password
          </span>

          <span className={styles.subTitle}>
            Please create and confirm new password for&nbsp;<span className={styles.bold}>{searchParams.get('email')}</span>
          </span>
        </div>
      )
    }

    if(location.pathname.includes(HOME_ROUTES.AdminPanel)) {
      return (
        <div className={styles.adminHeader}>
          <div className={styles.header}>
            <span className={styles.title}>
              Admin panel
            </span>

            {
              location.pathname.includes(HOME_ROUTES.AdminPanelStaff) && userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE) ?
                <Button 
                  className={styles.newEmployeeBtn}
                  onClick={onAddNewEmployee}
                  size="small"
                  event="submit"
                  >
                  + Add new employee
                </Button>
                :
                <></>
            }

            {
              location.pathname.includes(HOME_ROUTES.AdminPanelHouses) && userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_HOUSE) ?
                <Button 
                  className={styles.newEmployeeBtn}
                  onClick={onAddNewHouse}
                  size="small"
                  event="submit"
                >
                  + Add new home
                </Button>
                :
                <></>
            }

            {
              location.pathname.includes(HOME_ROUTES.AdminPanelAgencyStaff) && userStore.isPermission(OrganizationPermissionsEnum.REGISTER_ROOT_AGENCY_ACCOUNT) ?
                <Button 
                  className={styles.newAgencyAccountBtn}
                  onClick={onAddNewAgencyAccount}
                  size="small"
                  event="submit"
                >
                  + Add new agency nurse account
                </Button>
                :
                <></>
            }
          </div>
          
          <div className={styles.nav}>
            {
              userStore.isPermission(OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD) ||
              // userStore.isPermission(OrganizationPermissionsEnum.EDIT_EMPLOYEE_PERMISSION) ||
              userStore.isPermission(OrganizationPermissionsEnum.ENABLE_EMPLOYEE) ||
              userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE) ||
              userStore.isPermission(OrganizationPermissionsEnum.DISABLE_EMPLOYEE)
                ?
              <NavLink 
                to={HOME_ROUTES.AdminPanelStaff} 
                className={styles.nav__item} 
                activeClassName={styles.nav__item_active}
              >
                Staff
              </NavLink>
              :
              <></>
            }
            {
              userStore.isPermission(OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER) ||
              userStore.isPermission(OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER)
               ?
              <NavLink 
                to={HOME_ROUTES.AdminPanelFamilyMembers} 
                className={styles.nav__item} 
                activeClassName={styles.nav__item_active}
              >
                Family members
              </NavLink>
              :
              <></>
            }
            {
              userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_HOUSE) ||
              userStore.isPermission(OrganizationPermissionsEnum.VIEW_ACCESS_KEYS) ||
              userStore.isPermission(OrganizationPermissionsEnum.CREATE_ACCESS_KEYS) ||
              userStore.isPermission(OrganizationPermissionsEnum.UPDATE_ACCESS_KEYS)
               ?
              <NavLink 
                to={HOME_ROUTES.AdminPanelHouses} 
                className={styles.nav__item} 
                activeClassName={styles.nav__item_active}
              >
                Homes
              </NavLink>
              :
              <></>
            }
            {
              userStore.isPermission(OrganizationPermissionsEnum.VIEW_ROOT_AGENCY_ACCOUNTS)
               ?
              <NavLink 
                to={HOME_ROUTES.AdminPanelAgencyStaff} 
                className={styles.nav__item} 
                activeClassName={styles.nav__item_active}
              >
                Agency staff
              </NavLink>
              :
              <></>
            }
          </div>
        </div>
      )
    }
  }

  const getHomeDropdown = () => {
    return (
      <div className={styles.menu}>
        <img className={styles.icon} src={iconHouseFilled} alt="home"/>

        <div className={styles.select}>
          <Dropdown className={styles.dropdown} items={dropdownItems} arrow>
              <span className={styles.dropdown__text}>{nursingHomeStore.activeNursingHome?.name}</span>
          </Dropdown>
        </div>
      </div>
    )
  }

  const onNewUpdate = () => {
    if (!residentsStore.getActiveResidentUpdateEnabled() || residentsStore.getActiveResidentFamilyMemberChoseNotToUseApp()) {
      residentsStore.setModalNewUpdatesDisabledResident(residentsStore.getActiveResident());
      
      return;
    }

    if (!residentsStore.isActiveResidentPrimaryContactActivated()) {
      residentsStore.setModalBlockNewUpdatesResident(residentsStore.getActiveResident());

      return;
    }

    if (residentsStore.isActiveResidentPrimaryContactActivated()) {
      logEvent('updates__click_new_update_button', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      if (nursingHomeStore.isNursingHomeTrialMode) {
        trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.CREATE_UPDATE, OnboardingTrialModeModalType.CAN_DO);
      }
      router.push({
        pathname: `${HOME_ROUTES.Residents}/${residentsStore.activeResident?._id}/update`,
      });

      return;
    }
  }

  const onNewAnnouncement = () => {
    if (nursingHomeStore.isNursingHomeTrialMode) {
      trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.CREATE_ANNOUNCEMENT, OnboardingTrialModeModalType.CAN_DO);
    }
    router.push(HOME_ROUTES.NewAnnouncement);
  }

  const onAddNewEmployee = () => {
    if (nursingHomeStore.isNursingHomeTrialMode) {
      trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.CREATE_EMPLOYEE, OnboardingTrialModeModalType.CAN_DO);
    }
    router.push(HOME_ROUTES.NewEmployee);
  }

  const onAddNewHouse = () => {
    router.push(HOME_ROUTES.NewHome);
  }

  const onAddNewAgencyAccount = () => {
    router.push(HOME_ROUTES.NewAgencyAccount);
  }
  
  const onBackResidents = () => {
    router.push(`${HOME_ROUTES.Residents}`);
    residentsStore.setFocusOnResident(residentsStore.getActiveResident());
  }

  const onBackResidentsArchive = () => {
    router.push(`${HOME_ROUTES.ResidentsArchive}`)
  }

  const onLogout = () => {
    logEvent("on_logout_button", {
      email: userStore.getUserInfo()?.email || "",
    })

    AuthController.logout();
    router.push(ROUTES.LoginPage)
  }

  const onChangeOrganization = () => {
    userStore.setOrganizationId(null);
    localStorage.removeItem(SELECTED_NURSING_HOME_ID);

    logEvent('organization_admin__change_organization', {});

    router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
  }

  const onBackToUpdates = () => {
    router.push(HOME_ROUTES.History);
  }

  return(
    <div className={styles.appHeader}>
      {getHeader()}
    </div>
  )
});

export default AppHeader;

interface AppHeaderProps {
}

