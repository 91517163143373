import { IResetPasswordRequest } from "../models/familyMemberModels";
import FamilyMemberService from "../services/familyMemberService";

export default class FamilyMemberController {
  static async resetPassword(body: IResetPasswordRequest) {
    return await FamilyMemberService.resetPassword(body);
  }

  static async nursingHomeInactiveFamilyMembers(nursingHomeId: string) {
    return await FamilyMemberService.nursingHomeInactiveFamilyMembers(nursingHomeId);
  }
}