
import { HomeStatus, IGetNursingHomeResponse, IGetNursingHomesResponse, INursingHome, SELECTED_NURSING_HOME_ID } from "@source/api/models/nursingHomeModels";
import { HousesTableData } from "@source/utils/models/adminModels";
import { makeAutoObservable } from "mobx";

class NursingHomeStore {
  nursingHomes: INursingHome[] = [];
  nursingHomesTotalCount: number = 0;
  adminNursingHomes: INursingHome[] = [];
  adminNursingHomesTotalCount: number = 0;
  activeNursingHome: INursingHome | null = null;
  editedNursingHome: INursingHome | null = null;
  focusNursingHome: INursingHome | null = null;
  focusNursingHomePage: number | null = null;
  isEditNursingHome: boolean = false;
  name: string = '';
  address: string = '';
  

  constructor() {
    makeAutoObservable(this);
  }

  private formatAadminNusringHome(nursingHome: INursingHome): HousesTableData {
    const {accessKeys, photo, email, address, _id, name, phone, isEmployeeUploaded, isFamiliesUploaded, status, lastEmployeeInvitation, lastFamiliesInvitation, endOnboardingDate} = nursingHome;

    const formatedNursingHome = {
      name,
      address,
      _id,
      email,
      photo,
      accessKeys,
      phone,
      actions: null,
      isEmployeeUploaded,
      isFamiliesUploaded,
      status,
      lastEmployeeInvitation, 
      lastFamiliesInvitation,
      endOnboardingDate,
    }

    return formatedNursingHome;
  }

  setFocusNursingHome(editedNursingHomeId: string | null) {
    this.focusNursingHome = this.adminNursingHomes.find((adminNursingHome) => adminNursingHome._id === editedNursingHomeId) || null;
  }

  getFocusNursingHome() {
    return this.focusNursingHome;
  }

  setFocusNursingHomePage(page: number | null) {
    this.focusNursingHomePage = page;
  }

  getFocusNursingHomePage() {
    return this.focusNursingHomePage;
  }

  getFormatedAdminHomes(): HousesTableData[] {
    return this.adminNursingHomes.map((employee) => {
      return this.formatAadminNusringHome(employee);
    })
  }

  editNursingHome(editedNursingHome: INursingHome) {
    if (this.activeNursingHome?._id === editedNursingHome._id) {
      this.activeNursingHome = editedNursingHome;
    }

    this.nursingHomes = this.nursingHomes.map(nursingHome => {
      if (nursingHome._id === editedNursingHome._id) {
        return {
          ...editedNursingHome,
        }
      }

      return {
        ...nursingHome,
      }
    })
  }

  setEditedNursingHome(editedNursingHome: HousesTableData | null) {
    this.editedNursingHome = this.adminNursingHomes.find((adminNursingHome) => adminNursingHome._id === editedNursingHome?._id) || null;
  }

  setNursingHomes(nursingHomesRes: IGetNursingHomesResponse) {
    this.nursingHomes = nursingHomesRes.nursingHomes;
    this.nursingHomesTotalCount = nursingHomesRes.totalCount;
  }

  setAdminNursingHomes(nursingHomesRes: IGetNursingHomesResponse) {
    this.adminNursingHomes = nursingHomesRes.nursingHomes;
    this.adminNursingHomesTotalCount = nursingHomesRes.totalCount;
  }

  setActiveNursingHome(nursingHome: INursingHome | null) {
    this.activeNursingHome = nursingHome;
  }

  setLocalStorageActiveNursingHome(nursingHomeId: string) {
    localStorage.setItem(SELECTED_NURSING_HOME_ID, nursingHomeId);
  }

  getLocalStorageActiveNursingHome() {
    return localStorage.getItem(SELECTED_NURSING_HOME_ID);
  }

  getId() {
    return this.activeNursingHome?._id || "";
  }

  getActiveNursingHome() {
    return this.activeNursingHome;
  }

  getNursingHomes() {
    return this.nursingHomes;
  }

  getAdminNursingHomes() {
    return this.adminNursingHomes;
  }

  get isNursingHomeTrialMode() {
    return this.activeNursingHome?.status === HomeStatus.ONBOARDING;
  }
}

export default new NursingHomeStore();
