import { getAnalytics, logEvent as firebaseLogEvent } from "firebase/analytics";
import { getApps } from "firebase/app";

function useFirebaseLogEvent(): IUseFirebaseLogEvent {
  const logEvent = (event: AvailableEvents, body: ILogEventBody) => {
    if (getApps().length > 0) {
      firebaseLogEvent(getAnalytics(), event as string, body);
    }
  }

  return [logEvent]
} 

interface ILogEventBody {
  [key: string]: string | boolean | number,
}

type AvailableEvents = 
  'login' 
  | 'on_logout_button' 
  | 'automatic_logout' 
  | 'set_password_new_account' 
  | 'forgot_password__send_letter'
  | 'forgot_password__reset_password'
  | 'auth__need_help'
  | 'change_home'
  | 'routes_navigation'
  | 'announcements__create'
  | 'announcements__edit'
  | 'announcements__delete'
  | 'help_section__tech_support'
  | 'help_section__feedback'
  | 'settings__change_info'
  | 'settings__change_password'
  | 'settings__change_photo'
  | 'settings__delete_photo'
  | 'updates__click_new_update_button'
  | 'updates__create'
  | 'updates__click_draft_button'
  | 'updates__draft_automatic_logout'
  | 'updates__click_continue_button_drafts'
  | 'updates__complete_draft'
  | 'updates__delete_draft'
  | 'organization_admin__login'
  | 'organization_admin__select_organization'
  | 'organization_admin__change_organization'
  | 'agency_login__login_as_agency'
  | 'agency_login__name'
  | 'agency_login__type_registration_number'
  | 'agency_login__successful_login'
  | 'admin__staff__create'
  | 'admin__staff__edit'
  | 'admin__staff__activate'
  | 'admin__staff__deactivate'
  | 'admin__homes__create'
  | 'admin__homes__edit'
  | 'admin__family_members__create'
  | 'admin__family_members__edit'
  | 'admin__family_members__disable'
  | 'admin__family_members__create_note'
  | 'admin__family_members__delete_note'
  | 'admin__family_members__change_email'
  | 'admin__family_members__resend_invitation'
  | 'admin__family_members__activate'
  | 'admin__family_members__deactivate'
  | 'admin__residents__create'
  | 'admin__residents__edit'
  | 'admin__residents__deactivate'
  | 'admin__residents__download_updates_history'
  | 'admin__agency_staff__create'
  | 'admin__agency_staff__change_password'
  | 'admin__agency_staff__forgot_password__send_letter'
  | 'admin__agency_staff__forgot_password__change_password'
  | 'admin__agency_staff__deactivate'
  | 'admin__agency_staff__activate'
  | 'admin__agency_staff__resend_invitation'

type IUseFirebaseLogEvent = [(event: AvailableEvents, body: ILogEventBody) => void]

export { useFirebaseLogEvent };