import styles from "./trialModeModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import iconTimer from "@source/assets/icons/iconTimer.svg";
import trialModeStore from "@store/trialModeStore";
import { OnboardingTrialModeModalType } from "@source/api/models/onboadringModels";

const TrialModeModal = observer((props: TrialModeModalProps) => {
  const { } = props;

  const trialModalObj = trialModeStore.getTrialModal();

  const onCancel = () => {
    trialModeStore.setIsTrialModal();

    setTimeout(() => {
      trialModeStore.setTrialModal(false, null);
    }, 100)
  }

  return(
    <Modal open={trialModeStore.isTrialModal} className={styles.TrialModeModal} maxWidth={410} close={onCancel}>
      <div className={styles.TrialModeModalContainer}>
        <div className={styles.content}>
          <img className={styles.icon} src={iconTimer} alt="timer"/>

          <div className={styles.textInfo}> 
            {
              trialModalObj.trialModalType === OnboardingTrialModeModalType.CAN_NOT_DO ?
                <span className={styles.title}>
                  You can’t {trialModalObj.trialModalAction} because you are using a practice mode
                </span>
                :
                <span className={styles.title}>
                  You can {trialModalObj.trialModalAction} in a practice mode
                </span>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default TrialModeModal;

interface TrialModeModalProps {
}