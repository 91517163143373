import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./residentCardContacts.module.scss";
import Svg from "@source/components/UI/svg/svg";
import iconEdit from "@source/assets/icons/iconEdit.svg";
import iconCloseComment from "@source/assets/icons/iconCloseComment.svg";
import iconCheck from "@source/assets/icons/iconCheck.svg";
import iconStar from "@source/assets/icons/iconStar.svg";
import iconEditResident from "@source/assets/icons/iconEditResident.svg";
import iconDelete from "@source/assets/icons/iconDelete.svg";
import { observer } from "mobx-react-lite";
import residentsStore from "@source/store/residentsStore";
import useFetching from "@source/hooks/useFetching";
import ResidentController from "@source/api/controllers/residentController";
import Modal from "@source/components/UI/modal/modal";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import Button from "@source/components/UI/buttons/button/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import userStore from "@source/store/userStore";
import { HOME_ROUTES } from "@source/utils/utils";
import iconConfirmed from '@source/assets/icons/iconConfirmed.svg';
import { ContactTypeEnum } from "@source/api/models/allUsersModels";
import { HomePermissionsEnum, OrganizationPermissionsEnum } from "@source/api/models/permissionsModels";
import ConfirmModal from "@components/confirmModal/confirmModal";
import Notification from "@components/snackMessage/notification";
import { IResidentFamilyRelation } from "@source/api/models/residentModel";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import nursingHomeStore from "@store/nursingHomeStore";
import { OnboardingResponseActions, OnboardingTrialModeModalActions, OnboardingTrialModeModalType } from "@source/api/models/onboadringModels";
import trialModeStore from "@store/trialModeStore";

const ResidentCardContacts = observer((props: ResidentCardContactsProps) => {
  const { activeResident } = residentsStore;
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();
  const [deleteComment, isDeleteLoading, deleteCommentError] = useFetching<ReturnType<typeof ResidentController.deleteResidentContactComment>>(ResidentController.deleteResidentContactComment);
  const [createComment, isCreateLoading, createCommentError] = useFetching<ReturnType<typeof ResidentController.createResidentContactComment>>(ResidentController.createResidentContactComment);
  const [deleteFamilyMemberCommunication, deleteFamilyMemberCommunicationLoading, deleteFamilyMemberCommunicationError] = useFetching<ReturnType<typeof ResidentController.deleteFamilyMemberCommunication>>(ResidentController.deleteFamilyMemberCommunication);
  
  const [commentModalContact, setCommentModalContact] = useState<any>(null)
  const [contactsModal, setContactsModal] = useState<any>(false)
  
  const validateSchema = Yup.object({
    comment: Yup.string().required("Comment is required"),
  });

  const mainFormik = useFormik<{comment: string}>({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      comment: '',
    },
    onSubmit: async ({comment}) => {
      await onCreateComment(comment);
    }
  });

  const onDeleteComment = async (familyMemberId: string) => {
    const deleteCommentRes = await deleteComment({familyMemberId, body: {residentId: activeResident?._id}});
    
    if (deleteCommentRes) {
      if (deleteCommentRes.action === OnboardingResponseActions.TRIAL_PERIOD) {
        trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.DELETE_NOTE)
        
        return;
      }

      logEvent('admin__family_members__delete_note', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      new Notification().success("Comment has been deleted");
      residentsStore.deleteActiveResidentContactComment(familyMemberId);
    }
  }

  const onCreateComment = async (comment: string) => {
    const createCommentRes = await createComment({familyMemberId: commentModalContact.familyMember._id, body: {
      residentId: activeResident?._id,
      comment
    }});

    if (createCommentRes) {
      if (createCommentRes.action === OnboardingResponseActions.TRIAL_PERIOD) {
        trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.CREATE_NOTE)
        
        return;
      }

      logEvent('admin__family_members__create_note', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      new Notification().success("Comment created successfully");
      setCommentModalContact(null);
      residentsStore.addActiveResidentContactComment(commentModalContact.familyMember._id, comment);
      mainFormik.resetForm();
    }
  }

  const onNewFamilyMember = () => {
    residentsStore.setAddResidentFamilyMembers(residentsStore.activeResident);
    if (nursingHomeStore.isNursingHomeTrialMode) {
      trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.CREATE_FAMILY_MEMBER, OnboardingTrialModeModalType.CAN_DO);
    }
    router.push(`${HOME_ROUTES.Residents}/${residentsStore.activeResident?._id}/addResidentFamilyMembers`)
  }

  const onEditFamilyMember = (familyRelation) => {
    residentsStore.setAddResidentFamilyMembers(residentsStore.activeResident);
    residentsStore.setEditFamilyMember(familyRelation);
    if (nursingHomeStore.isNursingHomeTrialMode) {
      trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.EDIT_FAMILY_MEMBER, OnboardingTrialModeModalType.CAN_DO);
    }
    router.push(`${HOME_ROUTES.Residents}/${residentsStore.activeResident?._id}/addResidentFamilyMembers/${familyRelation.familyMember._id}`)
  }

  const onDeleteFamilyMember = async (familyRelation) => {
    const disableFamilyMemberRes = await deleteFamilyMemberCommunication(familyRelation.familyMember._id, activeResident?._id);
    
    if (disableFamilyMemberRes) {
      if (disableFamilyMemberRes.action === OnboardingResponseActions.TRIAL_PERIOD) {
        trialModeStore.setTrialModal(true, OnboardingTrialModeModalActions.DELETE_FAMILY_MEMBER)
        
        return;
      }

      logEvent('admin__family_members__disable', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })
      
      new Notification().success(disableFamilyMemberRes.message);
      residentsStore.disableActiveResidentsFamilyMembers(familyRelation.familyMember._id)
    } 
  }

  const onCreateNote = (contact) => {
    setCommentModalContact(contact)
    mainFormik.setFieldValue("comment", contact.comment || "")
  }

  const isResidentSecondaryContact = useCallback(() => {
    return activeResident?.familyRelations?.some((familyRelation) => familyRelation.contactType === ContactTypeEnum.secondary);
  }, [activeResident?.familyRelations])

  const isResidentCommonContact = useCallback(() => {
    return activeResident?.familyRelations?.some((familyRelation) => familyRelation.contactType === ContactTypeEnum.other);
  }, [activeResident?.familyRelations])

  const getCommonContacts = useCallback(() => {
    return activeResident?.familyRelations.filter((familyRelation) => familyRelation.contactType === ContactTypeEnum.other) || []
  }, [activeResident?.familyRelations])

  const isShowConfirmedIcon = (familyRelation: IResidentFamilyRelation) => {
    return !familyRelation?.familyMember?.account?.isTemporaryPassword && 
    familyRelation?.familyMember?.isConfirmed &&
    !familyRelation?.familyMember?.isDisabled;
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = mainFormik;
  return (
   <>
    {
      commentModalContact
      ?
        <Modal open={!!commentModalContact} className={styles.modalComment} close={() => {
          setCommentModalContact(null)
          mainFormik.resetForm();
        }}>
          <div className={styles.modal}>
            <span className={styles.title}>Notes for {commentModalContact.familyMember.firstName} {commentModalContact.familyMember.lastName}</span>

            <form className={styles.form} onSubmit={handleSubmit}>
              <TextInput id="comment" label="Comment"
                  placeholder="Write any comment here"
                  className={styles.input}
                  value={values.comment}
                  touched={touched.comment}
                  error={errors.comment} 
                  multiline={true}
                  handleChange={handleChange}
                />
              
              <div className={styles.bottom}>
                <Button 
                  className={styles.saveBtn}
                  event="submit"
                  isLoading={isCreateLoading}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      : 
      <></>
    }

    {
      contactsModal ?
        <Modal open={contactsModal} className={styles.modalContacts} close={() => {setContactsModal(false)}}>
          <div className={`${styles.modal} ${getCommonContacts().length > 1 ? styles.modal_scrollable : ""}`}>
            <span className={styles.title}>Additional contacts</span>
            
            <div className={styles.familyRelations}>
              {
                getCommonContacts().map((familyRelation) => {
                  return (
                    <div className={styles.familyRelation} key={familyRelation._id}>
                      <div className={styles.name}>
                        <div className={styles.text}>
                          {familyRelation.familyMember.firstName} {familyRelation.familyMember.lastName} ({familyRelation.relationship})
                          
                          {
                            isShowConfirmedIcon(familyRelation) ?
                              <img className={styles.confirmedIcon} src={iconConfirmed} alt="confirmed"/>
                              :
                              <></>
                          }
                        </div>

                        <div className={styles.adminActions}>
                          {
                            userStore.isPermission(OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER) ?
                              <div className={styles.editFamilyMember} onClick={() => onEditFamilyMember(familyRelation)}>
                                <Svg
                                  className={styles.icon}
                                  src={iconEditResident}
                                  width={16}
                                  height={16}
                                  color="#3956C7"
                                />

                                <span className={styles.text}>Edit</span>
                              </div>
                              :
                              <></>
                          }

                          {
                            userStore.isPermission(HomePermissionsEnum.DELETE_FAMILY_RELATION) 
                              ? <ConfirmModal
                                  confirmCb={() => onDeleteFamilyMember(familyRelation)}
                                  className={styles.deleteFamilyMember}
                                  title="Are you sure you want to delete the contact?"
                                  confirmLoading={deleteFamilyMemberCommunicationLoading}
                                >
                                  <Svg
                                    className={styles.icon}
                                    src={iconDelete}
                                    width={16}
                                    height={16}
                                    color="#DB1818"
                                  />

                                  <span className={styles.text}>Delete</span>
                                </ConfirmModal>
                              :<></>
                          }
                        </div>
                      </div>

                      {
                        familyRelation.familyMember.account.enduringGuardianship ?
                          <div className={styles.checked}>
                            <Svg className={styles.icon} src={iconCheck} 
                              width={16} height={16} color="#19CB56"/>

                            <span className={styles.text}>Enduring Guardianship</span>
                          </div>
                          :
                          <></>
                      }

                      {
                        familyRelation.familyMember.account.powerOfAttorney ?
                          <div className={styles.checked}>
                            <Svg className={styles.icon} src={iconCheck} 
                              width={16} height={16} color="#19CB56"/>

                            <span className={styles.text}>Power of attorney</span>
                          </div>
                          :
                          <></>
                      }

                      <div className={styles.infoRows}>
                        {
                          familyRelation.familyMember.phoneNumber && <div className={styles.infoRow}>
                            <span className={styles.title}>Mobile phone</span>
      
                            <span className={styles.value}>{familyRelation.familyMember.phoneNumber}</span>
                          </div>
                        }
                        {
                          familyRelation.familyMember.account.homeNumber &&
                          <div className={styles.infoRow}>
                            <span className={styles.title}>Home phone</span>
      
                            <span className={styles.value}>{familyRelation.familyMember.account.homeNumber}</span>
                          </div>
                        }
                        {
                          familyRelation.familyMember.account.address &&
                          <div className={styles.infoRow}>
                            <span className={styles.title}>Address</span>
      
                            <span className={styles.value}>{familyRelation.familyMember.account.address}</span>
                          </div>
                        }
                        {
                          familyRelation.familyMember.email &&
                          <div className={styles.infoRow}>
                            <span className={styles.title}>Email</span>
      
                            <span className={styles.value}>{familyRelation.familyMember.email}</span>
                          </div>
                        }
                      </div>

                      {
                        true ?
                          <div className={styles.line}/>
                          :
                          <></>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Modal>
      : 
      <></>
    }

    <div className={styles.residentCardContacts}>
      {
        userStore.isPermission(OrganizationPermissionsEnum.ADD_FAMILY_MEMBER) ?
          <div className={styles.newFamilyMember}>
            <div className={styles.adminIcon} onClick={onNewFamilyMember}>+</div>
          </div>
          :
          ""
      }

      {
        activeResident?.familyRelations.map((familyRelation, familyRelationIndex) => {
          if (familyRelation.familyMember.isDisabled) {
            return null;
          }

          if (familyRelation.contactType !== ContactTypeEnum.primary && familyRelation.contactType !== ContactTypeEnum.secondary) {
            return null;
          }
          
          return (
            <div className={styles.contactContainer} key={familyRelation.familyMember._id + familyRelationIndex}>
              <div className={styles.contact} >
                <div className={styles.header}>
                  <div className={styles.title}>
                    <span className={styles.text}>
                      {
                        familyRelation.contactType === ContactTypeEnum.primary ?
                          "Primary Contact"
                          :
                          "Secondary Contact"
                      }
                    </span>

                    {
                      familyRelation.contactType === ContactTypeEnum.primary ?
                        <Svg
                          className={styles.icon}
                          src={iconStar}
                          width={16}
                          height={16}
                          color='#FEB526'
                        />
                        :
                        <></>
                    }
                    
                  </div>

                  <div className={styles.writeComment} onClick={() => onCreateNote(familyRelation)}>
                    <Svg
                      className={styles.icon}
                      src={iconEdit}
                      width={16}
                      height={16}
                      color="#3956C7"
                    />

                    <span className={styles.text}>Create a Note</span>
                  </div>
                </div>

                <div className={styles.name}>
                  <div className={styles.text}>
                    <span>{familyRelation.familyMember.firstName} {familyRelation.familyMember.lastName} ({familyRelation.relationship})</span>

                    {
                      isShowConfirmedIcon(familyRelation) ?
                        <img className={styles.confirmedIcon} src={iconConfirmed} alt="confirmed"/>
                        :
                        <></>
                    }
                  </div>
                  
                  <div className={styles.adminActions}>
                    {
                      userStore.isPermission(OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER) ?
                        <div className={styles.editFamilyMember} onClick={() => onEditFamilyMember(familyRelation)}>
                          <Svg
                            className={styles.icon}
                            src={iconEditResident}
                            width={16}
                            height={16}
                            color="#3956C7"
                          />

                          <span className={styles.text}>Edit</span>
                        </div>
                        :
                        <></>
                    }

                    {
                      userStore.isPermission(HomePermissionsEnum.DELETE_FAMILY_RELATION) 
                        ? <ConfirmModal
                            confirmCb={() => onDeleteFamilyMember(familyRelation)}
                            className={styles.deleteFamilyMember}
                            title="Are you sure you want to delete the contact?"
                            confirmLoading={deleteFamilyMemberCommunicationLoading}
                          >
                            <Svg
                              className={styles.icon}
                              src={iconDelete}
                              width={16}
                              height={16}
                              color="#DB1818"
                            />

                            <span className={styles.text}>Delete</span>
                          </ConfirmModal>
                        :<></>
                    }
                  </div>
                </div>
          
                {
                  familyRelation.familyMember.account.enduringGuardianship ?
                    <div className={styles.checked}>
                      <Svg className={styles.icon} src={iconCheck} 
                        width={16} height={16} color="#19CB56"/>

                      <span className={styles.text}>Enduring Guardianship</span>
                    </div>
                    :
                    <></>
                }

                {
                  familyRelation.familyMember.account.powerOfAttorney ?
                    <div className={styles.checked}>
                      <Svg className={styles.icon} src={iconCheck} 
                        width={16} height={16} color="#19CB56"/>

                      <span className={styles.text}>Power of attorney</span>
                    </div>
                    :
                    <></>
                }

                <div className={styles.infoRows}>
                  {
                    familyRelation.familyMember.phoneNumber && <div className={styles.infoRow}>
                      <span className={styles.title}>Mobile phone</span>

                      <span className={styles.value}>{familyRelation.familyMember.phoneNumber}</span>
                    </div>
                  }
                  {
                    familyRelation.familyMember.account.homeNumber &&
                    <div className={styles.infoRow}>
                      <span className={styles.title}>Home phone</span>

                      <span className={styles.value}>{familyRelation.familyMember.account.homeNumber}</span>
                    </div>
                  }
                  {
                    familyRelation.familyMember.account.address &&
                    <div className={styles.infoRow}>
                      <span className={styles.title}>Address</span>

                      <span className={styles.value}>{familyRelation.familyMember.account.address}</span>
                    </div>
                  }
                  {
                    familyRelation.familyMember.email &&
                    <div className={styles.infoRow}>
                      <span className={styles.title}>Email</span>

                      <span className={styles.value}>{familyRelation.familyMember.email}</span>
                    </div>
                  }
                </div>

                {
                  familyRelation.comment 
                    ? <div className={styles.comment}>
                        <ConfirmModal
                          className={styles.confirm}
                          confirmCb={() => onDeleteComment(familyRelation.familyMember._id)}
                          confirmLoading={isDeleteLoading}
                          title="Are you sure you want to delete the comment?"
                        >
                          <img className={styles.icon} src={iconCloseComment} alt="close"/>
                        </ConfirmModal>

                        <span className={styles.text}>
                          <span className={styles.red}>*</span>{familyRelation.comment}
                        </span>
                      </div>
                    : <></>
                }
              </div>

              {
                familyRelationIndex === 0 && isResidentSecondaryContact() ?
                 <div className={styles.line}/>
                 :
                 <></>
              }
            </div>
          )  
        })
      }

      {
        isResidentCommonContact() ?
        <div className={styles.seeAllContacts}>
          <div className={styles.line}/>

          <span className={styles.btn} onClick={() => setContactsModal(true)}>See all contacts</span>
        </div>
        :
        <></>
      }
    </div>
   </>
  );
});

export default ResidentCardContacts;

interface ResidentCardContactsProps {
}
