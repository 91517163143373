import React, { memo } from "react";
import styles from "./checkbox.module.scss";
import CheckboxLib from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import tickIcon from "@source/assets/imgs/tick.svg";
import { Colors } from "@source/utils/utils";

const BpIcon = styled('span')(({ theme }) => ({
  width: 24,
  height: 24,
  padding: 0,
  borderRadius: '4px',
  backgroundColor: Colors.border,
  transition: 'all 0.1s linear',

  '&:before': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    width: 23,
    height: 23,
    borderRadius: '4px',
    backgroundColor: '#fff',
    content: '""',
  },

  'input:hover ~ &': {
    backgroundColor: Colors.hover01,
    transition: 'all 0.1s linear',
  },
  'input:focus ~ &': {
    borderColor: '#1C3BCF',
    boxShadow: '0 0 8px 4px rgba(20, 0, 255, 0.07)',
    transition: 'all 0.1s linear',
  },
  'input:disabled ~ &': {
    opacity: 0.3,
    transition: 'all 0.1s linear',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  position: 'relative',
  backgroundColor: Colors.primary01,
  backgroundImage: `url(${tickIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '11px',
  transition: 'all 0.1s linear',

  '&:before': {
    display: 'block',
    width: 0,
    height: 0,
    content: '""',
  },

  'input:hover ~ &': {
    transition: 'all 0.1s linear',
    backgroundColor: Colors.hover01,
  },
  'input:disabled ~ &': {
    opacity: 0.3,
    transition: 'all 0.1s linear',
  },
});

/** You can use single checkbox or union of it with checkboxInput 
 * Prefer to use checkboxInput with one item
*/
export default memo((props: CheckboxProps) => {
  const {name, className, label, value, onChange, disabled, checked, inputProps, id} = props;

  return(
    <div className={`${styles.checkbox} ${className}`}>
      <label className={styles.checkbox_inner}
      >
        <CheckboxLib
          sx={{
            '&:hover': { bgcolor: 'transparent' },
            padding: 0
          }}
          name={name}
          id={id ? id : name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          disableRipple
          checked={checked}
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          inputProps={inputProps}/>
      </label>

      {label
        ? <div className={`${styles.checkbox_label} ${disabled && styles.checkbox_label_disabled}`}>{label}</div>
        : <></>}
    </div>
  )
})

interface CheckboxProps extends ICheckbox {
  /** change handler */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,

  /** checkbox checked status
   * @default false
  */
  checked?: boolean,

  inputProps?: any,
}
export interface ICheckbox {
  /** checkbox value */
  value: string,

  /** checkbox name (to unit under one name in checkBoxInput) - use id in formik */
  name?: string,

  /** checkbox id*/
  id?: string,

  /** checkbox label */
  label?: string | number | JSX.Element,

  /** checkbox disabled */
  disabled?: boolean,

  /** className for container wrapping Checkbox component */
  className?: string,
}