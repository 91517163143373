import { IGetAllUpdatesResponse, IGetUpdateResponse, IUpdate } from "@source/api/models/residentModel";
import { makeAutoObservable } from "mobx";

class UpdatesStore {
  updates: IGetUpdateResponse[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setUpdates(updates: IGetUpdateResponse[]) {
    this.updates = updates;
  }

  getUpdates() {
    return this.updates;
  }

  deleteUpdate(updateId: string) {
    this.updates = this.updates.filter(update => update._id !== updateId);
  }
}

export default new UpdatesStore();