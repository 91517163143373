import Notification from "@components/snackMessage/notification";
import Debounce from "@source/utils/debounce";
import userStore from "@store/userStore";
import AuthController from "./controllers/authController";
import { getAnalytics, logEvent as firebaseLogEvent } from "firebase/analytics";
import { getApps } from "firebase/app";

export const refreshTokenUpdateTime = 10*60*1000; //ms
export const refreshTokenExpiredModalTime = 8*60*1000; //ms
const refreshTimeShift = 2*60*1000; //ms - to ensure that there is enough time for the refresh request

class InteractionObserver {
  static instance?: InteractionObserver;
  expireTimeout: ReturnType<typeof setTimeout> | null = null;
  expiredModalTimeout: ReturnType<typeof setTimeout> | null = null;
  refreshTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    if(!InteractionObserver.instance) {
      InteractionObserver.instance = this;
      const refreshToken = localStorage.getItem('refreshToken');
      if(refreshToken) {
        this.setInteractionObservers();
        this.handleEvent();
      }
    } else {
        this.getInstance().updateInteractionObserversTime();
    }
  }

  setInteractionObservers() {
    const mousemoveCb = new Debounce(() => this.handleMouseEvent(), 10000);
    const onkeypressCb = new Debounce(() => this.handleOtherEvents(), 1000);
    const clickCb = new Debounce(() => this.handleOtherEvents(), 1000);
    const touchstartCb = new Debounce(() => this.handleOtherEvents(), 1000);
/*     const onscrollCb = new Debounce(() => this.handleEvent(), 1000); */

    window.onmousemove = () => mousemoveCb.call();
    window.ontouchstart = () => touchstartCb.call();
    window.onclick = () => clickCb.call();
    window.onkeypress = () => onkeypressCb.call();
/*     window.onscroll = () => onscrollCb.call(); */
    this.clearRefreshTimeout();
    this.getInstance().refreshTimeout = setTimeout(() => this.handleRefreshTimeout(), refreshTokenUpdateTime - refreshTimeShift)
  }

  updateInteractionObserversTime() {
    this.clearRefreshTimeout();
    this.setInteractionObservers();
  }
  
  private handleOtherEvents() {
    this.handleEvent();
  }
  
  private handleMouseEvent() {
    if (!userStore.getExpiredModal()) { // allow user to click Continue button when expired modal showed
      this.handleEvent();
    }
  }

  private handleEvent() {
    const timeout = this.getInstance().expireTimeout;
    const modalTimeout = this.getInstance().expiredModalTimeout;
    if(timeout) {
      clearTimeout(timeout);
    }
    if(modalTimeout) {
      clearTimeout(modalTimeout);
    }
    if(userStore.getExpiredModal()) {
      userStore.setExpiredModal(false);
    }

    const refreshToken = localStorage.getItem('refreshToken');
    if(refreshToken) {
      this.getInstance().expireTimeout = setTimeout(
        () => this.handleExpireTimeout(), 
        refreshTokenUpdateTime
      );

      this.getInstance().expiredModalTimeout = setTimeout(
        () => this.handleExpiredModal(), 
        refreshTokenExpiredModalTime
      );
    }
  }

  private async handleRefreshTimeout() {
    const refreshToken = localStorage.getItem('refreshToken');
    if(refreshToken) {
      const response = await AuthController.refreshTokens(refreshToken);
      
      if(response) {
        this.updateInteractionObserversTime();
      }
    }
  } 

  private async handleExpireTimeout() {
    if (getApps().length > 0) {
      firebaseLogEvent(getAnalytics(), 'automatic_logout', {});
    }

    userStore.setExpiredModal(null);
    window.location.hash = '#/login'
    this.clearRefreshTimeout();
    setTimeout(() => {
      AuthController.logout();
    }, 2000) //need to save draft request (see step.tsx file)
    new Notification().warning('Session ended');
  } 

  private async handleExpiredModal() {
    if (typeof userStore.getExpiredModal() !== "boolean") {
      userStore.setExpiredModal(true);
    }
  }

  private clearRefreshTimeout() {
    const refreshTimeout = this.getInstance().refreshTimeout
    if(refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
  }

  private getInstance() {
    if(!InteractionObserver.instance) {
      InteractionObserver.instance = this;
    }

    return InteractionObserver.instance
  }

  clearInstance() {
    window.onmousemove = null;
    window.ontouchstart = null;
    window.onclick = null;
    window.onkeypress = null;
    this.refreshTimeout = null;
    this.expireTimeout = null;
    delete InteractionObserver.instance;
  }
}

export default InteractionObserver;