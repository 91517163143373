import { Role } from "@source/utils/models/adminModels";

export enum HomePermissionsEnum {
  ADD_NEW_RESIDENT = "add_new_resident",
  EDIT_RESIDENT = "edit_resident",
  VIEW_RESIDENTS = "view_residents",
  DISABLE_RESIDENT = 'disable_resident',
  ENABLE_RESIDENT = 'enable_resident',
  ADD_UPDATE = "add_update",
  VIEW_UPDATES = "view_updates",
  ADD_NEW_ANNOUNCEMENT_EVENT = "add_new_announcement_event",
  VIEW_ANNOUNCEMENT_EVENT = "view_announcement_event",
  EDIT_ANNOUNCEMENT_EVENT = "edit_announcement_event",
  VIEW_DISABLED_RESIDENT = "view_disabled_resident",
  DELETE_FAMILY_RELATION = "delete_family_relation",
  VIEW_HOME_STATISTIC = 'view_home_statistic',
  DOWNLOAD_UPDATE_HISTORY = 'download_update_history',
  DOWNLOAD_UNREAD_UPDATES = 'download_unread_updates',
  ADD_MULTIPLE_UPDATE = 'add_multiple_update',
}

export enum OrganizationPermissionsEnum {
  ADD_NEW_HOUSE = "add_new_house",
  ADD_NEW_EMPLOYEE = "add_new_employee",
  // EDIT_EMPLOYEE_PERMISSION = "edit_employee_permissions",
  EDIT_EMPLOYEE_PASSWORD = "edit_employee_password",
  DISABLE_EMPLOYEE = "disable_employee",
  ENABLE_EMPLOYEE = "enable_employee",
  DISABLE_FAMILY_MEMBER = "disable_family_member",
  ENABLE_FAMILY_MEMBER = "enable_family_member",
  ADD_FAMILY_MEMBER = "add_family_member",
  EDIT_FAMILY_MEMBER = "edit_family_member",
  VIEW_ACCESS_KEYS = "view_access_keys",
  CREATE_ACCESS_KEYS = "create_access_keys",
  UPDATE_ACCESS_KEYS = "update_access_keys",
  REGISTER_ROOT_AGENCY_ACCOUNT = 'register_root_agency_account',
  VIEW_ROOT_AGENCY_ACCOUNTS = 'view_agency_accounts',
}

export const permissionName = {
  [OrganizationPermissionsEnum.ADD_NEW_HOUSE]: "Add new home",
  [OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE]: "Add new employee",
  // [OrganizationPermissionsEnum.EDIT_EMPLOYEE_PERMISSION]: "Edit employee permissions",
  [HomePermissionsEnum.ADD_NEW_RESIDENT]: "Add new resident",
  [HomePermissionsEnum.EDIT_RESIDENT]: "Edit Resident",
  [OrganizationPermissionsEnum.ADD_FAMILY_MEMBER]: "Add family member",
  [OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER]: "Edit family member",
  [HomePermissionsEnum.VIEW_RESIDENTS]: "View residents",
  [HomePermissionsEnum.DISABLE_RESIDENT]: "Deactivate resident",
  [HomePermissionsEnum.ENABLE_RESIDENT]: "Activate resident",
  [HomePermissionsEnum.ADD_UPDATE]: "Add update",
  [HomePermissionsEnum.VIEW_UPDATES]: "View updates",
  [OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD]: "Edit employee password",
  [HomePermissionsEnum.ADD_NEW_ANNOUNCEMENT_EVENT]: "Add new announcement event",
  [HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT]: "View announcement event",
  [HomePermissionsEnum.EDIT_ANNOUNCEMENT_EVENT]: "Edit announcement event",
  [OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER]: "Deactivate family member",
  [OrganizationPermissionsEnum.DISABLE_EMPLOYEE]: "Deactivate employee",
  [OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER]: "Activate family member",
  [OrganizationPermissionsEnum.ENABLE_EMPLOYEE]: "Activate employee",
  [OrganizationPermissionsEnum.VIEW_ACCESS_KEYS]: "View IP key",
  [OrganizationPermissionsEnum.CREATE_ACCESS_KEYS]: "Create IP keys",
  [OrganizationPermissionsEnum.UPDATE_ACCESS_KEYS]: "Update IP keys",
  [HomePermissionsEnum.VIEW_DISABLED_RESIDENT]: "View deactivated residents",
  [HomePermissionsEnum.DELETE_FAMILY_RELATION]: "Delete family relation",
  [HomePermissionsEnum.VIEW_HOME_STATISTIC]: "Download activation report",
  [HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY]: "Download update history",
  [HomePermissionsEnum.DOWNLOAD_UNREAD_UPDATES]: "Download unread update history",
  [OrganizationPermissionsEnum.REGISTER_ROOT_AGENCY_ACCOUNT]: "Register agency account",
  [OrganizationPermissionsEnum.VIEW_ROOT_AGENCY_ACCOUNTS]: "View agency accounts",
  [HomePermissionsEnum.ADD_MULTIPLE_UPDATE]: "Add multiple update",
};

export const rolePermissions = {
  [Role.ORGANIZATION_ADMIN]: {
    homePermissions: [
      HomePermissionsEnum.VIEW_RESIDENTS,
      HomePermissionsEnum.VIEW_UPDATES,
      HomePermissionsEnum.ADD_UPDATE,
      HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.EDIT_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.ADD_NEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.DELETE_FAMILY_RELATION,
      HomePermissionsEnum.EDIT_RESIDENT,
      HomePermissionsEnum.ADD_NEW_RESIDENT,
      HomePermissionsEnum.DISABLE_RESIDENT,
      HomePermissionsEnum.VIEW_DISABLED_RESIDENT,
      HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY,
      HomePermissionsEnum.VIEW_HOME_STATISTIC,
      HomePermissionsEnum.ADD_MULTIPLE_UPDATE,
    ],
    orgPermissions: [
      OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ADD_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER,
      OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE,
      OrganizationPermissionsEnum.ENABLE_EMPLOYEE,
      OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD,
      OrganizationPermissionsEnum.DISABLE_EMPLOYEE,
      OrganizationPermissionsEnum.ADD_NEW_HOUSE,
      OrganizationPermissionsEnum.VIEW_ACCESS_KEYS,
      OrganizationPermissionsEnum.CREATE_ACCESS_KEYS,
      OrganizationPermissionsEnum.UPDATE_ACCESS_KEYS,
      OrganizationPermissionsEnum.VIEW_ROOT_AGENCY_ACCOUNTS,
      OrganizationPermissionsEnum.REGISTER_ROOT_AGENCY_ACCOUNT,
    ],
  },
  [Role.HOME_ADMIN]: {
    homePermissions: [
      HomePermissionsEnum.VIEW_RESIDENTS,
      HomePermissionsEnum.VIEW_UPDATES,
      HomePermissionsEnum.ADD_UPDATE,
      HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.EDIT_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.ADD_NEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.DELETE_FAMILY_RELATION,
      HomePermissionsEnum.EDIT_RESIDENT,
      HomePermissionsEnum.ADD_NEW_RESIDENT,
      HomePermissionsEnum.DISABLE_RESIDENT,
      HomePermissionsEnum.VIEW_DISABLED_RESIDENT,
      HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY,
      HomePermissionsEnum.VIEW_HOME_STATISTIC,
      HomePermissionsEnum.ADD_MULTIPLE_UPDATE,
    ],
    orgPermissions: [
      OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ADD_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER,
      OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE,
      OrganizationPermissionsEnum.ENABLE_EMPLOYEE,
      OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD,
      OrganizationPermissionsEnum.DISABLE_EMPLOYEE,
    ],
  },
  [Role.STAFF_ADMIN]: {
    homePermissions: [
      HomePermissionsEnum.ADD_NEW_RESIDENT,
      HomePermissionsEnum.EDIT_RESIDENT,
      HomePermissionsEnum.VIEW_RESIDENTS,
      HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.ADD_UPDATE,
      HomePermissionsEnum.VIEW_UPDATES,
      HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY,
    ],
    orgPermissions: [
      OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE,
      OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD,
      OrganizationPermissionsEnum.DISABLE_EMPLOYEE,
      OrganizationPermissionsEnum.ENABLE_EMPLOYEE,
      OrganizationPermissionsEnum.ADD_FAMILY_MEMBER,
      OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER,
    ],
  },
  [Role.FAMILY_ADMIN]: {
    homePermissions: [
      HomePermissionsEnum.VIEW_RESIDENTS,
      HomePermissionsEnum.VIEW_UPDATES,
      HomePermissionsEnum.ADD_UPDATE,
      HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.DELETE_FAMILY_RELATION,
      HomePermissionsEnum.EDIT_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.ADD_NEW_ANNOUNCEMENT_EVENT,
      HomePermissionsEnum.EDIT_RESIDENT,
      HomePermissionsEnum.ADD_NEW_RESIDENT,
    ],
    orgPermissions: [
      OrganizationPermissionsEnum.EDIT_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ADD_FAMILY_MEMBER,
      OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER,
      OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER,
    ],
  },
  [Role.NURSE]: {
    homePermissions: [
      HomePermissionsEnum.VIEW_RESIDENTS,
      HomePermissionsEnum.VIEW_UPDATES,
      HomePermissionsEnum.ADD_UPDATE,
      HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT,
    ],
    orgPermissions: [],
  },
};
