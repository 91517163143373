import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./residentTab.module.scss";
import iconDownload from '@source/assets/icons/iconDownload.svg';
import Svg from "@source/components/UI/svg/svg";
import ResidentCardInfo from "./residentCardInfo/residentCardInfo";
import ResidentCardContacts from "./residentCardContacts/residentCardContacts";
import HistoryCard from "@source/components/historyCard/historyCard";
import useFetching from "@source/hooks/useFetching";
import ResidentController from "@source/api/controllers/residentController";
import { IDownloadResidentUpdateHistoryRequest, IGetResidentUpdatesRequest } from "@source/api/models/residentModel";
import nursingHomeStore from "@source/store/nursingHomeStore";
import Loader from "@source/components/UI/loader/loader";
import { observer } from "mobx-react-lite";
import residentsStore from "@source/store/residentsStore";
import userStore from "@store/userStore";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import BlockNewUpdatesModal from "@components/blockNewUpdatesModal/blockNewUpdatesModal";
import NewUpdatesDisabledModal from "@components/newUpdatesDisabledModal/newUpdatesDisabledModal";
import DownloadUpdateHistoryModal from "@components/downloadUpdateHistoryModal/downloadUpdateHistoryModal";
import FileSaver from 'file-saver';
import moment from "moment";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

const residentTab = observer((props: ResidentTabProps) => {
  const {} = props;
  const [residentLoad, isResidentLoading, residentError] = useFetching<ReturnType<typeof ResidentController.getResident>>(ResidentController.getResident);
  const [getUpdates, isUpdateLoading, updateError] = useFetching<ReturnType<typeof ResidentController.getResidentUpdates>>(ResidentController.getResidentUpdates);
  const [downloadResidentUpdateHistory, downloadResidentUpdateHistoryLoading, downloadResidentUpdateHistoryError] = useFetching<ReturnType<typeof ResidentController.downloadResidentUpdateHistory>>(ResidentController.downloadResidentUpdateHistory);
  const { residentId } = useParams<{residentId: string}>();
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const [downloadUpdatesModal, setDownloadUpdatesModal] = useState(false)
  
  useEffect(() => {
    let isMounted = true;

    const fetchUpdates = async () => {
      const request:IGetResidentUpdatesRequest = {
        nursingHomeId: nursingHomeStore.getId(),
        residentId: residentId
      }
      const response = await getUpdates(request);
      if(response) {
        if (isMounted) {
          residentsStore.setActiveResidentUpdates(response.updates);
        }
      }
    }
    
    const fetchResident = async () => {
      const residentsRes = await residentLoad({nursingHomeId: nursingHomeStore.getId(), residentId});

      if( residentsRes) {
        if (isMounted) {
          residentsStore.setActiveResident(residentsRes);
        }
      }
    }
    
    if (userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES)) {
      fetchUpdates();
    }
    fetchResident();

    return () => {
      isMounted = false;
      residentsStore.setActiveResident(null);
    }
  }, [])

  const onTabUpdateDelete = async (updateId: string) => {
    residentsStore.deleteActiveResidentUpdates(updateId);
  }

  const toggleDownloadUpdatesModal = () => {
    setDownloadUpdatesModal(!downloadUpdatesModal);
  }

  const onDownloadUpdateHistory = async (req: IDownloadResidentUpdateHistoryRequest) => {
    const downloadResidentUpdateHistoryRes = await downloadResidentUpdateHistory(nursingHomeStore.activeNursingHome?._id, residentsStore.activeResident?._id, req);

    if (downloadResidentUpdateHistoryRes) {
      logEvent('admin__residents__download_updates_history', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      let blob =  new Blob([downloadResidentUpdateHistoryRes], { type: req.type })

      FileSaver.saveAs(blob, `report_${moment().format("DD.MM.YYYY")}.${req.type}`)
    }
  }

  return(
    <div className={styles.residentTab}>
      {
        residentsStore.getModalBlockNewUpdatesResident() ?
        <BlockNewUpdatesModal/>
        :
        <></>
      }

      {
        residentsStore.getModalNewUpdatesDisabledResident() ?
        <NewUpdatesDisabledModal />
        :
        <></>
      }

      <DownloadUpdateHistoryModal open={downloadUpdatesModal} setOpen={toggleDownloadUpdatesModal} onDownload={onDownloadUpdateHistory}/>

      <div className={styles.cards}>
        {isResidentLoading 
          ? 
          <div className={styles.loader}>
            <Loader width={50} height={50} borderWidth={4}/>
          </div>
          : residentsStore.activeResident 
            ? <>
                <ResidentCardInfo />
                <ResidentCardContacts />
              </>
            : <></>
        }
      </div>

      {
        userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES) ?
        <div className={styles.history}>
          <div className={styles.header}>
            <span className={styles.title}>Update history</span>

            {
              userStore.isPermission(HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY) ?
                <div className={styles.downloadHistory} onClick={() => setDownloadUpdatesModal(!downloadUpdatesModal)}>
                  <Svg className={styles.icon} width={24} height={24} src={iconDownload} color="#333333"/>

                  <span className={styles.text}>Download history</span>
                </div>
                :
                <></>
              }
          </div>

          <div className={styles.list}>
            {isUpdateLoading
                ? <div className={styles.loader}>
                    <Loader width={50} height={50} borderWidth={4}/>
                  </div>
                : residentsStore.activeResidentUpdates && residentsStore.activeResidentUpdates.map((update, key) => (
                    <div className={styles.item} key={key}><HistoryCard residentStatus={residentsStore.getActiveResident()?.status} update={update} onTabUpdateDelete={onTabUpdateDelete}/></div>
                  ))
            }
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
})

export default residentTab;

interface ResidentTabProps {

}
