import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import { IResetPasswordRequest, IResetPasswordResponse } from "../models/familyMemberModels";

export default class FamilyMemberService {
  static async resetPassword(body: IResetPasswordRequest) {
    try {
      return(await api.post<IResetPasswordResponse>("auth/family-member/forgot-password/reset", body)).data;
    } catch (e: any) {
      throw ReqError.resetFamilyMemberPasswordError(e.response)
    }
  }

  static async nursingHomeInactiveFamilyMembers(nursingHomeId: string) {
    try {
      return(await api.get(`family-member/inactive/${nursingHomeId}`)).data;
    } catch (e: any) {
      throw ReqError.nursingHomeInactiveFamilyMembersError(e.response)
    }
  }
}