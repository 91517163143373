
import { useHistory } from "react-router-dom";
import styles from "./TrialModePage.module.scss";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import { observer } from "mobx-react-lite";
import { HOME_ROUTES, LogoType, ROUTES } from "@source/utils/utils";
import Logo from "@components/UI/logo/logo";
import Button from "@components/UI/buttons/button/button";
import iconTimer from "@source/assets/icons/iconTimer.svg";

const TrialModePage = observer((props: TrialModePageProps) => {
  const {} = props;
  
  const router = useHistory();

  const onStartWorking = () => {
    router.push(HOME_ROUTES.Residents);
  }

  return(
    <div className={styles.trialMode}>
      <div className={styles.trialMode__inner}>
        <Logo className={styles.trialMode__inner_logo} type={LogoType.White}/>

        <div className={styles.trialMode__inner__form}>
          <img className={styles.icon} src={iconTimer}/>

          <span className={styles.title}>Practice mode</span>

          <span className={styles.text}>For the next couple of days you will use Brenna in practice mode to get acquainted with its functionality. We will send you an email when you can fully work with Brenna</span>

          <Button className={styles.btn} onClick={onStartWorking}>
            Start working
          </Button>
        </div>
      </div>

      <img className={styles.trialMode_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default TrialModePage;

interface TrialModePageProps {

}